import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Get_DropDown, API_QNRList, API_QNRResult } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { OpenModal } from "../../global/ToolUtil";
import { checkAuthToken } from "../../global/TokenUtil";

function QuestionList() {
    var history = useHistory();

    useEffect(() => {

        getData();
    }, []);
    const [CouPeriodList, setCouPeriodList] = useState([{}]);
    const [CouTypeList, setCouTypeList] = useState([{}]);
    const [CouTypeCList, setCouTypeCList] = useState([{}]);
    const [CouPID, setCouPID] = useState('');
    const [CouTypeID, setCouTypeID] = useState('');
    const [CouTypeCID, setCouTypeCID] = useState('');
    const [QunList, setQunList] = useState([{}]);
    const [QunResultList, setQunResultList] = useState([{}]);
    const [TeacherName, setTeacherName] = useState('');
    const [CouName, setCouName] = useState('');
    function getData() {
        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    console.log(data);
                    setCouPeriodList(data.result.coursePeriodArray);
                    setCouTypeList(data.result.courseTypeCArray);
                    setCouTypeCList(data.result.courseTypeArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        Search();
    }
    function Search() {
        API_QNRList({ CouPID: CouPID, CouTypeID: CouTypeID, CouTypeCID: CouTypeCID})
            .then((res) => res.data)
            .then((data) => {
                setQunList(data.result);
            }).catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
   
    function MLink() {       
        location.href = "Main?path=QuestionMaintain";
        //OpenModal("modal-view");
    }
    function RLink(CouID) {
        API_QNRResult({ CouID: CouID })
            .then((res) => res.data)
            .then((data) => {
                //console.log(data);
                if (!checkAuthToken(data)) {
                    localStorage.clear();
                    history.push("/");
                }
                setCouName(data.result.couName);
                setTeacherName(data.result.couTeacher);
                setQunResultList(data.result.qnrQunList);
            }).catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        OpenModal("modal-view");

        //location.href = "Main?path=QuestionResult";

    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalOpen() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    return (
        <>
            <div className="main">
                <div className="page-content h-full">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">問卷調查管理</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="fc-1 full">
                                <div className="form-container wp9">
                                    <div className="form-item twenty">
                                        <div className="top-table">期別</div>
                                        <div className="top-value">
                                            <select value={CouPID} onChange={(e) => { setCouPID(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {CouPeriodList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">類別</div>
                                        <div className="top-value">
                                            <select value={CouTypeCID} onChange={(e) => { setCouTypeCID(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {CouTypeList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">班別</div>
                                        <div className="top-value">
                                            <select value={CouTypeID} onChange={(e) => { setCouTypeID(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {CouTypeCList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <a className="btn btn-border btn-middle" onClick={Search}>
                                        <span>查詢</span>
                                    </a>


                                </div>

                                <div className="info-holder">
                                    <div className="right fc-1 pointer"  onClick={MLink}>
                                        <div className="btn btn-icon" >
                                        <i className="icon icon-24 mr-1" ></i>
                                        </div>
                                        <span className="fw-b">問卷維護</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">班別序號</div>
                                        <div className="flex-2">課程名稱</div>
                                        <div className="flex-2">課程期別</div>
                                        <div className="flex-2">類別</div>
                                        <div className="flex-2">班別</div>
                                        <div className="flex-2">結束日期</div>
                                        <div className="flex-2">填卷比例</div>
                                        <div className="flex-1">查看結果</div>
                                    </li>
                                    {QunList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-1">{x.CouNo}</div>
                                            <div className="flex-2">{x.CouName}</div>
                                            <div className="flex-2">{x.CouPName}</div>
                                            <div className="flex-2">{x.CouTypeCName}</div>
                                            <div className="flex-2">{x.CouTypeName}</div>
                                            <div className="flex-2">{x.EndDate}</div>
                                            <div className="flex-2">{x.FillCnt + "/" + x.RegCnt}</div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={() => {RLink(x.CouID);}}
                                                    >
                                                        <i className="icon icon-30"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {/*<li className="lt-row">*/}
                                    {/*    <div className="form-item">*/}
                                    {/*        <i className="icon icon-30"></i>*/}
                                    {/*        <div className="mr-1">查看全部及下載統計結果</div>*/}
                                    {/*    </div>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>

                        </div>


                    </div>
                </div>
                <div id="modal-view" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">查看課程問卷結果</h3>
                        </div>
                        <div className="modal-body">
                            <div className="info-holder">
                                <div className="form-item twenty" style={{ width : "calc(40%)" }}>
                                    <div className="field">課程名稱</div>
                                    <div className="value">
                                        <input type="text" value={ CouName} />
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">教師名稱</div>
                                    <div className="top-half" >
                                        <input type="text" value={TeacherName} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">題號</div>
                                        <div className="flex-2">人數</div>
                                        <div className="flex-1">A</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">B</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">C</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">D</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">E</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">平均</div>
                                        <div className="flex-2">淨分數</div>
                                    </li>
                                    {
                                        QunResultList.map((x) => (
                                            <li className="lt-row">
                                                <div className="flex-1">
                                                    {x.Serial}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Cnt}
                                                </div>
                                                <div className="flex-1">
                                                    {x.Cnt0}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Rate0}%
                                                </div>
                                                <div className="flex-1">
                                                    {x.Cnt1}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Rate1}%
                                                </div>
                                                <div className="flex-1">
                                                    {x.Cnt2}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Rate2}%
                                                </div>
                                                <div className="flex-1">
                                                    {x.Cnt3}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Rate3}%
                                                </div>
                                                <div className="flex-1">
                                                    {x.Cnt4}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Rate4}%
                                                </div>
                                                <div className="flex-1">
                                                    {x.AVG}
                                                </div>
                                                <div className="flex-2">
                                                    {x.SUM}
                                                </div>

                                            </li>
                                        ))}
                                </ul>


                            </div>
                        </div>
             
                    </div>
                </div>
            </div>
        </>
    );
}
export default QuestionList;
