import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_POST_AddCourseType } from "../../global/constants";
import { useHistory } from "react-router-dom";

function InformModify() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    function getData() { }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([
        { class: "111年秋季班", name: "全部", content: "您在大墩文化中心報名的YYYYY課程經過電腦隨機抽選結果...", sendtime: "2022/08/14 09: 01: 33", people: " 錄 取 ", modus: "簡訊", total: "1124", success: "1120", fail: " 4", isEdit: false },
        { class: "111年秋季班", name: "國畫(七)水墨山水", content: "您在大墩文化中心報名的YYYYY課程已收到您的繳費，請注意...", sendtime: "2022/08/14 09: 01: 33", people: "繳費", modus: "簡訊", total: "1124", success: "1120", fail: " 4", isEdit: false },
        { class: "111年秋季班", name: "國畫(七)水墨山水", content: "您在大墩文化中心申請的YYYYY課程已錄取為備選，請盡快到...", sendtime: "2022/08/14 09: 01: 33", people: "備取", modus: "簡訊", total: "1124", success: "1120", fail: " 4", isEdit: false },
        { class: "111年秋季班", name: "國畫(八)水墨山水", content: "因為疫情擴大影響，您所報名的YYYY課程於即日停課兩週，所...", sendtime: "2022/08/14 09: 01: 33", people: "學員", modus: "簡訊", total: "1124", success: "1120", fail: " 4", isEdit: false },

    ]);

    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) { //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        //getDashboard();
                        //localStorage.setItem('token', data.token.token);
                        //localStorage.setItem('user', data.result.loginData.userNo);
                        //localStorage.setItem('userName', data.result.loginData.userName);
                        //localStorage.setItem('userID', data.result.loginData.userID);
                        //history.push('?path=changepassword')

                    }
                    else if (data.code == "2013") { //特定的失敗原因


                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }

    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function Add(e) {
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, isEdit: true }];
        });
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="container">
                                <h3 className="fz-B has-border">新增一般通知</h3>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="form-container">
                            <div className="form-item half">
                                <div className="field fw-b">通知標題</div>
                                <div className="value">
                                    <input type="text" value="上課時間臨時更改通知"></input>
                                </div>

                            </div>
                            <div className="form-item half">
                                <div className="field text-right">發送方式 </div>
                                <div className="value">
                                    <div>
                                        <input type="checkbox" id="f1021-1" name="f1021" value="f1021-1">

                                        </input>
                                        <label for="f1021-1">簡訊</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" id="f1021-2" name="f1021" value="f1021-2">

                                        </input>
                                        <label for="f1021-2">電子郵件</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-item col my-1">
                                <div className="field fw-b">簡訊內容    <span className="text-red ml-1">注意：請注意總字數不得超過70個字（包括標點符號）。</span></div>
                                <div className="top-value my-1">
                                    <textarea name="" id="" cols="50" rows="5">
                                        您在大墩文化中心報名的111年春季國畫(六)課程已被錄取，請至報名網站下載繳費單進行繳費或點擊下面網址在超商進行繳費。https://pay.taichung.gov.tw/e6KWz
                                    </textarea>
                                </div>

                            </div>
                            <div className="form-item col my-1">
                                <div className="field fw-b">郵件內容</div>
                                <div className="top-value my-1">
                                    <textarea name="" id="" cols="50" rows="10">
                                        李大明您好，您在大墩文化中心報名的xxxx課程被錄取為學員，請至報名網站下載繳費單進行繳費。或在手機打開下面網址在各大超商進行繳費。https://pay.taichung.gov.tw/e6KWz
                                    </textarea>
                                </div>

                            </div>
                            <div className="container">
                                <div className="form-container">
                                    <div className="form-item col my-1 fw-b"> 選擇通知對象 </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">期別</div>
                                        <div className="top-value">
                                            <select>
                                                <option>111年秋季班</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">班別序號</div>
                                        <div className="top-value">
                                            <select>
                                                <option>AAA001</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">通知對象</div>
                                        <div className="top-value">
                                            <select>
                                                <option>未繳費</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty  multi-inline">
                                        <div className="top-table">共計：</div>
                                        <div className="top-value fw-b">
                                            <div>
                                                <input type="text" value="22" />人
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="form-item half">
                                <div className="value multi-inline fw-b">
                                    <input type="radio" id="f221-1" name="f221" value="f221-1" />
                                    <label for="f221-1">立即發送</label>
                                    <input type="radio" id="f221-2" name="f221" value="f221-2" />
                                    <label for="f221-1" className="ml-1">排程發送</label>
                                    <div className="field ml-1 my-1">開課日期</div>
                                    <div className="value my-1">
                                        <input type="date" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-item btn-area sp">
                                <div className="left">

                                </div>
                                <div className="right">
                                    <div className="right">
                                        <button className="btn btn-border">
                                            <span>取消</span>
                                        </button>
                                        <button className="btn">
                                            <span>儲存</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}
export default InformModify;
