import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Get_CourseSpecAndAtt, API_Get_SpecList,API_Get_CourseBaseData, API_POST_CourseBaseData, API_Get_DropDown, API_POST_VBaseget, API_Put_CourseBaseData, API_Del_CourseBaseData } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import SignupDetail from "./SignupDetail"
import { CloseModal } from "../../global/ToolUtil";
import { MultiSelect } from "react-multi-select-component";


function CourseContent() {
    var history = useHistory();

    useEffect(() => {
        getData();
        
    }, []);


    const [Copy, setCopy] = useState(false);
    const [CouPNameError, setCouPNameError] = useState(false);
    const [CouTypeError, setCouTypeError] = useState(false);
    const [CouClassTypeError, setCouClassTypeError] = useState(false);
    const [CouSNameError, setCouSNameError] = useState(false);
    const [CouNameError, setCouNameError] = useState(false);
    const [CouNoError, setCouNoError] = useState(false);
    const [CouMoneyError, setCouMoneyError] = useState(false);
    const [CouAmountError, setCouAmountError] = useState(false);
    const [CouWeekError, setCouWeekError] = useState(false);
    const [CouWeek2Error, setCouWeek2Error] = useState(false);


    
    const [selected, setSelected] = useState([]);
    const [CouID, setCouID] = useState("");
    const [ATTID, setATTID] = useState("");
    const [SpecList, setSpecList] = useState([{}]);
    const [CouSpList, setCouSpList] = useState([{}]);
    const [SpecChkList, setSpecChkList] = useState([{}]);
    const [DetailCouSID, setDetailCouSID] = useState(0);
    const [attList, setattList] = useState([{}]);
    useEffect(() => {
        API_Get_SpecList({ CouID: CouID }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    setSpecList(data.result.spList);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        API_Get_CourseSpecAndAtt({ CouID: CouID }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    setCouSpList(data.result.couCheckList);
                    setattList(data.result.attList);
                    
                }
                else {
                    setCouSpList([]);
                    setattList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }, [CouID]);
    function changetState(e) {
        console.log(CouSpList);
        if (e.target.checked) {
            setCouSpList(function (prev) {
                return [...prev, { specDiscID: e.target.value }];
            });
        } else {
            setCouSpList(function (prev) {
                return prev.filter((item) => item.specDiscID != e.target.value);
            });
        }
    }
    function changetValue(e,id) {
        console.log(CouSpList);
        CouSpList.forEach(f => {
            if (f.specDiscID == id) {
                f.couSpecAmount = e.target.value;
            }
        });
        setCouSpList(CouSpList);
    }

    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [DropDownCouTypeID, setDropDownCouTypeID] = useState("");//班別下拉
    const [DropDownCouTypeCID1, setDropDownCouTypeCID1] = useState("");//類別下拉
    const [DropDownCouName, setDropDownCouName] = useState("");//課程名稱
    function getData() {


        API_Get_CourseBaseData({
            CouPID: DropDownCouPID,
            CouTypeCID1: DropDownCouTypeCID1,
            CouTypeID: DropDownCouTypeID,
            CouName: DropDownCouName
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);                    
                    setDataList(data.result);              
                }
                else {
                    console.log(data.message);
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);
                    setteacherBaseArrayList(data.result.teacherBaseArray);
                    setcodeBaseSexArrayList(data.result.codeBaseSexArray);
                    setcourseSchdArrayList(data.result.courseSchdArray);
                    setWeeksArrayList(data.result.codeBaseWeedsArray);
                }
                else {
                    console.log(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [modalTit, setModalTit] = useState("刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [courseTypeCArrayList, setcourseTypeCArrayList] = useState([{}]);//類別清單
    const [courseTypeArrayList, setcourseTypeArrayList] = useState([{}]);//班別清單
    const [teacherBaseArrayList, setteacherBaseArrayList] = useState([{}]);//教師清單
    const [codeBaseSexArrayList, setcodeBaseSexArrayList] = useState([{}]);//性別清單
    const [courseSchdArrayList, setcourseSchdArrayList] = useState([{}]);//報名作業時間清單
    const [WeeksArrayList, setWeeksArrayList] = useState([{}]);//上課清單
    const [dataList, setDataList] = useState([{}]);
    const [CouName, setCouName] = useState(""); //課程名稱
    const [CouNo, setCouNo] = useState("");//課程代號
    const [CouMoney, setCouMoney] = useState("");//課程費用
    const [CouAmountT, setCouAmountT] = useState("");//名額
    const [CouAgeMin, setCouAgeMin] = useState("");//學員年齡限制最小
    const [CouAgeMax, setCouAgeMax] = useState("");//學員年齡限制最大
    const [CouTeacherName, setCouTeacherName] = useState("");//教師姓名
    const [CouTeacherID, setCouTeacherID] = useState("");//教師代號
    const [CouStartDate, setCouStartDate] = useState("");//開課日期
    const [CouCloseDate, setCouCloseDate] = useState("");//結束日期
    const [CouStartTime, setCouStartTime] = useState("");//上課開始時間
    const [CouCloseTime, setCouCloseTime] = useState("");//上課結束時間
    const [CourseTypeID, setCourseTypeID] = useState("");//班別
    const [CouPID, setCouPID] = useState("");//期別
    const [Sex, setSex] = useState(""); //學員性別
    const [CouDesc, setCouDesc] = useState("");//課程內容
    const [CouTarget, setCouTarget] = useState("");//適用對象
    const [CouTypeCID1, setCouTypeCID1] = useState("");//類別
    const [CouHoldID, setCouHoldID] = useState("");//上期課程序號
    const [CouPName, setCouPName] = useState("");//期別
    const [Kind, setKind] = useState("");//類別
    const [ClassType, setClassType] = useState("");//班別
    const [CouUSex, setCouUSex] = useState("");//學員性別
    const [CouWeeks, setCouWeeks] = useState("");
    const [CouWeeks2, setCouWeeks2] = useState("");
    const [CouPlace, setCouPlace] = useState("");
    const [CouHour, setCouHour] = useState("");
    const [CouHoldAmount, setCouHoldAmount] = useState(""); //上階段課程保留名額
    const [CouAmountA, setCouAmountA] = useState(""); //身心障礙人士保障名額
    const [EditcouNo, setEditcouNo] = useState(false);
    
    const [CouTypeID, setCouTypeID] = useState("");
    const [CouSID, setCouSID] = useState("");
    const [CouSIDName, setCouSIDName] = useState("");
    const [Weeks, setWeeks] = useState(false);
    const [Weeks2, setWeeks2] = useState(false);
    const [MCardRegPoint, setMCardRegPoint] = useState("");//報名限制點數
    const [MCardDiscPoint, setMCardDiscPoint] = useState("");//報名點數折抵
    const [MCardDiscMoney, setMCardDiscMoney] = useState("");//報名點數折抵金額
    const [CouRemark, setCouRemark] = useState("");//備註


    const [files, setFile] = useState([]);
    const LimitSize = 5120000;
    const [PrevImg, setPrevImg] = useState('');
    const [UserSpecList, setUserSpecList] = useState([]);
    const [caroImage, setCaroImage] = useState();
    const options = [
        { label: "二", value: "星期二" },
        { label: "三", value: "星期三" },
        { label: "四", value: "星期四" },
        { label: "五", value: "星期五" },
        { label: "六", value: "星期六" },
        { label: "日", value: "星期日" },
    ];

    function formRequired() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                if (element.id != "datetime-local" && element.id != "SName") {
                    console.log(element);
                    element.classList.add("error");
                    result = false;
                }
            } else {
                element.classList.remove("error");
            }
        });
        return result;
    }

    function required() { //驗證恢復預設
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });
    }


    function Edit(type, index) {   
        if (type == 2) {
            //驗証必填欄位
            var result = formRequired();
            //按下的是新增
            //呼叫API新增
            if (result) {
                var dt = {
                    CouHour: parseInt(CouHour),
                    CouRemark: CouRemark,
                    CouStatus: "0",
                    CouWeeks: Weeks == true ? JSON.stringify(selected) : CouWeeks2,//課程星期
                    CouHoldID: CouHoldID,
                    CouHoldAmount: parseInt(CouHoldAmount),
                    CouSID: parseInt(CouSID),
                    CouPID: parseInt(CouPID),
                    CouName: CouName,
                    CouNo: CouNo,
                    CouTypeCID1: parseInt(CouTypeCID1),
                    CouTypeID: parseInt(CourseTypeID),
                    CouTeacherID: parseInt(CouTeacherID),
                    CouTeacherName: CouTeacherName,
                    CouMoney: parseInt(CouMoney),
                    CouAmount: parseInt(CouAmountT),
                    CouUSex:CouUSex,
                    CouPlace :CouPlace,
                    CouAgeMin: parseInt(CouAgeMin),
                    CouAgeMax: parseInt(CouAgeMax),
                    CouStartDate: CouStartDate,
                    CouCloseDate: CouCloseDate,
                    CouStartTime: CouStartTime + ":00",
                    CouCloseTime: CouCloseTime + ":00",
                    CouDesc: CouDesc,
                    CouTarget: CouTarget,
                    CouATTID: ATTID,
                    SpecList: CouSpList,
                    Imgs: attList,
                    CardList: [
                        { "MCardType": "A", "MCardRegPoint": parseInt(MCardRegPoint), "MCardDiscPoint": parseInt(MCardDiscPoint), "MCardDiscMoney": parseInt(MCardDiscMoney) }
                    ],
                };
                console.log(JSON.stringify(dt));
                if (Weeks && selected == "" ) {
                    openModalError("新增", "尚有必填未填(橘色框)");
                    return;
                }
                API_POST_CourseBaseData(dt).then((response) => response.data)
                    .then((data) => {
                        console.log(data);
                        if (data.code == "0000") {
                            getData();
                            alert(data.message);
                            CloseModal("modal-edit");
                            //成功
                            //getDashboard();
                            //localStorage.setItem('token', data.token.token);
                            //localStorage.setItem('user', data.result.loginData.userNo);
                            //localStorage.setItem('userName', data.result.loginData.userName);
                            //localStorage.setItem('userID', data.result.loginData.userID);
                            //history.push('?path=changepassword')
                        } else if (data.code == "2018") {
                            //特定的失敗原因
                            openModalError("", data.message);
                            return;
                        } else {
                            openModalError("", data.message);
                            return;
                        }

                        //console.log(localStorage.getItem('menulist'));
                    })
                    .catch((err) => {
                        if (err.response.status == "400") {
                            openModalError("新增", "尚有必填未填");
                        }
                        return;
                    });
            }
            else {
                openModalError("新增", "尚有必填未填(橘色框)");
            }
        }
        //編輯查詢
        else if (type == 1) {
            setCouID(dataList[index].CouID);
            API_POST_VBaseget({
                "CouDeptID": localStorage.getItem("acntTypeID"),
                "CouID": dataList[index].CouID,
                "SpecList": [],
                "CardList": []
            }).then((response) => response.data)
                .then((data) => {
                    
                    setCouAmountA(data.result[0].CouSpecAmountA)//身心障礙人士保障名額
                    setCouHoldAmount(data.result[0].CouHoldAmount) //上階段課程保留名額
                    setCouPName(data.result[0].CouPName)//期別
                    setKind(data.result[0].Kind)//類別
                    setClassType(data.result[0].ClassType)//班別
                    setCouTeacherName(data.result[0].CouTeacherName)//教師
                    setCouUSex(data.result[0].CouUSex)//學員性別
                    setATTID(data.result[0].ATTID);
                    if (data.result[0].CouUSex == "0") {
                        setSex("不限");
                    }
                    else if (data.result[0].CouUSex == "M") {
                        setSex("男");
                    }
                    else {
                        setSex("女");
                    }

                    setCouWeeks(data.result[0].CouWeeks)//上課日期

                    if (data.result[0].CouWeeks != null) {
                        if (data.result[0].CouWeeks.substring(0,1) == "[") {
                            setWeeks(true);
                            setWeeks2(false);
                            setCouWeeks(data.result[0].CouWeeks);
                            setSelected(JSON.parse(data.result[0].CouWeeks));                            
                        }
                        else {
                            setWeeks(false);
                            setWeeks2(true);
                            setCouWeeks2(data.result[0].CouWeeks);
                        }
                    }
                    else {
                        setCouWeeks("")//上課日期
                        setWeeks2(false);
                        setWeeks(false);
                    }
                    setCouName(data.result[0].CouName); //課程名稱
                    setCouNo(data.result[0].CouNo); //班別序號
                    setCouMoney(data.result[0].CouMoney); //課程費用
                    setCouAmountT(data.result[0].CouAmount); //名額
                    setCouAgeMin(data.result[0].CouAgeMin); //學員年齡限制最小
                    setCouAgeMax(data.result[0].CouAgeMax); //學員年齡限制最大

                    setCouStartDate(data.result[0].CouStartDate.split("T00:00:00")[0]); //開課日期
                    setCouCloseDate(data.result[0].CouCloseDate.split("T00:00:00")[0]); //結束日期
                    
                    setCouStartTime(data.result[0].CouStartTime); //上課時間
                    setCouCloseTime(data.result[0].CouCloseTime); //上課時間

                    setCouDesc(data.result[0].CouDesc); //課程內容
                    if (data.result[0].CouTarget == null) {
                        console.log(data.result[0].CouTarget);
                        setCouTarget(""); //適用對象
                    }
                    else {
                        setCouTarget(data.result[0].CouTarget);
                    }
                    setCouHoldID(data.result[0].CouHoldID);//上期課程序號
                    setCouPID(data.result[0].CouPID);
                    setCouID(data.result[0].CouID);
                    setCouTypeID(data.result[0].CouTypeID);
                    setCourseTypeID(data.result[0].CouTypeID);
                    setCouTypeCID1(data.result[0].CouTypeCID1);
                    setCouTeacherID(data.result[0].CouTeacherID); //教師ID
                    setCouSID(data.result[0].CouSID); //報名作業時間ID
                    setMCardRegPoint(data.result[0].MCardRegPoint); //報名限制點數
                    setMCardDiscPoint(data.result[0].MCardDiscPoint); //點數折抵
                    setMCardDiscMoney(data.result[0].MCardDiscMoney); //點數折抵金額
                    setCouRemark(data.result[0].CouRemark); //備註
                    setCouPlace(data.result[0].CouPlace);
                    setCouHour(data.result[0].CouHour);//上課時數                
                })
                .catch(err => {
                    console.log(err);
                })
        }
            //編輯
        else if (type == 3) {     
            //驗証必填欄位
            var result = formRequired();
            if (result) {
                var dt = {
                    CouRemark: CouRemark,
                    //CouWeeks: Weeks == true ? CouWeeks : CouWeeks2,//課程星期
                    CouWeeks: Weeks == true ? JSON.stringify(selected) : CouWeeks2,//課程星期
                    CouHoldID: CouHoldID,
                    CouHoldAmount: parseInt(CouHoldAmount),
                    CouSID: parseInt(CouSID),
                    CouID: parseInt(CouID),
                    CouPID: parseInt(CouPID),
                    CouName: CouName,
                    CouNo: CouNo,
                    CouTypeCID1: parseInt(CouTypeCID1),
                    CouTypeID: parseInt(CourseTypeID),
                    CouTeacherID: parseInt(CouTeacherID),
                    CouTeacherName: CouTeacherName,
                    CouMoney: parseInt(CouMoney),
                    CouAmount: parseInt(CouAmountT),
                    CouUSex: CouUSex,//性別
                    CouPlace: CouPlace,
                    CouHour: parseInt(CouHour) ,
                    CouAgeMin: parseInt(CouAgeMin),
                    CouAgeMax: parseInt(CouAgeMax),
                    CouStartDate: CouStartDate,
                    CouCloseDate: CouCloseDate,
                    CouStartTime: CouStartTime + ":00", //上課時間
                    CouCloseTime: CouCloseTime + ":00", //上課時間
                    CouDesc: CouDesc,
                    CouTarget: CouTarget,
                    SpecList: CouSpList,
                    CouATTID :ATTID,
                    Imgs: attList,
                    CardList: [
                        { "MCardType": "A", "MCardRegPoint": parseInt(MCardRegPoint), "MCardDiscPoint": parseInt(MCardDiscPoint), "MCardDiscMoney": parseInt(MCardDiscMoney) }
                    ]

                }
                if (Weeks && selected == "") {
                    openModalError("編輯", "尚有必填未填(橘色框)");
                    return;
                }
                API_Put_CourseBaseData(dt).then((response) => response.data)
                    .then((data) => {
                        console.log(data);
                        if (data.code == "0000") {//成功
                            openModalOk();
                            getData();
                            CloseModal("modal-edit");
                        }
                        else {
                            alert(data.message);
                            return;
                        }
                    })
                    .catch(err => {
                        if (err.response.status == "400") {
                            openModalError("編輯", "尚有必填未填");
                        }
                        return;
                    })
            }
            else {
                openModalError("編輯", "尚有必填未填");
            }
            
        }



        //else {
        //    setDataList(function (prev) {
        //        return prev.map((item, i) => {
        //            if (i == index) {
        //                item.isEdit = type == 1 ? true : false;
        //            }
        //            return item;
        //        });
        //    });
        //}
        
        

    }

    function handleChange(event, CouID) {

        if (event.target.files.length > 0) {
            console.log(attList);
            
            if (event.target.files[0].size > LimitSize) {
                openModalError("錯誤", "超過檔案大小");
                return;
            }
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                var nextFile = attList.filter((f) => f.fileName != "");
                nextFile.push({attid:null, fileName: event.target.files[0].name, base64String: reader.result, filePath : reader.result });
                //更新檔案名稱

                //更新檔案array
                setattList(nextFile);
                
            }

        }
    }
    function DelImg(i) {
        setattList(function (prev) {
            return prev.filter((x, idx) => idx != i);
        })
    }
    function preview(File) {
        setPrevImg(File);
        var id = "modal-Image";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    

    function openModalOk(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-ok";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    function delData() {
        //呼叫API
        //成功
        API_Del_CourseBaseData({
            CouID: dataList[tmpIndex].CouID,
            SpecList: []
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功
                    getData();
                } else {
                    alert(data.message);
                    return;
                }
            })
            .catch(err => {
                console.log(err);
            })
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

     
    function WeeksCheck() {
        setCouWeeks2("");
        setWeeks(true);
        setWeeks2(false); 
        var id = "Weeks2";
        var target = document.querySelector("#" + id);
        target.classList.remove("error");
    }
    function Weeks2Check() {
        setSelected([]);
        setWeeks2(true);       
        setWeeks(false);
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function CopyData(tmpIndex) {
        setEditcouNo(true);
        setCopy(true);
        setNew(false);      

        Edit(1, tmpIndex);
        setMCardRegPoint("");
        setMCardDiscPoint("");
        setMCardDiscMoney("");
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
         

       
        //Edit(2);
        //getData();
        //alert("複製成功");
    }
    const [New, setNew] = useState(false);
    function openModalEdit( tmpIndex) {
        required();
        if (tmpIndex == -1) {
            //if (tmpIndex.detail) {
      
            setEditcouNo(false);
            setNew(true);
            setCopy(false);
            setCouName("");
            setCouNo("");
            setCouID("");
            setCouMoney("");
            setCouAmountT("");
            setCouAgeMin("");
            setCouAgeMax("");
            setCouHoldAmount("");
            setCouHoldID("");
            setCouStartDate("");
            setCouCloseDate("");
            setCouStartTime("");
            setCouCloseTime("");
            setWeeks(false);
            setWeeks2(true);
            setCouWeeks("");
            setCouWeeks2("");
            setCouDesc("");
            setCouTarget("");
            setMCardRegPoint("");
            setMCardDiscPoint("");
            setMCardDiscMoney("");
            setattList([]);
            setCouPlace("");
            setCouRemark("");
            setCouHour("");
            setCouTeacherID("");
            setCourseTypeID("");
            setCouPID("");
            setCouTypeCID1("");
            setCouSID("");
            setATTID(null);
            setCouUSex("");
        }
        else {
  
            setCouRemark("");
            setEditcouNo(false);
            setNew(false);
            setCopy(false);
            Edit(1, tmpIndex);    
            setMCardRegPoint("");
            setMCardDiscPoint("");
            setMCardDiscMoney("");
            setCouPlace("");
            setCouHour("");
        }

        //setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalSignupView(isNew) {
        console.log(isNew);
        if (isNew) {
            setDetailCouSID(0);
        }
        else {
            setDetailCouSID(CouSID);
        }
        var id = "modal-edit-schd";
        /*var id = "modal-view";*/
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    
    
    


   
        return (
            <>
                <div className="main">
                    <div className="page-content">
                        <div className="content-body">
                            <div className="title-area">
                                <div className="containerB full">
                                    <h3 className="fz-A mr-1">課程內容管理</h3>
                                </div>
                            </div>

                            <div className="container full">
                                <div className="form-container">
                                    <div className="form-item twenty">
                                        <div className="top-table">期別</div>
                                        <div className="top-value">
                                            <select value={DropDownCouPID} onChange={(e) => { setDropDownCouPID(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {coursePeriodArrayList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">類別</div>
                                        <div className="top-value">
                                            <select value={DropDownCouTypeCID1} onChange={(e) => { setDropDownCouTypeCID1(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {courseTypeCArrayList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">班別</div>
                                        <div className="top-value">
                                            <select value={DropDownCouTypeID} onChange={(e) => { setDropDownCouTypeID(e.target.value) }}>
                                                <option value="">請選擇</option>
                                                {courseTypeArrayList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">課程名稱</div>
                                        <div className="top-value">
                                            <input type="text" value={DropDownCouName} onChange={(e) => { setDropDownCouName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <a className="btn btn-border btn-middle" onClick={getData}>
                                        <span >查詢</span>
                                    </a>

                                    <div className="right fc-1 info">
                                        <div className="form-item " style={{ margin: "0em", cursor: "pointer" }} onClick={(e) => { openModalEdit(-1) }}>
                                            <div
                                                className="btn btn-icon"

                                            >
                                                <i className="icon icon-14"></i>

                                            </div>
                                            <span className="fw-b"  >新增課程</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container full mt-1">
                                <div>
                                    <h5 className="mr-2">下載功能圖示說明</h5>
                                </div>
                                <div className="form-item">
                                    <i className="icon icon-22"></i>
                                    <div className="mr-1">複製並新增課程</div>

                                    <i className="icon icon-24"></i>
                                    <div className="mr-1">編輯課程</div>

                                    <i className="icon icon-4"></i>
                                    <div>刪除課程</div>
                                </div>
                                <div className="modal-body">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-0">ID</div>
                                            <div className="flex-1">班別序號</div>
                                            <div className="flex-1">課程名稱</div>
                                            <div className="flex-1">課程期別</div>
                                            <div className="flex-1">類別</div>
                                            <div className="flex-1">班別</div>
                                            <div className="flex-1">教師</div>
                                            <div className="flex-1">開課日期</div>
                                            <div className="flex-0">名額</div>
                                            <div className="flex-3">&ensp;操作</div>
                                        </li>
                                        {dataList.map((x, i) => (
                                            <li className="lt-row">
                                                <div className="flex-0">{i + 1}</div>
                                                <div className="flex-1">{x.CouNo}</div>
                                                <div className="flex-1">{x.CouName}</div>
                                                <div className="flex-1">{x.CouPName}</div>
                                                <div className="flex-1">{x.Kind}</div>
                                                <div className="flex-1">{x.ClassType}</div>
                                                <div className="flex-1">{x.CouTeacherName}</div>
                                                <div className="flex-1">{x.CouStartDate}</div>
                                                <div className="flex-0">{x.CouAmount}</div>
                                                <div className="flex-3 fn">
                                                    <div className="fn-bar">
                                                        <div
                                                            className="btn btn-icon"
                                                            onClick={(e) => CopyData(i)}
                                                        >
                                                            <i className="icon icon-22"></i>
                                                        </div>

                                                        <div
                                                            className="btn btn-icon"
                                                            onClick={(e) => openModalEdit(i)}
                                                        >
                                                            <i className="icon icon-24"></i>
                                                        </div>

                                                        <div
                                                            className="btn btn-icon"
                                                            onClick={(e) => openModalCheck(i)}
                                                        >
                                                            <i className="icon icon-4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="modal-check" className="modal small" data-modal>
                        <div className="modal-container">
                            <div className="modal-body">
                                <div className="icon-holder">
                                    <i className="icon icon-orange icon-9"></i>
                                </div>
                                <div className="text-holder">確認刪除?</div>
                                <div className="btn-holder">
                                    <a className="btn btn-border" data-close>
                                        <span>取消</span>
                                    </a>
                                    <a className="btn" data-close onClick={delData}>
                                        <span>確認</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="modal-edit" className="modal large" data-modal>
                        <div className="modal-container">
                            <div className="modal-header">
                                <h3 className="fz-B has-border">{Copy == true ? "複製課程" :  "編輯或新增課程"} </h3>
                            </div>
                            <div className="modal-body">
                                <div className="form-container">
                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '40px' }}>期別</div>
                                        <div className="value" style={{ width: 'calc(100% - 40px)' }}>
                                            {EditcouNo == false ?
                                                <select value={CouPID} className={`form-item must ${CouPNameError ? "show-help" : ""}`} onChange={(e) => { setCouPID(e.target.value) }} data-required>
                                                    <option value="">請選擇</option>
                                                    {coursePeriodArrayList.map((y) => (
                                                        <option value={y.id}>{y.name} </option>
                                                    ))}
                                                </select>
                                                :
                                                <select className=""  >
                                                    {coursePeriodArrayList.map((y) => (
                                                        <option value={CouPName} selected={y.name == CouPName ? true : false} > {y.name}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>課程名稱</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <input className={`form-item must ${CouNameError ? "show-help" : ""}`} type="text" value={CouName} onChange={(e) => { setCouName(e.target.value) }} data-required />
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>班別序號</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <input className={`form-item must ${CouNoError ? "show-help" : ""}`} type="text" value={CouNo} onChange={(e) => { setCouNo(e.target.value) }} data-required />
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '40px' }}>類別</div>
                                        <div className="value" style={{ width: 'calc(100% - 40px)' }}>
                                            {EditcouNo == false ?
                                                <select value={CouTypeCID1} className={`form-item must ${CouTypeError ? "show-help" : ""}`} onChange={(e) => { setCouTypeCID1(e.target.value) }} data-required>
                                                    <option value="">請選擇</option>
                                                    {courseTypeCArrayList.map((y, i) => (
                                                        <option value={y.id} >{y.name}  </option>
                                                    ))}
                                                </select>
                                                :
                                                <select name="" >
                                                    {courseTypeCArrayList.map((y) => (
                                                        <option value={Kind} selected={y.name == Kind ? true : false} >{y.name}  </option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>班別</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            {EditcouNo == false ?
                                                <select value={CourseTypeID} className={`form-item must ${CouClassTypeError ? "show-help" : ""}`} onChange={(e) => { setCourseTypeID(e.target.value), console.log(e.target.value) }} data-required >
                                                    <option value="">請選擇</option>
                                                    {courseTypeArrayList.map((y) => (
                                                        <option value={y.id}>{y.name}</option>
                                                    ))}
                                                </select>
                                                :
                                                <select name="" >
                                                    {courseTypeArrayList.map((y) => (
                                                        <option value={ClassType} selected={y.name == ClassType ? true : false} >{y.name}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>教師</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            {EditcouNo == false ?
                                                <select name="" value={CouTeacherID} onChange={(e) => { console.log(e.target.value),setCouTeacherID(e.target.value), setCouTeacherName(event.target.options[e.target.selectedIndex].text) }}   >
                                                    <option value="">請選擇</option>
                                                    {teacherBaseArrayList.map((y) => (
                                                        <option value={y.id} > {y.name} </option>
                                                    ))}
                                                </select>
                                                :
                                                <select name="">
                                                    {teacherBaseArrayList.map((y) => (
                                                        <option value={CouTeacherName} selected={y.name == CouTeacherName ? true : false} > {y.name} </option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>課程費用</div>
                                        <div className="value multi-inline" style={{ width: 'calc(100% - 70px)' }}>
                                            <input className={`form-item must ${CouMoneyError ? "show-help" : ""}`} type="text" value={CouMoney} onChange={(e) => { setCouMoney(e.target.value) }} data-required />
                                            <span className="fc-1 fw-b">&ensp;名額&ensp;</span>
                                            <input className={`form-item must ${CouAmountError ? "show-help" : ""}`} type="text" value={CouAmountT} onChange={(e) => { setCouAmountT(e.target.value) }} data-required />
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>學員性別</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            {EditcouNo == false ?
                                                <select name="" value={CouUSex} onChange={(e) => setCouUSex(e.target.value)} >
                                                    <option value="">請選擇</option>
                                                    {codeBaseSexArrayList.map((y) => (
                                                        <option value={y.id}>{y.name}</option>
                                                    ))}
                                                </select>
                                                :
                                                <select name="" >
                                                    {codeBaseSexArrayList.map((y) => (
                                                        <option value={Sex} selected={y.name == Sex ? true : false} >{y.name}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '100px' }}>學員年齡限制</div>
                                        <div className="value multi-inline" style={{ width: 'calc(100% - 100px)' }}>
                                            <input type="text" value={CouAgeMin} onChange={(e) => { setCouAgeMin(e.target.value) }} />
                                            &ensp;至&ensp;
                                            <input type="text" value={CouAgeMax} onChange={(e) => { setCouAgeMax(e.target.value) }} />
                                        </div>
                                    </div>

                                    {/*<div className="form-item thirty">*/}
                                    {/*    <div className="field" style={{ width: '165px' }}>身心障礙人士保障名額</div>*/}
                                    {/*    <div className="value" style={{ width: 'calc(100% - 165px)' }}>*/}
                                    {/*        <input type="text" value={CouAmountA} onChange={(e) => { setCouAmountA(e.target.value) }} />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '150px' }}>上階段課程保留名額</div>
                                        <div className="value" style={{ width: 'calc(100% - 150px)' }}>
                                            <input type="text" value={CouHoldAmount} onChange={(e) => { setCouHoldAmount(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '150px' }}>上階段課程班別序號</div>
                                        <div className="value" style={{ width: 'calc(100% - 150px)' }}>
                                            <input type="text" value={CouHoldID} onChange={(e) => { setCouHoldID(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="form-item thirty">
                                        <div className="field" style={{ width: '70px' }}>適用對象</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <input type="text" value={CouTarget} onChange={(e) => { setCouTarget(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="form-item quarter">
                                        <div className="field" style={{ width: '70px' }}>開課日期</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <input type="date" value={CouStartDate} onChange={(e) => { setCouStartDate(e.target.value) }} data-required />
                                        </div>
                                    </div>

                                    <div className="form-item quarter" >
                                        <div className="field" style={{ width: '70px' }}>結束日期</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <input type="date" value={CouCloseDate} onChange={(e) => { setCouCloseDate(e.target.value) }} data-required />
                                        </div>
                                    </div>

                                    <div className="form-item thirty" style={{ width: 'calc(50% - 70px)' }} >
                                        <div className="field" style={{ width: '70px' }}>上課時間</div>  {/*編輯的時候有問題 */}
                                        <div className="value multi-inline" style={{ width: 'calc(100% - 100px)' }}>
                                            {EditcouNo == false ?		                                                
                                                <input type="time" value={CouStartTime} onChange={(e) => { setCouStartTime(e.target.value) }}  data-required />
                                                :
                                                <input type="time" value={CouStartTime} onChange={(e) => { setCouStartTime(e.target.value) }}  />
                                            }
                                            {console.log("EditcouNo", EditcouNo, '修改', CouStartTime) }
                                            &ensp;至&ensp;		                                        
                                            {EditcouNo == false ?		
                                                <input type="time" value={CouCloseTime} onChange={(e) => { setCouCloseTime(e.target.value) }} data-required />		                                               
                                                :
                                                <input type="time" value={CouCloseTime} onChange={(e) => { setCouCloseTime(e.target.value) }}   />
                                            }
                                        </div>
                                    </div>

                                    <div className="form-item" style={{ margin: '0 10px' }}>
                                        <div className="field" style={{ width: '70px' }}>上課日期</div>
                                        <div className="value multi-inline" style={{ width: 'calc(100% - 70px)' }}>

                                            <input type="radio" id="f221-1" name="f221" value="f221-1" onChange={WeeksCheck} checked={Weeks} />
                                            <label htmlFor="f221-1">每週</label>
                                            {Weeks == true ?
                                                (EditcouNo == false ?
                                                    //<select name="" value={CouWeeks} className="mr-1 w1" onChange={(e) => setCouWeeks(e.target.value)} style={{ 'width': '120px' }}>
                                                    //    <option value="" disabled selected>請選擇</option>
                                                    //    {WeeksArrayList.map((y) => (
                                                    //        <option value={y.id}>{y.name}</option>
                                                    //    ))}
                                                    //</select>

                                                    //<pre>{JSON.stringify(selected)}
                                                    //   <MultiSelect
                                                    //    options={options}
                                                    //    value={selected}
                                                    //    onChange={setSelected}
                                                    //    labelledBy="Select"
                                                    //    /></pre>

                                                    selected == "" ?
                                                        <pre style={{ borderWidth: '3px', borderStyle: 'solid', borderColor: '#f28500', padding: '1px' }}>
                                                            <MultiSelect                                                            
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select"
                                                                data-required>
                                                            </MultiSelect>
                                                        </pre>
                                                    :
                                                        <pre >
                                                            <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select"
                                                                data-required>
                                                            </MultiSelect>
                                                        </pre>

                                                    :
                                                    //<select name="" className="mr-1 w1" value={CouWeeks} onChange={(e) => setCouWeeks(e.target.value)} style={{ 'width': '120px' }}>
                                                    //    <option value="" disabled selected>請選擇</option>
                                                    //    {WeeksArrayList.map((y) => (
                                                    //        <option value={y.id} selected={y.id == CouWeeks ? true : false}>{y.name}</option>
                                                    //    ))}
                                                    //</select>

                                                    selected == "" ?
                                                        <pre style={{ borderWidth: '3px', borderStyle: 'solid', borderColor: '#f28500', padding: '1px' }}>
                                                            <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select"
                                                                data-required>
                                                            </MultiSelect>
                                                        </pre>
                                                        :
                                                        <pre >
                                                            <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select"
                                                                data-required>
                                                            </MultiSelect>
                                                        </pre>

                                                )
                                                :
                                                (
                                                    <select name="" className="mr-1 w1" style={{ 'width': '120px' }} >
                                                    </select>    
                                                    
                                                )
                                            }
                                            <input type="radio" id="f221-2" name="Weeks2" value="f221-2" onChange={Weeks2Check} checked={Weeks2} />
                                            <label htmlFor="f221-2">非固定日期</label>
                                            <div className="value" style={{ marginRight: '0' }}>
                                                {Weeks2 == true ?
                                                    (EditcouNo == false ?
                                                        <input className={`form-item must ${CouWeek2Error ? "show-help" : ""}`} type="text" id="Weeks2" value={CouWeeks2} onChange={(e) => setCouWeeks2(e.target.value)} data-required />                                                          
                                                        :
                                                        <input type="text" id="Weeks2" value={CouWeeks2} onChange={(e) => setCouWeeks2(e.target.value)} data-required />
                                                    )
                                                    :
                                                    <input type="text" value={CouWeeks2}  />                                                  
                                                }
                                            </div>
                                         
                                            

                                        </div>
                                    </div>
                                    <div className="form-item" style={{ margin: '5px 10px' }}>
                                        <div className="field" style={{ width: '70px' }}>上課地點</div>
                                        <div className="value multi-inline" style={{ width: 'calc(40% - 70px)' }}>
                                            <input type="text" id="Weeks2" value={CouPlace} onChange={(e) => setCouPlace(e.target.value)} data-required />
                                        </div>
                                        
                                        <div className="form-item twenty">
                                            <div className="top-table">上課時數</div>
                                            <div className="top-value">
                                                <input type="text" value={CouHour} onChange={(e) => setCouHour(e.target.value)} />
                                            </div>
                                        </div>    
                                        
                                    </div>
                                  
                                    <hr style={{ margin: "0.5em" }} />
                                    <div className="form-item" style={{ margin: '0 10px', padding: '.5rem 0' }}>
                                        <div className="field" style={{ width: '90px' }}>可折扣身份</div>
                                        <div className="value multi-inline discount" style={{ width: 'calc(100% - 90px)' }}>
                                            {SpecList.map((x, i) => (
                                                <div className="discount-div">
                                                    <input type="checkbox" id={"spec_" + x.specDiscID} name={"spec_" + x.specDiscID} value={x.specDiscID} onClick={changetState} checked={CouSpList.filter((s) => s.specDiscID == x.specDiscID).length > 0 ? "checked" : ""} />

                                                    <label htmlFor={"spec_" + x.specDiscID}>{x.specName}</label>
                                                    <div>
                                                        <input type="text" style={{ width: '60px' }} value={
                                                            CouSpList.filter((s) => s.specDiscID == x.specDiscID).length > 0
                                                                ? CouSpList.filter((s) => s.specDiscID == x.specDiscID)[0].couSpecAmount
                                                                : ""} onChange={(e) => changetValue(e, x.specDiscID)} ></input>
                                                        <label style={{ paddingRight: "0.5em" }}>人</label>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <hr style={{ margin: "0.1em" }} />
                                    <div className="form-item" style={{ margin: '0 10px', padding: '.5rem 0' }}>
                                        <div className="field" style={{ width: '290px', color: 'red' }}>市圖閱讀存摺點數設定:</div>
                                        <div className="field" style={{ width: '140px' }}>報名最低點數限制</div>
                                        <input type="text" value={MCardRegPoint} onChange={(e) => setMCardRegPoint(e.target.value)} style={{ width: '60px' }} ></input>
                                        <div className="field" style={{ width: '200px' }}>點</div>
                                        <div className="field" style={{ width: '70px' }}>點數折抵</div>
                                        <input type="text" value={MCardDiscPoint} onChange={(e) => setMCardDiscPoint(e.target.value)} style={{ width: '60px' }} ></input>
                                        <div className="field" style={{ width: '50px' }}>點折抵</div>
                                        <div className="field" style={{ width: '50px' }}>點折抵</div>
                                        <input type="text" value={MCardDiscMoney} onChange={(e) => setMCardDiscMoney(e.target.value)} style={{ width: '70px' }} ></input>
                                        <div className="field" style={{ width: '50px' }}>元</div>
                                    </div>


                                    <div className="form-item half" style={{ margin: '0 10px' }}>
                                        <div className="field" style={{ width: '70px' }}>課程內容</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <textarea name="" cols="30" rows="2" value={CouDesc} onChange={(e) => setCouDesc(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="form-item half">
                                        <div className="field" style={{ width: '70px' }}>備註</div>
                                        <div className="value" style={{ width: 'calc(100% - 70px)' }}>
                                            <textarea name="" cols="30" rows="2" value={CouRemark} onChange={(e) => setCouRemark(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="form-item">
                                        <div className="value ">
                                            <label for={"file_" + CouID}><i className="icon icon-27" style={{ cursor: 'pointer' }}></i> </label>
                                            <input type="file" id={"file_" + CouID} name="file1" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleChange(e, CouID)} />
                                            <label for={"file_" + CouID} style={{ cursor: "pointer" }}>上傳圖檔</label>
                                        </div>

                                    </div>
                                    <div className="form-item">
                                        <div className="value multi-inline" id="imgDiv">
                                            {attList.map((x,i) => (

                                                <div className="form-border " >
                                                    <div className="CouImgDiv">
                                                        <span class="CouImgXX" onClick={()=>DelImg(i)}>✖</span>
                                                        <img class="h-full" style={{ width: '200px',marginRight:"10px" }} src={x.filePath}></img>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="form-item half" style={{ margin: '0 10px' }}>
                                        <div className="field" style={{ width: '100px' }}>報名作業時間</div>
                                        <div className="value multi-inline" style={{ width: 'calc(100% - 100px)' }}>
                                            {EditcouNo == false ?
                                                <select value={CouSID} className={`form-item must ${CouSNameError ? "show-help" : ""}`} onChange={(e) => { setCouSID(e.target.value) }} data-required>
                                                    <option value="">請選擇</option>
                                                    {courseSchdArrayList.map((y) => (
                                                        <option value={y.id}>{y.name} </option>
                                                    ))}
                                                </select>
                                                :
                                                <select name="" className="mr-1" onChange={(e) => { setCouSID(e.target.value) }} >
                                                    {courseSchdArrayList.map((y) => (
                                                        <option value={CouSID, y.id} selected={y.id == CouSID ? true : false} > {y.name} </option>
                                                    ))}
                                                </select>
                                            }
                                            <button className="btn btn-middle btn-border" onClick={() => openModalSignupView(false)}>
                                                <span>預覽</span>
                                            </button>
                                            <button className="btn btn-middle btn-border" onClick={() => openModalSignupView(true)}>
                                                <span>新增</span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <br />
                                <div className="btn-holder text-center">
                                    <a className="btn" onClick={(Copy) ? (e) => Edit(2) : New == true ? (e) => Edit(2) : (e) => Edit(3)}  >
                                        <span>存檔</span>
                                    </a>
                                    <a className="btn btn-border" onClick={required } data-close>
                                        <span>放棄</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="modal-Image" className="modal large" data-modal>
                        <div className="modal-container preview-modal">
                            <div className="modal-body">
                                <div className="preview-closebtn">
                                    <div className="btn btn-middle btn-border" data-close >
                                        <span>X</span>
                                    </div>
                                </div>
                                <div className="container full mt-1 center">
                                    <div className="form-item preview-form">
                                        <div className="value">
                                            <div className="btn-holder mt-1">

                                            </div>
                                        </div>
                                        <div className="fields preview-fields">

                                            <div className="form-border ">
                                                <img className="preview-img" src={PrevImg} img />
                                            </div>
                                            <br />
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                    <div id="modal-error" className="modal small" data-modal>
                        <div className="modal-container">
                            <div className="modal-body">
                                <div className="icon-holder">
                                    <i className="icon icon-43"></i>
                                </div>
                                <div className="text-holder">{modalTit}失敗</div>
                                <div className="btn-holder">失敗原因:{modalStr}</div>

                            </div>
                            <a className="btn btn-border btn-middle btn-holder " data-close  >
                                <span>確定</span>
                            </a>
                        </div>
                    </div>
                    <div id="modal-ok" className="modal small" data-modal>
                        <div className="modal-container">
                            <div className="modal-body">
                                <div className="icon-holder">
                                    <i className="icon icon-orange icon-16"></i>
                                </div>
                                <div className="text-holder">成功</div>
                                <div className="btn-holder">
                                    <a className="btn" data-close >
                                        <span>確認</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="modal-edit-schd" className="modal large" data-modal>
                        <SignupDetail editcouSID={DetailCouSID} setReGet={true} />
                    </div>
                    <div id="modal-view" className="modal small" data-modal>
                        <div className="modal-container">
                            <div className="modal-header">
                                <h3 className="fw-b">錄取模式：電腦隨機抽選</h3>
                            </div>
                            <div className="modal-body">

                                <div className="form-container">
                                    <div className="form-item" style={{ margin: '0' }}>
                                        <h3 className="mr-2 text-red">第一階段錄取方式採電腦隨機抽選</h3>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第一階段報名開始/結束日期時間</div>
                                    </div>
                                    <div className="form-item multi-inline" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第一階段隨機抽選/名單公布日期時間</div>
                                    </div>
                                    <div className="form-item multi-inline" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="field">通知方式</div>
                                        <div className="value multi-inline">
                                            <span className="mr-1">簡訊通知</span>
                                            <span>郵件通知</span>
                                        </div>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第一階段繳費期限日期時間</div>
                                    </div>
                                    <div className="form-item multi-inline" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item">
                                        <div className="field">催繳通知方式</div>
                                        <div className="value multi-inline">
                                            <span className="mr-1">簡訊通知</span>
                                            <span>郵件通知</span>
                                        </div>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <h3 className="mr-2 text-red">第二階段錄取方式採先報先取原則</h3>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第二階段報名開始/結束日期時間</div>
                                    </div>
                                    <div className="form-item multi-inline" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第二階段繳費期限日期時間</div>
                                    </div>
                                    <div className="form-item multi-inline" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="field">催繳通知方式</div>
                                        <div className="value multi-inline">
                                            <div className="value multi-inline">
                                                <span className="mr-1">簡訊通知</span>
                                                <span>郵件通知</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">錄取通知上課日期時間</div>
                                    </div>
                                    <div className="form-item" style={{ margin: '0' }}>
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                    <div className="form-item">
                                        <div className="field">通知方式</div>
                                        <div className="value multi-inline">
                                            <div className="value multi-inline">
                                                <span className="mr-1">簡訊通知</span>
                                                <span>郵件通知</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <h3 className="mr-2 text-red">第三階段開課時現場報名</h3>
                                    </div>

                                    <div className="form-item" style={{ margin: '0' }}>
                                        <div className="fw-b">第三階段名額公告日期時間</div>
                                    </div>
                                    <div className="form-item">
                                        <input type="date" style={{ width: '250px' }} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    

}
export default CourseContent;
