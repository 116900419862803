import "../../css/style.css";
import React, { useState, useEffect, useRef } from "react";
import { API_POST_Coursepost, API_Get_DropDown, API_POST_ExCouStud, API_POST_ExClass, API_POST_ExCouLottery } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";

function Pagination({ selectPage, pageData, totalPage, setPageData, dataList, setSelectPage }) {
    useEffect(() => {
        changePageData();
    }, [selectPage])

    function changePageData() {
        setPageData(dataList.filter((item, index) => index >= (0 + 20 * selectPage) && index < (20 + 20 * selectPage)));
    }

    return (
        <>
            <div className="container full">
                {pageData ?
                    <>
                        <div className="pagination">
                            <span className="page-link cursor-pointer" onClick={() => {
                                setSelectPage(selectPage - 1)
                            }} style={{ display: selectPage ? 'block' : 'none' }}>
                                上一頁
                            </span>
                            {[...Array(totalPage)].map((item, index) => {
                                return (
                                    <span

                                        key={index + 1}
                                        className={`page-link cursor-pointer ${index == selectPage && 'active'}`}
                                        onClick={(e) => { setSelectPage(index) }}
                                    >
                                        {index + 1}
                                    </span>
                                );
                            })}
                            <span className="page-link cursor-pointer" onClick={() => {
                                setSelectPage(selectPage + 1)
                            }} style={{ display: selectPage + 1 !== totalPage ? 'block' : 'none' }}>
                                下一頁
                            </span>
                        </div>

                    </>

                    :
                    <></>
                }
            </div>
        </>
    );
}
export default Pagination;
