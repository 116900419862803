import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_POST_AddCourseType,
    API_Get_PayRefundDataget,
    API_Put_ReFundput,
    API_Put_Payput,
    API_Get_ReceiptPDF,
    API_UserRefund,
    API_BackURegDetail,
    API_BUserRefund
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import Swal from "sweetalert2";


function SpotPayRefund() {
    var history = useHistory();
    
    useEffect(() => {
        getData();
    }, []);

    function getData() {
        if (AcntName != "" || URegIDNo != "") {
            API_Get_PayRefundDataget({
                UserName: AcntName,
                UserIDNo: URegIDNo
            })
                .then((response) => response.data)
                .then((data) => {

                    if (!checkAuthToken(data)) {
                        window.location = "/";
                    }
                    if (data.code == "0000") {
                        console.log(data);
                        console.log("123");
                        console.log("測試中");
                        setDataList(data.result);
                    }
                    else {
                        alert(data.message);
                        setDataList([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            
            console.log(dataList);
        }
       
    }

    const FileDownload = require("js-file-download");
    const [AcntName, setAcntName] = useState("");
    const [URegIDNo, setURegIDNo] = useState("");//身分證
    const [URegID, setURegID] = useState("");//ID
 
    const [URFMoney, setURFMoney] = useState("");//退費金額
    const [URegMoney, setURegMoney] = useState("");//繳費金額
    const [UserName, setUserName] = useState("");//姓名

    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([]);

    const [RefundFile, setRefundFile] = useState(); //上傳文件
    const [URFCaption, setURFCaption] = useState("");
    const [userIDNo, setUserIDNo] = useState("");
    const LimitSize = 5242880;
    const [URegDetail, setURegDetail] = useState({});
    const [UserID, setUserID] = useState("");//UserID

    const [isDivVisible, setDivVisible] = useState(true);

    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) {
            //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            })
                .then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        //成功
                        //getDashboard();
                        //localStorage.setItem('token', data.token.token);
                        //localStorage.setItem('user', data.result.loginData.userNo);
                        //localStorage.setItem('userName', data.result.loginData.userName);
                        //localStorage.setItem('userID', data.result.loginData.userID);
                        //history.push('?path=changepassword')
                    } else if (data.code == "2013") {
                        //特定的失敗原因
                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }
    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }
    function MLink() {
       
        location.href = "Main?path=QuestionMaintain";
   
    }
    function RLink() {
        location.href = "Main?path=QuestionResult";

    }
    //退費
    function ReFundput() {
        var dt = {
            URegID: URegID,
        };
        API_Put_ReFundput(dt).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功
                    getData();
                    CloseModal("modal-check-refund");
                }
                else {
                    alert(data.message);
                    return;
                }
            })
            .catch(err => {
                alert(data.message);
                return;
            })
    }
    //繳費
    function Payput() {
        var dt = {
            URegID: URegID,
        };
        API_Put_Payput(dt).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功
                    getData();
                    CloseModal("modal-check");
                }
                else {
                    alert(data.message);
                    return;
                }
            })
            .catch(err => {
                alert(data.message);
                return;
            })
    }
 
    //收據
    function ReceiptPDF(index) {
        API_Get_ReceiptPDF({
            URegID: dataList[index].URegID,
        }).then((response) => {
            FileDownload(response.data, dataList[index].CouName + "繳費證明.pdf");      
        });

    }

    function openModalCheck(tmpIndex) {
        //setTmpIndex(tmpIndex);
        console.log(tmpIndex);
        setURegID(dataList[tmpIndex].URegID);

        setUserID(dataList[tmpIndex].UserID);

        setUserName(dataList[tmpIndex].UserName);
      
        setURegMoney(dataList[tmpIndex].URegMoney);
  
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
      
    }
    function openModalCheckRefund(tmpIndex) {
        //setTmpIndex(tmpIndex);

        setURegID(dataList[tmpIndex].URegID);
        setURFMoney(dataList[tmpIndex].URFMoney);
        setUserName(dataList[tmpIndex].UserName);

        
        var id = "modal-check-refund";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);

        var dt = {
            UserID: dataList[tmpIndex].UserID,
            URegID: dataList[tmpIndex].URegID
        }
      
        API_BackURegDetail(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    if (data.result != null) {
                        setURegDetail(data.result);
                 /*       setURegID(data.result.URegID);*/
                       /* setURegID(data.result[0].URegID);//使用者報名序號*/
                        //setURegID(dt.URegID);
                        //console.log(URegID);
                    }
                    else {
                        console.log(data.message);
                        Swal.fire("錯誤，請聯繫管理員。", "", "error");
                        window.location = "/";
                    }
                }
                else {
                    console.log(data.message);
                    Swal.fire("錯誤，請聯繫管理員。", "", "error");
                    window.location = "/Main?path=/SpotPayRefund/";
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

    //上傳檔案
    function FileUpload(event) {
        if (event.target.files.length > 0) {
            console.log(event.target.files);
            if (event.target.files[0].size > LimitSize) {
                Swal.fire("超過檔案大小(" + LimitSize / 1024 / 1024 + "MB)", "", "error");
                //alert("超過檔案大小");
                return;
            }
            setRefundFile(event.target.files[0]);
            var filename = event.target.files[0].name;
            if (filename.length > 16) {
                filename = filename.substring(1, 16) + "...";
            }
            document.getElementById("FileLabel").innerText = filename;
        }
    }
    

    //前台Copy的退費
    function RefundOK() {
       
        var dt = {
            URegID: dataList[tmpIndex].URegID,
            URFCaption: URFCaption
        };
        console.log(dt);
        API_BUserRefund(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {

                    Swal.fire(data.message, "", "success").then(ok => {
                        window.location = "/Main?path=SpotPayRefund";
                    });
                }
                else if (data.code == "2001") {
                    Swal.fire("資料或檔案未完整。", "", "error");
                }
                else {
                    Swal.fire(data.message, "", "error").then(ok => {
                        //alert(data.message);
                        window.location = "/Main?path=/SpotPayRefund";
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })

        event.preventDefault();
    } 

    function RefoundInfo(tmpIndex) {
        
        //setUserIDNo(JSON.parse(localStorage.getItem("UserData")).userIDNo);
        //setUserName(JSON.parse(localStorage.getItem("UserData")).userName);
        setURegID(dataList[tmpIndex].URegID);
        setUserID(dataList[tmpIndex].UserID);
         
        if (URegID == null || URegID == "") {
            history.goBack();
        }

        var dt = {
            UserID: UserID,
            URegID: URegID
        }
      
        API_BURegDetail(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    if (data.result != null) {
                        setURegDetail(data.result);
                    }
                    else {
                        console.log(data.message);
                        window.location = "/";
                    }
                }
                else {
                    console.log(data.message);
                    window.location = "/";
                }
            })
            .catch(err => {
                console.log(err);
            })


    }


    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">現場繳費/退費</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item multi-inline">
                                    <div>身分/居留證號：</div>
                                    <input type="text" value={URegIDNo} onChange={(e) => { setURegIDNo(e.target.value) }} style={{ width: '150px' }} />

                                    <div className="ml-1">姓名：</div>
                                    <input type="text" value={AcntName} onChange={(e) => { setAcntName(e.target.value) }} style={{ width: '150px' }} className="mr-1" />

                                    <a className="btn btn-border btn-middle" onClick={getData}>
                                        <span>查詢</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {dataList.length == 0 ? null :
                            <div className="container full mt-1">
                                <div className="modal-body">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">ID</div>
                                            <div className="flex-1">班別序號</div>
                                            <div className="flex-2">課程名稱</div>
                                            <div className="flex-1">課程期別</div>
                                            <div className="flex-1">類別</div>
                                            <div className="flex-1">班別</div>
                                            <div className="flex-1">開課日期</div>
                                            <div className="flex-1">狀態</div>
                                            <div className="flex-1">申請退費</div>
                                            <div className="flex-1">現場退款</div>
                                            <div className="flex-1">繳費</div>
                                        </li>
                                        {dataList.map((x, i) => (
                                            <li className="lt-row">
                                                <div className="flex-1">
                                                    {i + 1}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouNo}
                                                </div>
                                                <div className="flex-2">
                                                    {x.CouName}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouPName}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouTypeName}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouTypeName}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouStartDate}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CodeText}
                                                </div>
                                               
                                                <div className="flex-1"> 
                                                    <div className="btn btn-small btn-border" onClick={(e) => openModalEdit(i)} >
                                                        <span>退費</span>
                                                    </div>
                                                </div>

                                                <div className="flex-1">
                                                    {x.Fund_YN == 1 ? (
                                                        /*<a className="btn btn-border btn-middle">
                                                            <span>退費</span>
                                                        </a>*/
                                                        <div className="btn btn-small btn-border"
                                                            onClick={(e) => openModalCheckRefund(i)} >
                                                            <span>退費</span>
                                                        </div>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </div>

                                                <div className="flex-1">
                                                    {x.PAY_YN == 0 ? (
                                                        <span></span>
                                                    ) : (
                                                        x.PAY_YN == 1 ? (

                                                            <div className="btn btn-small btn-border"
                                                                onClick={(e) => openModalCheck(i)} >
                                                                <span>繳費</span>
                                                            </div>
                                                        ) : (

                                                            <div className="btn btn-small btn-border" onClick={(e) => ReceiptPDF(i)}>
                                                                <span>收據</span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>

                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            </div>
                        }
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確定點收到{UserName}繳交的現金{URegMoney}元整？</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" onClick={Payput} data-close>
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check-refund" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確定將現金{URFMoney}元現金整退還給{UserName}， 並確認{UserName}當場已點清？</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" onClick={ReFundput} data-close >
                                    <span>確定</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


           {/*     <form onSubmit={RefundOK}>*/}
                    <div id="modal-edit" className="modal" data-modal>
                        <div className="modal-container">
                            <div className="form-container">
                                <h2 style={{ color: "transparent" }}>退費內容</h2>
                                <div className="max-w-xl mx-auto">

                                    {/* <p className="my-4 text-center">身分證號碼 / 居留證號：{URegIDNo}　<span className="mobile-block">姓名：{UserName}</span></p>*/}
                               
                                    <div className="form-item">身分證號碼 / 居留證號：{URegIDNo}　{/*<span className="mobile-block">姓名：{UserName}</span>*/}</div>
                                    
                                    <div className="form-item">

                                        <div className="field">退費申請原因</div>
                                            <div className="value">
                                                <input type="text" placeholder="請填寫退費原因" value={URFCaption} onChange={(e) => { setURFCaption(e.target.value) }} required />
                                        </div>
                                    </div>

                                    {/*<div className="form-item">*/}
                                    {/*    <div className="field">退費文件上傳</div>*/}
                                    {/*    <div className="value" style={{ justifyContent: "space-between" }}>*/}
                                    {/*        <label for="" id="FileLabel" className="" style={{ cursor: "default" }}>尚未選擇文件</label>*/}
                                    {/*        <input id="RefundFile" onChange={FileUpload} style={{ opacity: "0", width: "1px", height: "1px", margin: "0px", padding: "0px" }} name="RefundFile" type="file" className="" placeholder="請填寫退費原因" required />*/}
                                    {/*        <label for="RefundFile" className="btn-line btn-primary mr-2">選擇退費證明文件</label>*/}

                                    {/*    </div>*/}
                                    {/*</div>*/}
                              
                                    <div className="form-item">
                                        <h3>{URegDetail.DeptName}｜{URegDetail.CouPName}｜班別序號：{URegDetail.CouNo}</h3>
                                      
                                        課程：{URegDetail.CouName}
                                        <br />
                                        上課日期：{URegDetail.CouStartDate} ~ {URegDetail.CouCloseDate}
                                        <br />
                                        上課時段：（{URegDetail.CouWeeks}）{URegDetail.CouStartTime} - {URegDetail.CouCloseTime}
                                        <hr />
                                        預計退費金額：依簡章規定計算{/*<b className="refund">{URegDetail.CouMoney}元</b>*/}
                             
                                        ※ 預計退費金額僅為參考，實際退費金額以審核結果為主。審核通過後，會簡訊通知至現場進行退費。
                                    </div>

                                    <div className="form-item btn-area" >
                                   {/*   <button type="button" className="btn btn-primary" onClick={handleButtonClick} >取消申請</button>*/}
                                    <a className="btn btn-border" data-close> <span>取消申請</span> </a>
                                        <button type="submit" className="btn btn-primary" onClick={RefundOK} >確認申請退費</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> 
               {/* </form>*/}
            </div>
        </>
    );
}
export default SpotPayRefund;
