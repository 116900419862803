import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_QunData, API_Post_QunData, API_Put_QunData, API_Put_QunDataChoice, API_Del_QunData } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { CloseModal, OpenModal } from "../../global/ToolUtil";
import Swal from 'sweetalert2'

function QuestionMaintain() {
    var history = useHistory();
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [QunList, setQunList] = useState([{}]);
    const [ChoiceList, setChoiceList] = useState([{}]);
    const [QunHtml, setQunHtml] = useState([{}]);
    const [type, settype] = useState(0);
    useEffect(() => {
        getData();
    }, []);

    function getData() {
        API_QunData()
            .then((res) => res.data)
            .then(data => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }

                if (data.code = "0000") {
                    //setSpecList(data.result.specList);
                    setQunList(data.result.qunList);
                    setChoiceList(data.result.choiceList);
                    console.log(data.result.qunHtml);
                    setQunHtml(data.result.qunHtml);
                }
                else {
                    openModalError("錯誤", data.message);
                }
            }).catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
                else {
                    Search();
                    openModalError("錯誤", data.message);
                }
            });
    }
    
    function Edit(type, index) {
        if (type == 2 && QunList[index].QunSNo == null) { //按下的是新增

            if (!QunList[index].QunText) {
                Swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '問題內容有誤，請重新輸入。',
                    showConfirmButton: true,
                    showCloseButton: true
                })
                return;
            }

            if (!QunList[index].QunType) {
                Swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '尚未選擇為題類型。',
                    showConfirmButton: true,
                    showCloseButton: true
                })
                return;
            }

            //呼叫API新增
            API_Post_QunData({
                QunText: QunList[index].QunText, QunType: QunList[index].QunType
            }).then((response) => response.data)
                .then((data) => {
                    console.log(QunList[index]);
                    if (!checkAuthToken(data)) {
                        window.location = "/";
                    }
                    if (data.code == "0000") {//成功
                        Edit(0, index);
                        OpenModal("modal-ok");
                    }
                    else if (data.code == "2013") { //特定的失敗原因
                        openModalError("錯誤", data.nessage);
                    } else {
                        openModalError("錯誤", data.nessage);
                    }

                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                        localStorage.clear();
                        history.push("/");
                    }
                    else {
                        Search();
                        openModalError("錯誤", data.message);
                    }
                });
        }
        else if (type == 1) {
            setQunList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            Edit(0, index);
                            API_Put_QunData({ QunSNo: QunList[index].QunSNo, QunText: QunList[index].QunText, QunType: QunList[index].QunType })
                                .then((res) => res.data)
                                .then(data => {
                                    if (data.code == "0000") {//成功
                                        Edit(0, index);
                                        OpenModal("modal-ok");
                                    }
                                    else if (data.code == "2013") { //特定的失敗原因


                                    } else {
                                        setMsgStr(data.message);
                                    }
                                })
                        }
                        
                        item.isEdit = type == 1 ? true : false;

                    }
                    return item;
                });
            });
        }
        else {
            settype(0);
            setQunList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        console.log(item);
                        if (item.isEdit == true) {
                            item.isEdit = false;
                        }
                        if (item.QunSNo == null) {
                            getData();
                        }
                    }
                    return item;
                });
            });
        }
    }
    function EditChoice(type, index) {
        if (type == 1) {
            setChoiceList(function (prev) {
                return prev.map((item, i) => {                    
                    if (i == index) {
                        if (item.isEdit == true) {
                            var dt = { QunCID: ChoiceList[i].QunCID, QunCValue: ChoiceList[i].QunCValue, QunCText: ChoiceList[i].QunCText }
                            API_Put_QunDataChoice(dt)
                                .then((res) => res.data)
                                .then(data => {
                                    if (!checkAuthToken(data)) {
                                        window.location = "/";
                                    }
                                    if (data.code == "0000") {//成功
                                        EditChoice(0, index);
                                        OpenModal("modal-ok");
                                    }
                                    else if (data.code == "2013") { //特定的失敗原因
                                        openModalError("錯誤", data.nessage);
                                    } else {
                                        openModalError("錯誤", data.nessage);
                                    }
                                }).catch((err) => {
                                    if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                                        localStorage.clear();
                                       history.push("/");
                                    }
                                });                        
                        }
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }
        else {
            setChoiceList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            item.isEdit = false;
                            getData();
                        }
                    }
                    return item;
                });
            });
        }
    }
    function Add(e) {

        settype(2);
        setQunList(function (prev) {
            return [...prev, { QunSNo: null, QunNo: null, CQunNo: null, QunText: null, QunType:null, isEdit: true }];
        });
    }
    function Delete() {
        API_Del_QunData({ QunSno: QunList[tmpIndex].QunSNo })
            .then((response) => response.data)
            .then((data) =>
            {
                if (data.code == "0000") {
                    OpenModal("modal-ok");
                    getData();
                }
                else {
                    openModalError("錯誤", data.message);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
                else {
                    Search();
                    openModalError("錯誤", data.message);
                }
            });
                
    }
    function openModalCheck(idx) {
        setTmpIndex(idx);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalStr(msg);
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function setQunText(e, idx) {
        setQunList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.QunText = e.target.value;
                }
                return item;
            });
        });
    }
    function setQunCText(e, idx) {
        setChoiceList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.QunCText = e.target.value;
                }
                return item;
            });
        });
    }
    function setQunCValue(e, idx) {
        setChoiceList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.QunCValue = e.target.value;
                }
                return item;
            });
        });
    }
    return (
        <>
            <div className="main">
                <div className="page-content h-full">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-B mr-1 has-border">問卷維護</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="info-holder">
                                <div className="left">

                                </div>
                                <div className="right fc-1">
                                    <div className="btn btn-icon" onClick={type == 0 ? Add : null}>
                                    <i className="icon icon-14 mr-1"></i>                              
                                        
                                    </div>
                                    <span className="fw-b">新增</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-4">問題內容</div>
                                        <div className="flex-1">問題類型</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                        <div className="flex-1">&ensp;刪除</div>
                                    </li>
                                    {QunList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-4">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.QunText} onChange={ (e)=> setQunText(e,i)} />
                                                ) : (
                                                        x.QunText
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ?
                                                    (
                                                        <select value={x.QunType} onChange={(e) => {
                                                            setQunList(function (prev) {
                                                                return prev.map((item, idx) => {
                                                                    if (i == idx) {
                                                                        item.QunType = e.target.value;
                                                                    }
                                                                    return item;
                                                                })
                                                            })
                                                        }}>
                                                            <option value="" >請選擇</option>
                                                            {QunHtml.map((y) => (
                                                                <option value={y.QunType}>{y.QunName} </option>
                                                            ))}
                                                        </select>
                                                    )
                                                    : (QunHtml.filter(f => f.QunType == x.QunType).length > 0 ? QunHtml.filter(f => f.QunType == x.QunType)[0].QunName : "")
                                                    }
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    {
                                                        x.QunSNo == null ?
                                                            //<div class="btn btn-small btn-border" onClick={(e) => Edit(2, i)}>
                                                            //    <span>確定</span>
                                                            //</div>
                                                            <div className="btn btn-icon" >
                                                                {/*<span>確定</span>*/}
                                                                <i className="icon icon-orange icon-16" onClick={(e) => Edit(2, i)}></i>
                                                                <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                            </div>
                                                            :
                                                            x.isEdit == true ?
                                                                <div className="btn btn-icon" >
                                                                    {/*<span>確定</span>*/}
                                                                    <i className="icon icon-orange icon-16" onClick={(e) => Edit(1, i)}></i>
                                                                    <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                                </div>
                                                                :
                                                                <div className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                                    <i className="icon icon-24"></i>
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={()=>openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}                                    
                                </ul>
                            </div>
                        </div>
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-B mr-1 has-border">滿意度內容維護</h3>
                            </div>
                        </div>
                        <div className="container mt-1">                           
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">選項</div>
                                        <div className="flex-2">答案內容</div>
                                        <div className="flex-2">配分</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                    </li>
                                    {ChoiceList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-1">{x.QunCID}</div>
                                            <div className="flex-2">{
                                                x.isEdit ?
                                                    <input type="text" value={x.QunCText} onChange={(e) => setQunCText(e, i)} />
                                                :
                                                     x.QunCText 
                                            }</div>
                                            <div className="flex-2">{
                                                x.isEdit ?
                                                    <input type="number" value={x.QunCValue} onChange={(e) => setQunCValue(e, i)} />
                                                    :
                                                    x.QunCValue
                                            }</div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    {
                                                        x.isEdit == true ?
                                                            <div className="btn btn-icon" >
                                                                {/*<span>確定</span>*/}
                                                                <i className="icon icon-orange icon-16" onClick={(e) => EditChoice(1, i)}></i>
                                                                <i className="icon icon-gray icon-38" onClick={(e) => EditChoice(0, i)}></i>
                                                            </div>

                                                            :
                                                            <div className="btn btn-icon" onClick={(e) => EditChoice(1, i)}>
                                                                <i className="icon icon-24"></i>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                   
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={Delete}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
                <div id="modal-ok" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-16"></i>
                            </div>
                            <div className="text-holder">成功</div>
                            <div className="btn-holder">
                                <a className="btn" onClick={() => CloseModal("modal-ok")} >
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default QuestionMaintain;
