import "../css/style.css";
import { FormattedMessage } from "react-intl"; //多國語言套件
//import Notify from '../components/Dashboard/Notify'
//import PermissionPanel from '../components/Dashboard/PermissionPanel'
//import AddUser from '../components/Dashboard/AddUser'

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function dashboard() {
  const history = useHistory();
  const [username, setUserName] = useState("");
  var currentModal;
  let currentLevel = 1;
  const modals = document.querySelectorAll("[data-modal]");
  const modalBtns = document.querySelectorAll("[data-openmodal]");
  modals.forEach((element) => {
    var modalBody = element.querySelector(".modal-container");
    var modalClose = element.querySelectorAll("[data-close]");
    var modalsBack = element.querySelector("[data-back]");
    //console.log(modalClose);
    element.addEventListener("click", function (e) {
      if (e.target == modalsBack) {
        var showLayerClass = "has-layer-" + currentLevel;
        currentLevel = currentLevel - 1;
        element.classList.remove("active");
        document.body.classList.remove(showLayerClass);
      } else if (e.target == element || e.target.hasAttribute("data-close")) {
        /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

        var showLayerClass = "has-layer-" + currentLevel;
        if (typeof currentModal !== "undefined") {
          currentModal.classList.remove("active");
        } else {
        }
        currentLevel = currentLevel - 1;
        element.classList.remove(
          "active",
          "layer-1",
          "layer-2",
          "layer-3",
          "layer-4",
          "layer-5",
          "layer-6"
        );
        document.body.classList.remove(showLayerClass);
      }
    });
  });

  useEffect(() => {
    // 更新body的class
    document.body.classList.remove("login");
    document.body.classList.add("dashboard");
    document.getElementById("root").classList.remove("center");
    //setUserName(localStorage.getItem("userName"));
  }, []);
  //const menuArray = JSON.parse(localStorage.getItem('menulist'));
  //console.log(menuArray);
  //const menuArrayLen = menuArray.filter(item => item.funcListType == "-1").length;
  function logout() {
    localStorage.clear();
    history.push("/");
  }
  //function erprefresh() {
  //    alert("ERP刷新成功");
  //}
  return (
    <>
      <div className="dashboard-header">
        <div>
          <div className="left">
            <div className="title">
              <i className="icon  icon-31"></i>
              <h1>
                <FormattedMessage id="systemName" />
                {/*系統名稱*/}
              </h1>
            </div>
            <div className="welcome">
              <h6 className="fz-B">wellcome !</h6>
              <h2 className="fz-A">{username}</h2>
            </div>
          </div>
          <div className="right">
            <div className="btn-logout" onClick={logout}>
              <i className="icon icon-3"></i>
              <span className="fw-b">登出</span>
            </div>
            <div className="fn"></div>
          </div>
        </div>
      </div>
      <div className="dashboard-body">
        <div className="scrollbar">
          <div className="container">
            <div className="left">Notify</div>
            <div className="right">PermissionPanel</div>
            AddUser
            <div className="bottom copyright">
              <span>2022 XXXXX Company Limited. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default dashboard;
