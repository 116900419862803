import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_POST_AddCourseType,
    API_Get_DeptNoticeData,
    API_Get_NoticeItem,
    API_Put_CouNoget,
    API_Post_DeptNoticeTest,
    API_Put_DeptNoticeData,
    API_Get_NoticeSetDataS,
    API_Put_NoticeSetSput
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
function InformBasic() {
    var history = useHistory();
    useEffect(() => {
        getData();

    }, []);

    function getData() {
        API_Get_DeptNoticeData().then((response) => response.data)
            .then((data) => {
                //console.log(data);
                setDeptNID(data.result[0].deptNID);
                setDeptSMSAcnt(data.result[0].deptSMSAcnt);
                setDeptSMSPwd(data.result[0].deptSMSPwd);
                setDeptMailAcnt(data.result[0].deptMailAcnt);
                setDeptMailPwd(data.result[0].deptMailPwd);
                setDeptSmtp(data.result[0].deptSmtp);
                setDeptTestMail(data.result[0].deptTestMail);
                setDeptTestPhone(data.result[0].deptTestPhone);

            })
            .catch(err => {
                console.log(err);
                
            })

        API_Get_NoticeSetDataS().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    setDataList(data.result);
                }
                else  {
                    setDataList([{}]);
                }
            })
            .catch(err => {
                console.log(err);

            })
    }
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [deptSMSIsEdit, setDeptSMSIsEdit] = useState(false);
    const [deptMailIsEdit, setDeptMailIsEdit] = useState(false);
    const [deptTestIsEdit, setDeptTestIsEdit] = useState(false);
    const [tmpIndex, setTmpIndex] = useState();
    const [SMSLen, setSMSLen] = useState(0);//計算字數
    const [deptNID, setDeptNID] = useState([]);
    const [deptSMSAcnt, setDeptSMSAcnt] = useState([]);
    const [deptSMSPwd, setDeptSMSPwd] = useState([]);
    const [deptMailAcnt, setDeptMailAcnt] = useState([]);
    const [deptMailPwd, setDeptMailPwd] = useState([]);
    const [deptSmtp, setDeptSmtp] = useState([]);
    const [deptTestMail, setDeptTestMail] = useState([]);
    const [deptTestPhone, setDeptTestPhone] = useState([]);

    const [noticeID, setNoticeID] = useState([]);
    const [noticeNo, setNoticeNo] = useState([]);
    const [noticeName, setNoticeName] = useState([]);
    const [noticeSMS, setNoticeSMS] = useState([]);
    const [noticeEmail, setNoticeEmail] = useState([]);

    const [dataList, setDataList] = useState([]);

    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉

    const [CouNoArrayList, setCouNoArrayList] = useState([{}]);//課程清單
    const [DropDownCouNo, setDropDownCouNo] = useState(""); //課程下拉
    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) { //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        //getDashboard();
                        //localStorage.setItem('token', data.token.token);
                        //localStorage.setItem('user', data.result.loginData.userNo);
                        //localStorage.setItem('userName', data.result.loginData.userName);
                        //localStorage.setItem('userID', data.result.loginData.userID);
                        //history.push('?path=changepassword')

                    }
                    else if (data.code == "2013") { //特定的失敗原因


                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }

    }
    function Save() {
        API_Put_DeptNoticeData({
            DeptNID: deptNID,
            DeptSMSAcnt: deptSMSAcnt,
            DeptSMSPwd: deptSMSPwd,
            DeptMailAcnt: deptMailAcnt,
            DeptMailPwd: deptMailPwd,
            DeptSmtp: deptSmtp,
            DeptTestPhone: deptTestPhone,
            DeptTestMail: deptTestMail
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    window.location.reload();
                } else {
                    openModalError("更新", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function CancelSMS() {
        setDeptSMSIsEdit(false);
        getData();
    }
    function CancelMail() {
        setDeptMailIsEdit(false);
        getData();
    }
    function CancelTest() {
        setDeptTestIsEdit(false);
        getData();
    }
    function delData(e) {
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, isEdit: true }];
        });
    }
    function Add(e) {
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, isEdit: true }];
        });
    }
    function MLink (){
        location.href = "Main?path=InformScheduleModify";
    }
    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalSuccess(title) {
        setModalTit(title);

        var id = "modal-successs";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {

        selNotice("");
        setTmpIndex(tmpIndex);
        setNoticeID(dataList[tmpIndex].noticeID);
        setNoticeNo(dataList[tmpIndex].noticeNo);
        setNoticeName(dataList[tmpIndex].noticeName);
        setNoticeSMS(dataList[tmpIndex].noticeSMS);
        setNoticeEmail(dataList[tmpIndex].noticeEmail);
   
        setSMSLen(dataList[tmpIndex].noticeSMS.length);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function SMSLength(e) {
        //console.log(e.target.value);
        setNoticeSMS(e.target.value);
        setSMSLen(e.target.value.length);
    }
    //下拉選單 期別
    function selNotice(type) {
        API_Get_NoticeItem()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //下拉選單 選定期別 連動班別序號
    function selCouPID(PID) {
        setDropDownCouPID(PID);
        console.log(PID);
        API_Put_CouNoget({
            CouPID: PID
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setCouNoArrayList(data.result);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function Cancel() {
        setDropDownCouPID("");
        setDropDownCouNo("");
        getData();
      
    }
    function UpData() {
        API_Put_NoticeSetSput({
            NoticeID: noticeID,
            NoticeNo: noticeNo,
            NoticeName: noticeName,
            NoticeSMS: noticeSMS,
            NoticeEmail: noticeEmail
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    //window.location.reload();
                    openModalSuccess("更新", data.message);
                } else {
                    openModalError("更新", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function TestNotice() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        if (result) {
            API_Post_DeptNoticeTest({
                CouPID: DropDownCouPID,
                CouNO: DropDownCouNo,
                NoticeID: noticeID,
                NoticeName: noticeName,
                NoticeSMS: noticeSMS, //todo: add port
                NoticeEmail: noticeEmail
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        openModalSuccess("發送", data.message);
                        setDropDownCouPID("");
                        setDropDownCouNo("");
                    } else {
                        openModalError("發送", data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        }
    }
    return (
        <>
            <div className="main">

                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">發送通知基本設定</h3>
                            </div>
                        </div>

                        <div className="container full">

                            <div className="form-container">
                                <div className="form-item">
                                    <h3 className="mr-2 text-red">emome簡訊傳送設置</h3>
                                </div>

                                <div className="form-item quarter ">
                                    
                                    <div className="top-table">登入帳號</div>
                                    <div className="top-value">
                                        {deptSMSIsEdit == true ? (
                                            <input type="text" value={deptSMSAcnt} onChange={(e) => setDeptSMSAcnt(e.target.value)} /> 
                                        ) : (
                                            <input type="text" value={deptSMSAcnt} disabled= "true" /> 
                                        )}
                                       
                                    </div> 
                                   
                                </div>

                                <div className="form-item quarter">
                  
                                    <div className="top-table">登入密碼</div>
                                    <div className="top-value">
                                        {deptSMSIsEdit == true ? (
                                            <input type="text" value={deptSMSPwd} onChange={(e) => setDeptSMSPwd(e.target.value)} />
                                        ) : (
                                            <input type="text" value={deptSMSPwd} disabled="true" />
                                        )}
                                    </div>
                                </div>
                               
                                {deptSMSIsEdit == true ? (
                                    <div className="form-container">
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-orange icon-16" onClick={(e) => Save()}></i>
                                        </div>
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-gray icon-38" onClick={(e) => CancelSMS()}></i>
                                        </div>
                                    </div>
                                ) : (
                                   <div className="form-container">
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-24" onClick={(e) =>  setDeptSMSIsEdit(true)}></i> 
                                            </div>
                                        <div className="fz-C flex-1">
                                            <span>編輯</span>
                                        </div>
                                    </div>
                                )}
                                   
                            </div>

                            <div action className="form-container">
                                <div className="form-item">
                                    <h3 className="mr-2 text-red">電子郵件通知設置</h3>
                                </div>

                                <div className="form-item quarter ">

                                    <div className="top-table">登入帳號</div>
                                    <div className="top-value">
                                        {deptMailIsEdit == true ? (
                                            <input type="text" value={deptMailAcnt} onChange={(e) => setDeptMailAcnt(e.target.value)} />
                                        ) : (
                                            <input type="text" value={deptMailAcnt} disabled="true" />
                                        )}
                                    </div>
                                    
                                </div>

                                <div className="form-item quarter">

                                    <div className="top-table">登入密碼</div>
                                    <div className="top-value">
                                        {deptMailIsEdit == true ? (
                                            <input type="text" value={deptMailPwd} onChange={(e) => setDeptMailPwd(e.target.value)} />
                                        ) : (
                                            <input type="text" value={deptMailPwd} disabled="true" />
                                        )}
                                    </div>
                                    
                                </div>

                                <div className="form-item quarter">

                                    <div className="top-table">郵件服務器</div>
                                    <div className="top-value">
                                        {deptMailIsEdit == true ? (
                                            <input type="text" value={deptSmtp} onChange={(e) => setDeptSmtp(e.target.value)} />
                                        ) : (
                                            <input type="text" value={deptSmtp} disabled="true" />
                                        )}
                                    </div>
                                   
                                </div>

                                {deptMailIsEdit == true ? (
                                    <div className="form-container">
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-orange icon-16" onClick={(e) => Save()}></i>
                                        </div>
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-gray icon-38" onClick={(e) => CancelMail()}></i>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="form-container">
                                        <div className="btn btn-icon info">
                                                <i className="icon icon-24" onClick={(e) => setDeptMailIsEdit(true)}></i>
                                        </div>
                                        <div className="fz-C flex-1">
                                            <span>編輯</span>
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div action className="form-container">
                                <div className="form-item">
                                    <h3 className="mr-2 text-red">測試用手機及帳號</h3>
                                </div>

                                <div className="form-item quarter ">

                                    <div className="top-table">手機號碼</div>
                                    <div className="top-value">
                                        {deptTestIsEdit == true ? (
                                            <input type="text" value={deptTestPhone} onChange={(e) => setDeptTestPhone(e.target.value)} />
                                        ) : (
                                                <input type="text" value={deptTestPhone} disabled="true" />
                                        )}
                                    </div>

                                </div>

                                <div className="form-item quarter">

                                    <div className="top-table">電子郵箱</div>
                                    <div className="top-value">
                                        {deptTestIsEdit == true ? (
                                            <input type="text" value={deptTestMail} onChange={(e) => setDeptTestMail(e.target.value)} />
                                        ) : (
                                            <input type="text" value={deptTestMail} disabled="true" />
                                        )}
                                    </div>

                                </div>

                                {deptTestIsEdit == true ? (
                                    <div className="form-container">
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-orange icon-16" onClick={(e) => Save()}></i>
                                        </div>
                                        <div className="btn btn-icon info">
                                            <i className="icon icon-gray icon-38" onClick={(e) => CancelTest()}></i>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="form-container">
                                        <div className="btn btn-icon info">
                                                <i className="icon icon-24" onClick={(e) => setDeptTestIsEdit(true)}></i>
                                        </div>
                                        <div className="fz-C flex-1">
                                            <span>編輯</span>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className="container full">
                            <div className="containerB">
                                <h5 className="mr-2">基本通知模版設置</h5>
                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-3">通知標題</div>
                                        <div className="flex-8">通知內容</div>
                                        <div className="flex-0">&ensp;編輯</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-3">
                                                {x.noticeName}
                                            </div>
                                            <div className="flex-8">
                                                {x.noticeEmail}
                                            </div>
                                            <div className="flex-0 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalEdit(i)} >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-edit" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">編輯基本通知模板</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">

                                <div className="form-item half my-1">
                                    <div className="field fw-b">通知標題</div>
                                    <div className="value">
                                        <input type="text" value={noticeName} onChange={(e) => setNoticeName(e.target.value)}></input>
                                    </div>

                                </div>
                                <div className="form-item col my-1">
                                    <div className="field fw-b">簡訊內容    <span className="text-red ml-1">注意：請注意總字數不得超過70個字（包括標點符號）。</span></div>
                                    <div className="top-value my-1">
                                        <textarea name="" id="" cols="50" rows="5" value={noticeSMS} onChange={(e) => SMSLength(e) }></textarea>
                                        <span style={{ float: 'right' }}>字數統計 {SMSLen}/70</span>
                                    </div>

                                </div>
                                <div className="form-item col my-1">
                                    <div className="field fw-b">郵件內容</div>
                                    <div className="top-value my-1">
                                        <textarea name="" id="" cols="50" rows="10" value={noticeEmail} onChange={(e) => setNoticeEmail(e.target.value)}></textarea>
                                    </div>

                                </div>

                                <div className="form-item">
                                    <span>下列代碼將會從資料庫讀取對應字串並取代：<br />
                                        {"{/名字/}"}&emsp; &emsp; &ensp; 學員姓名<br />
                                        {"{/課程名稱/}"}&emsp; 課程名稱<br />
                                        {"{/繳費期限/}"}&emsp; 繳費期限<br />
                                        {"{/退費日期/}"}&emsp; 核准退費日期<br />
                                        {"{/繳費日期/}"}&emsp; 繳費日期</span>
                                </div>
                                <div className="form-container mt-1">

                                    <div className="form-item field fw-b ml-2" style={{ alignItems: "left" }}> 選擇通知對象 </div>
                                    <div className="form-item">
                                        <div className="top-table">期別</div>
                                        <div className="top-value" style={{ width: '400px' }}>
                                            <select value={DropDownCouPID} onChange={(e) => { selCouPID(e.target.value) }} data-required>
                                                <option value="">請選擇</option>
                                                {coursePeriodArrayList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <div className="top-table">班別序號</div>
                                        <div className="top-value" style={{ width: '400px' }}>
                                            <select value={DropDownCouNo} onChange={(e) => { setDropDownCouNo(e.target.value) }} data-required >
                                                <option value="">請選擇</option>
                                                {/*<option value="00">全選</option>*/}
                                                {CouNoArrayList.map((y) => (
                                                    <option value={y.CouNo}>{y.CouName} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-item btn-area sp">
                                    <div className="btn-holder text-center">
                                        <a className="btn" onClick={UpData} style={{ minWidth: '150px' }} >
                                            <span>存檔</span>
                                        </a>
                                        <a className="btn btn-border" data-close onClick={Cancel} style={{ minWidth: '150px' }}>
                                            <span>取消</span>
                                        </a>
                                        <a className="btn" style={{ minWidth: '150px' }} onClick={TestNotice}>
                                            <span>測試</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
                <div id="modal-successs" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}成功</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InformBasic;
