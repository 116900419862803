import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_POST_AddCourseType } from "../../global/constants";
import { useHistory } from "react-router-dom";

function QuestionResult() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    function getData() { }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([
        {
            id: 1,
            number: "20",
            noA: "4",
            proA: "20%",
            noB: "5",
            proB: "25%",
            noC: "7",
            proC: "35%",
            noD: "3",
            proD: "15%",
            noE: "1",
            proE: "5%",
            ave: "3.4",
            fraction:"5",
        },
        {
            id: 2,
            number: "20",
            noA: "3",
            proA: "15%",
            noB: "6",
            proB: "30%",
            noC: "8",
            proC: "40%",
            noD: "3",
            proD: "15%",
            noE: "0",
            proE: "0%",
            ave: "3.45",
            fraction: "51",
        },
        {
            id: 3,
            number: "20",
            noA: "5",
            proA: "25%",
            noB: "7",
            proB: "35%",
            noC: "7",
            proC: "35%",
            noD: "1",
            proD: "5%",
            noE: "0",
            proE: "0%",
            ave: "3.8",
            fraction: "50",
        },
        {
            id: 4,
            number: "20",
            noA: "4",
            proA: "20%",
            noB: "8",
            proB: "40%",
            noC: "5",
            proC: "25%",
            noD: "2",
            proD: "10%",
            noE: "1",
            proE: "5%",
            ave: "3.6",
            fraction: "30",
        },
        {
            id: 5,
            number: "20",
            noA: "3",
            proA: "15%",
            noB: "4",
            proB: "20%",
            noC: "11",
            proC: "55%",
            noD: "2",
            proD: "10%",
            noE: "0",
            proE: "0%",
            ave: "3.4",
            fraction: "15",
        },
        {
            id: 6,
            number: "20",
            noA: "2",
            proA: "10%",
            noB: "5",
            proB: "25%",
            noC: "6",
            proC: "30%",
            noD: "61",
            proD: "30%",
            noE: "1",
            proE: "5%",
            ave: "3.05",
            fraction: "-35",
        },
    ]);

    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) { //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        //getDashboard();
                        //localStorage.setItem('token', data.token.token);
                        //localStorage.setItem('user', data.result.loginData.userNo);
                        //localStorage.setItem('userName', data.result.loginData.userName);
                        //localStorage.setItem('userID', data.result.loginData.userID);
                        //history.push('?path=changepassword')

                    }
                    else if (data.code == "2013") { //特定的失敗原因


                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }

    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function Add(e) {
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, isEdit: true }];
        });
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-B mr-1 has-border">查看課程問卷結果</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="info-holder">

                               
                                    <div className="form-item twenty">
                                        <div className="top-table">課程名稱</div>
                                        <div className="top-half">
                                        <input type="text" value="國畫(七)水墨山水"/>
                                        </div>
                                    </div>
                                <div className="form-item twenty">
                                    <div className="top-table">教師名稱</div>
                                    <div className="top-half" >
                                        <input type="text" value="袁之靜" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">題號</div>
                                        <div className="flex-2">人數</div>
                                        <div className="flex-1">A</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">B</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">C</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">D</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">E</div>
                                        <div className="flex-2">佔比</div>
                                        <div className="flex-1">平均</div>
                                        <div className="flex-2">淨分數</div>
                                     
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.number} />
                                                ) : (
                                                        x.number
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.noA} />
                                                ) : (
                                                        x.noA
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.proA} />
                                                ) : (
                                                        x.proA
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.noB} />
                                                ) : (
                                                        x.noB
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.proB} />
                                                ) : (
                                                        x.proB
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.noC} />
                                                ) : (
                                                        x.noC
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.proC} />
                                                ) : (
                                                        x.proC
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.noD} />
                                                ) : (
                                                        x.noD
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.proD} />
                                                ) : (
                                                        x.proD
                                                )}
                                            </div>
                                            
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.noE} />
                                                ) : (
                                                        x.noE
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.proE} />
                                                ) : (
                                                        x.proE
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.ave} />
                                                ) : (
                                                    x.ave
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.fraction} />
                                                ) : (
                                                    x.fraction
                                                )}
                                            </div>

                                        </li>
                                    ))}
                                    
                                </ul>


                            </div>
                        </div>


                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default QuestionResult;
