import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_POST_AddCourseType, API_POST_SaleRptpost, API_Get_DropDown, API_POST_SaleRpt, API_GET_DailyRpt } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken, getAuthToken } from "../../global/TokenUtil";

function SignUpReport() {
    var history = useHistory();
    useEffect(() => {
        getData();
    }, []);
    function getData(value = 1) {
        //setToken(getAuthToken());
        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setPageData([])
                    setcoursePeriodArrayList(data.result.coursePeriodArray); //存期別資料
                    //setToken(data.token.token)

                    setDropDownCouPID(value) //傳入各期別的id
                    getDailyRpt(value); //渲染各期資料
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        
    }
    function getDailyRpt(value) {
        API_GET_DailyRpt({
            CouPID: value,
            //CouDeptID: token
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data.result);
                    setTotalData(data.result); //存入各期資料
                    //getPageData(data.result.sqlList); //分頁資訊
                    setData(data?.result?.sqlList);
                    setTotalPage(Math.ceil(data?.result?.sqlList.length / selectNum));
                    setPageData(data?.result?.sqlList.filter((item, index) => index >= (0 + selectNum * selectPage) && index < (selectNum + selectNum * selectPage)));
                }
                else {
                    //alert(data.message);
                    setTotalData('');
                }
            })
            .catch((err) => {
                console.dir(err)
            })
    }


    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉ID
    //const [token, setToken] = useState(""); //TOKEN
    const [totalData, setTotalData] = useState([{}]); //總資料


    const [data, setData] = useState([]); //總資料
    const [totalPage, setTotalPage] = useState(0); //總頁數
    const [pageData, setPageData] = useState([]); //該頁資料
    const [selectPage, setSelectPage] = useState(0); //指定頁數
    const [selectNum, setSelectNum] = useState(4); //一次顯示幾筆資料
    const [pagePeopleNum, setPagePropleNum] = useState(0) //該頁人數

    //useEffect(() => {
    //    getPageData();
    //}, []);

    useEffect(() => {
        changePageData();
        changeTotalPage();
    }, [selectPage, selectNum])

    //const getPageData = () => {
    //    setData(data?.result?.sqlList);
    //    setTotalPage(Math.ceil(data?.result?.sqlList.length / selectNum));
    //};

    const changeTotalPage = () => {
        setTotalPage(Math.ceil(data.length / selectNum));
    }
    const changePageData = () => {
        setPageData(data.filter((item, index) => index >= (0 + selectNum * selectPage) && index < (selectNum + selectNum * selectPage)));
    }
    const changeSelectNum = (e) => {
        setSelectPage(0)
        setSelectNum(parseInt(e.target.value))
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">每日報名人數</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item twenty">
                                    <div className="top-table">期別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => {
                                            const value = e.target.value
                                            getData(value)
                                        }}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option key={ y.id} value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-2">報名日期</div>
                                        <div className="flex-2">報名人數</div>
                                    </li>
                                    {pageData.length > 0 ? pageData.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-2">{x.CreateDate}</div>
                                            <div className="flex-2">{x.Pcount}</div>
                                        </li>
                                    )) : <li className="lt-row text-center">尚無資料</li>}
                                    {totalData.sqlList ?
                                        <li className="lt-row list-footer lt-th">
                                            <div className="flex-2">報名總人數:{totalData.tPeople}</div>
                                            <div className="flex-2">該頁報名人數:{pageData.reduce((acc, cur) => {
                                                return acc + cur.Pcount
                                            }, 0)}</div>
                                        </li>
                                        :
                                        <>
                                        </>
                                    }
                                </ul>

                            </div>
                        </div>
                        {totalData ? <div className="pagination">
                            <span>每頁</span>
                            <select className="page-select" onChange={(e) => {
                                e.preventDefault();
                                changeSelectNum(e)
                            }}>
                                <option value="4">4</option> 
                                <option value="8">8</option>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="50">50</option>
                            </select>
                            <span className="margin-right-3">筆</span>
                            <span className="page-link cursor-pointer" onClick={() => {
                                setSelectPage(selectPage - 1)
                            }} style={{ display: selectPage ? 'block' : 'none' }}>
                                上一頁
                            </span>
                            {[...Array(totalPage)].map((item, index) => {
                                return (
                                    <span
                                        onClick={(e) => {
                                            setSelectPage(index)
                                        }}
                                        key={index + 1}
                                        className={`page-link cursor-pointer ${index == selectPage && 'active'}`}
                                    >
                                        {index + 1}
                                    </span>
                                );
                            })}
                            <span className="page-link cursor-pointer" onClick={() => {
                                setSelectPage(selectPage + 1)
                            }} style={{ display: selectPage + 1 !== totalPage ? 'block' : 'none' }}>
                                下一頁
                            </span>
                        </div> :<></>}
                        
                        

                    </div>
                </div>
            </div>
        </>
    );
}
export default SignUpReport;
