import "../css/style.css";
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";


function unitadmin() {
  const history = useHistory();
  const [username, setUserName] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const path = queryParams.get("path");
  const pageArray = JSON.parse(localStorage.getItem("pagelist"));
  const menuArray = JSON.parse(localStorage.getItem("menulist"));
  //console.log(pageArray);
  const [dataList, setDataList] = useState([
      {
          id: 1, name: "大墩文化中心", acntno: "username1", acntName: "葉大", ip: "192.168.115.221", operate: "登入",
          location:"登陸",describe: "登入系統", operateTime: "2022/12/22 15:30:44", state: 0},
      {
          id: 1, name: "大墩文化中心", acntno: "username2", acntName: "葉中", ip: "192.168.115.221", operate: "新增",
          location: "研習之友折扣管理", describe: "新增折扣類別", operateTime: "2022/12/22 15:30:44", state: 0
      },
      {
          id: 1, name: "大墩文化中心", acntno: "username3", acntName: "葉小", ip: "192.168.115.221", operate: "編輯",
          location: "課程內容管理", describe: "編輯課程AAA001", operateTime: "2022/12/22 15:30:44", state: 0
      }
  ]);

    const [name, setName] = useState();
    const [acntno, setAcntNo] = useState();
    const [acntName, setAcntName] = useState();
    const [ip, setIp] = useState();
    const [operate, setOperate] = useState();
    const [location, setLocation] = useState();
    const [describe, setDescribe] = useState();
    const [operateTime, setOperateTime] = useState();

  useEffect(() => {
    document.body.classList.remove("login");
    document.body.classList.add("dashboard");
    document.getElementById("root").classList.remove("center");
    setUserName(localStorage.getItem("userName"));
    //menu展開收合--------------------
    var hasSubMenu = document.querySelectorAll(".has-dropmenu");
    hasSubMenu.forEach((element) => {
      element.addEventListener("click", function (e) {
        console.log(element);
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        } else {
          element.classList.add("active");
        }
      });
    });
    //menu展開收合----------
    getData();
  }, []);

  function getData() {
    //API_GET_HomeData({
    //}).then((response) => response.data)
    //    .then((data) => {
    //        //console.log(data);
    //        //setNotifyArray(data.result.notify);
    //    })
    //    .catch(err => {
    //        console.log(err);
    //        if (err.response.status === 401 || err.response.data.code == "1001") {
    //            localStorage.clear();
    //            history.push('/');
    //        }
    //    })
  }

  //const menuArrayLen = menuArray.filter(item => item.FuncListType === "-1").length;
  //const action = localStorage.getItem('action');

  function logout() {
    localStorage.clear();
    history.push("/");
  }
  var currentModal;
  let currentLevel = 1;
  const modals = document.querySelectorAll("[data-modal]");
  const modalBtns = document.querySelectorAll("[data-openmodal]");
  setInterval(tick, 1000);
  const [nowTime, setNowTime] = useState("");
  function tick() {
    setNowTime(
      new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
    );
  }

  modals.forEach((element) => {
    var modalBody = element.querySelector(".modal-container");
    var modalClose = element.querySelectorAll("[data-close]");
    var modalsBack = element.querySelector("[data-back]");
    //console.log(modalClose);
    element.addEventListener("click", function (e) {
      if (e.target == modalsBack) {
        var showLayerClass = "has-layer-" + currentLevel;
        currentLevel = currentLevel - 1;
        element.classList.remove("active");
        document.body.classList.remove(showLayerClass);
      } else if (e.target == element || e.target.hasAttribute("data-close")) {
        /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

        var showLayerClass = "has-layer-" + currentLevel;
        if (typeof currentModal !== "undefined") {
          currentModal.classList.remove("active");
        } else {
        }
        currentLevel = currentLevel - 1;
        element.classList.remove(
          "active",
          "layer-1",
          "layer-2",
          "layer-3",
          "layer-4",
          "layer-5",
          "layer-6"
        );
        document.body.classList.remove(showLayerClass);
      }
    });
  });

  function openModalSuccess(dt) {
      console.log(dt);
      setName(dt.name);
      setAcntNo(dt.acntno);
      setAcntName(dt.acntName);
      setIp(dt.ip);
      setOperate(dt.operate);
      setLocation(dt.location)
      setDescribe(dt.describe)
      setOperateTime(dt.operateTime)

    var id = "modal-success";
    var target = document.querySelector("#" + id);
    var layerClass = "layer-1";

    target.classList.add("active");
    target.classList.add(layerClass);
    //e.preventDefault();
  }

  //function upData(id) {
  //    var index = e.target.dataset.index;
  //    setProductData(function (prev) {
  //        return prev.map(x,i => {
  //            if (i == index) {
  //                item.amortized = e.target.value;
  //            }
  //            return item;
  //        });
  //    });

  //}
  function delData(id) {
    setDataList(function (prev) {
      return prev.filter((x, i) => x.id != id);
    });
  }
  return (
    <>
      <header className="header">
        <a href="/Dashboard" className="logo">
          <i className="hlogo"></i>
        </a>
        
        <div className="fn-bar">
          <div className="left">
            <div className="logotxt ">報名繳費系統管理平台</div>
          </div>
          <div className="right">
            {/*<a href="" className="notice" onClick={ openModalReview}>*/}
            {/*    <i className="icon icon-42"></i>*/}
            {/*</a>*/}
            <a href="">
              <i className="icon icon-40"></i>
              <span>{username}</span>
            </a>
            <a onClick={logout} className="logout">
              <i className="icon icon-38"></i>
              <span>登出</span>
            </a>
          </div>
        </div>
      </header>

      <div className="main">
        <div className="page-content">
          <div className="content-body">
            <div className="title-area">
              <div className="containerB mid">
                <h3 className="fz-A mr-1">系統操作紀錄查詢</h3>
              </div>
            </div>

            <div className="container mid">
              <div className="modal-body">
                <form action="">
                  <div className="form-container inline">
                    <div className="form-item">                                       
                      <select>
                        <option>大墩文化中心</option>
                      </select>
                    </div>
                    <div className="form-item">
                      <div className="field">帳號</div>
                      <div className="value">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="form-item">
                        <div className="field">IP</div>
                        <div className="value">
                            <input type="text" />
                        </div>
                    </div>
                    <div className="date-item-l">
                                          <div className="field big">日期區間</div>
                      <div className="value read">
                        <input type="date"/>
                      </div>
                    </div>
                     
                    <div className="date-item-r">     
                        <div className="field big">至</div>
                        <div className="value read">
                            <input type="date"/>
                        </div>
                    </div>     
                    <div className="form-item btn-area">
                      <button className="btn btn-small" data-openmodal="modal-1">
                        <span>查詢</span>
                      </button>
                    </div>
                  </div>
                  <ul className="list-table">
                    <li className="lt-row lt-th">
                      <div className="flex-2">單位</div>
                      <div className="flex-1">帳號</div>
                      <div className="flex-1">使用者名稱</div>
                      <div className="flex-2">IP</div>
                      <div className="flex-1">操作</div>
                      <div className="flex-1">位置</div>
                      <div className="flex-4">描述</div>
                      <div className="flex-2">操作時間</div>
                    </li>
                    {dataList.map((x) => (
                      <li className="lt-row">
                        <div className="flex-2">{x.name}</div>
                        <div className="flex-1">{x.acntno}</div>
                        <div className="flex-1">{x.acntName}</div>
                        <div className="flex-2">{x.ip}</div>
                        <div className="flex-1">{x.operate}</div>
                        <div className="flex-1">{x.location}</div>
                        <div className="flex-4">{x.describe}</div>
                        <div className="flex-2">{x.operateTime}</div>             
                      </li>
                    ))}
                  </ul>
                </form>
                <div className="form-item btn-area">
                    <a href='#'>                    
                        <i className="icon icon-29"></i>下載報表
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="modal-success"
          className="modal large"
          data-modal
          data-mid="1.1.1.1"
        >
            <div className="modal-container ">
            <div className="title-area">
              <div className="container">
                <h3 className="fz-B has-border">權限設定</h3>
              </div>
            </div>

            <div className="container">
              <form action="">
                <div className="form-container">
                  <div className="block-area">
                    <div className="row field">
                      <div className="left">
                        <span>姓名：{name}</span>
                        <span>帳號：{acntno}</span>
                      </div>

                     
                    </div>

                    <div className="form-item col">
                      <div className="field jcsb">
                        <span> 功能權限</span>
                        
                      </div>
                      <div className="value">
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox1"
                            name="checkbox"
                            value="checkbox1"
                          />
                          <label for="checkbox1">銷貨訂單</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox2"
                            name="checkbox"
                            value="checkbox2"
                          />
                          <label for="checkbox2">帳務沖銷</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox3"
                            name="checkbox"
                            value="checkbox3"
                          />
                          <label for="checkbox3">帳務沖銷 (登錄)</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox4"
                            name="checkbox"
                            value="checkbox4"
                          />
                          <label for="checkbox4">帳務沖銷 (審核)</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox5"
                            name="checkbox"
                            value="checkbox5"
                          />
                          <label for="checkbox5">客戶維護</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox6"
                            name="checkbox"
                            value="checkbox6"
                          />
                          <label for="checkbox6">客戶維護 (檢核)</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox7"
                            name="checkbox"
                            value="checkbox7"
                          />
                          <label for="checkbox7">雲端帳號維護</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox8"
                            name="checkbox"
                            value="checkbox8"
                          />
                          <label for="checkbox8">雲端帳號維護 (檢核)</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox8"
                            name="checkbox"
                            value="checkbox8"
                          />
                          <label for="checkbox8">雲端帳務</label>
                        </div>
                      </div>
                      <span className="help-word">請至少選擇一個</span>
                    </div>
                    <div className="form-item col">
                      <div className="field">報表權限</div>
                      <div className="value">
                        <div>
                          <input
                            type="checkbox"
                            id="checkboxB1"
                            name="checkboxB"
                            value="checkboxB1"
                          />
                          <label for="checkboxB1">業績報表</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkboxB2"
                            name="checkboxB"
                            value="checkboxB2"
                          />
                          <label for="checkboxB2">營收月報</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="checkboxB3"
                            name="checkboxB"
                            value="checkboxB3"
                          />
                          <label for="checkboxB3">應收帳款報表</label>
                        </div>
                        <br />
                        <div>
                          <input  type="checkbox"id="checkboxB4"name="checkboxB"value="checkboxB4" />
                          <label for="checkboxB4">客戶訪談紀錄</label>
                        </div>
                        <div>
                          <input  type="checkbox"id="checkboxB5"name="checkboxB"value="checkboxB5"/>
                          <label for="checkboxB5">客戶訪談紀錄 (查詢)</label>
                        </div>
                      </div>
                      <span className="help-word">請至少選擇一個</span>
                    </div>
                  </div>

                  <div className="form-item btn-area sp">
                    <div className="left"></div>
                    <div className="right">
                      <a className="btn btn-border" href="">
                        <span>取消</span>
                      </a>
                      <button className="btn" data-openmodal="modal-1">
                        <span>確定</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default unitadmin;
