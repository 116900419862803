import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Login from './pages/Login';
import ChangePassword from './pages/changePassword'; //變更密碼
import Dashboard from './pages/Dashboard';
import Main from './pages/Main';
import acntadmin from './pages/AcntAdmin';
import unitadmin from './pages/UnitAdmin';
import log from './pages/Log';



const root = (
    //test
    <I18n>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/Dashboard" component={Dashboard} />
                <Route path="/Main" component={Main} />
                <Route path="/AcntAdmin" component={acntadmin} />
                <Route path="/UnitAdmin" component={unitadmin} />
                <Route path="/Log" component={log} />
                <Route path="/ChangePassword" component={ChangePassword} />
            </Switch>
        </BrowserRouter >
    </I18n>
);

ReactDOM.render(root, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
