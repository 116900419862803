import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Login } from "../../global/constants";
import { useHistory } from "react-router-dom";

function CourseBase() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    function getData() { }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([
        { id: 1, name: "111年春季課程", period: "111年春季班統一報名", quota: "5", discount: "85%", deadline:"2022/01/20", isEdit: false },
        { id: 2, name: "111年秋季課程", period: "111年秋季班統一報名", quota: "5", discount: "85%" , deadline:"2022/08/20", isEdit: false },
    ]);
    function Edit(type, index) {
        console.log(index);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == index) {
                    item.isEdit = type == 1 ? true : false;
                }
                return item;
            });
        });
    }
    function delData() {
        //呼叫API
        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function Add(e) {
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, period: null, quota: null, discount: null, deadline: null,  isEdit: true }];
        });
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">課程內容管理</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-2">班別序號</div>
                                        <div className="flex-4">課程名稱</div>
                                        <div className="flex-3">課程期別</div>
                                        <div className="flex-2">類別</div>
                                        <div className="flex-2">班別</div>
                                        <div className="flex-2">教師</div>
                                        <div className="flex-2">開課日期</div>
                                        <div className="flex-2">名額</div>
                                        <div className="flex-1">複製全期</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                        <div className="flex-1">&ensp;刪除</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.couno} />
                                                ) : (
                                                        x.couno
                                                )}
                                            </div>
                                            <div className="flex-4">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.couname} />
                                                ) : (
                                                        x.couname
                                                )}
                                            </div>
                                            <div className="flex-3">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.coupd} />
                                                ) : (
                                                        x.coupd
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.coutype} />
                                                ) : (
                                                        x.coutype
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.classtype} />
                                                ) : (
                                                    x.classtype
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.teachername} />
                                                ) : (
                                                    x.teachername
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.sdate} />
                                                ) : (
                                                    x.sdate
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.quota} />
                                                ) : (
                                                    x.quota
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-22"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    {x.isEdit == false ? (
                                                        <div className="btn btn-icon" onClick={(e) => Edit(1, i)} >
                                                            <i className="icon icon-24"></i>
                                                        </div>
                                                     ) : x.id == null ? (
                                                        <div className="btn btn-small btn-border" onClick={(e) => Edit(2, i)} >
                                                            <span>確定</span>
                                                        </div>
                                                     ) : (
                                                        <div className="btn btn-small btn-border" onClick={(e) => Edit(2, i)}>
                                                            <span>取消</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                    ))}
                                    <li className="lt-row">
                                        <div className="flex-2">
                                            <a className="btn  btn-icon" onClick={Add}>
                                                <i className="icon icon-14"></i>
                                               
                                            </a>
                                            <span>新增</span>
                                        </div>
                                        <div className="flex-6"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-4"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-4"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CourseBase;
