import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_POST_ViewSchdget,  //取得該課程報名時間詳情
    API_POST_CourseSchdData, //新增報名時間
    API_Put_CourseSchdData //刪除報名時間 && 編輯報名時間詳情
} from "../../global/constants";
import { useHistory } from "react-router-dom";


const SignupDetail = ({ editcouSID, setReGet }) => {
    var history = useHistory();
   
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");

    const [tmpIndex, setTmpIndex] = useState();

    const [couSID, setCouSID] = useState("");
    const [couSName, setCouSName] = useState(""); // 課程名稱
    const [couSDistType, setCouSDistType] = useState("");//錄取模式
    const [couSLimitCnt, setCouSLimitCnt] = useState(0); // 報名場次限制(CouSDistType=A才有值)
    const [couSPublicDate, setCouSPublicDate] = useState(""); //公告開始日期時間
    const [couSCloseDate, setCouSCloseDate] = useState("");
    //電腦隨機抽選 CouSTSNo = A
    const [couSTRegStartDateA, setCouSTRegStartDateA] = useState(""); //報名開始日期時間
    const [couSTRegEndDateA, setCouSTRegEndDateA] = useState(""); //報名結束日期時間
    const [couSTPayEndDateA, setCouSTPayEndDateA] = useState(""); //繳費期限日期時間
    const [couSTPayNoticeA, setCouSTPayNoticeA] = useState("00"); //催繳通知方式
    const [couSTLoseNoticeDateA, setCouSTLoseNoticeDateA] = useState("");//資格取消及備取通知日期時間
    const [couSTLoseNoticeA, setCouSTLoseNoticeA] = useState("00"); //資格取消及備取通知方式
    const [couSTDrawDateA, setCouSTDrawDateA] = useState(""); //抽籤日期
    const [couSTOpenDateA, setCouSTOpenDateA] = useState(""); //公告時間
    const [couSTOpenNoticeA, setCouSTOpenNoticeA] = useState("00"); //公告通知方式

    //備取 CouSTSNo = P
    const [couSTPayTypeP, setCouSTPayTypeP] = useState(0); //備取繳費期限(A=日期，B=天數)
    const [couSTPayTypePA, setCouSTPayTypePA] = useState(false); //備取繳費期限(A=日期，B=天數)
    const [couSTPayTypePB, setCouSTPayTypePB] = useState(false); //備取繳費期限(A=日期，B=天數)
    const [couSTPayDaysP, setCouSTPayDaysP] = useState(0); //備取可繳費天數
    const [couSTPayEndDateP, setCouSTPayEndDateP] = useState(""); //備取繳費期限日期時間
    const [couSTPayNoticeP, setCouSTPayNoticeP] = useState("00"); //備取繳費通知方式
    const [couSTLoseNoticeDateP, setCouSTLoseNoticeDateP] = useState(""); //錄取通知及資格取消日期時間
    const [couSTLoseNoticeP, setCouSTLoseNoticeP] = useState("00"); //錄取通知及資格取消通知方式

    //先報先取 CouSTSNo = B
    const [couSTRegStartDateB, setCouSTRegStartDateB] = useState(""); //報名開始日期時間
    const [couSTRegEndDateB, setCouSTRegEndDateB] = useState(""); //報名結束日期時間
    const [couSTPayTypeB, setCouSTPayTypeB] = useState(0); //繳費期限(A=日期，B=天數)
    const [couSTPayDaysB, setCouSTPayDaysB] = useState(0); //可繳費天數
    const [couSTPayEndDateB, setCouSTPayEndDateB] = useState(""); //繳費期限日期時間
    const [couSTPayNoticeB, setCouSTPayNoticeB] = useState("00"); //催繳通知方式
    const [couSTLoseNoticeDateB, setCouSTLoseNoticeDateB] = useState("");//錄取通知及資格取消日期時間
    const [couSTLoseNoticeB, setCouSTLoseNoticeB] = useState("00"); //錄取通知及資格取消日期時間通知方式

    //開課時現場報名 CouSTSNo = C
    const [couSTOpenDateC, setCouSTOpenDateC] = useState(""); //公告時間

    //驗證
    const [couSLimitCntError, setCouSLimitCntError] = useState(false); //報名場次限制
    const [couSPublicDateError, setCouSPublicDateError] = useState(false); //公告時間
    const [couSCloseDateError, setCouSCloseDateError] = useState(false); //公告時間
    const [couSDistTypeError, setCouSDistTypeError] = useState(false); //錄取模式
    const [couSTRegStartDateAError, setCouSTRegStartDateAError] = useState(false); //報名開始日期
    const [couSTRegEndDateAeError, setCouSTRegEndDateAError] = useState(false); //報名結束日期
    const [couSTDrawDateAError, setCouSTDrawDateAError] = useState(false); //隨機抽選日期
    const [couSTOpenDateAError, setCouSTOpenDateAError] = useState(false); //名單公布日期
    const [couSTPayEndDateAError, setCouSTPayEndDateAError] = useState(false); //繳費期限日期
    const [couSTLoseNoticeDateAError, setCouSTLoseNoticeDateAError] = useState(false); //資格取消及備取通知日期 (電腦隨機抽)
    const [couSTRegStartDateBError, setCouSTRegStartDateBError] = useState(false); //報名開始日期時間 (先報先取)
    const [couSTRegEndDateBError, setCouSTRegEndDateBError] = useState(false); //報名結束日期 (先報先取)
    const [couSTPayEndDateBError, setCouSTPayEndDateBError] = useState(false); //繳費期限日期 (先報先取)
    const [couSTLoseNoticeDateBError, setCouSTLoseNoticeDateBError] = useState(false); //錄取通知及資格取消日期 (先報先取)

    const [couSTPayEndDatePError, setCouSTPayEndDatePError] = useState(false); //備取繳費期限日期時間
    const [couSTLoseNoticeDatePError, setCouSTLoseNoticeDatePError] = useState(false); //錄取通知及資格取消日期時間 (先報先取)

    const [couSTOpenDateCError, setCouSTOpenDateCError] = useState(false); //名額公告日期

    useEffect(() => {
        getData();
    }, [editcouSID]);

    function getData() {
        if (editcouSID == "0") {
            console.log('新增');

            setCouSID(""); //課程ID
            setCouSName(""); // 課程名稱
            setCouSDistType("");//錄取模式
            setCouSLimitCnt("0"); //報名場次限制(CouSDistType=A才有值)
            setCouSPublicDate("");//公告開始日期時間
            setCouSCloseDate("");
            //電腦隨機抽選 CouSTSNo = A
            setCouSTRegStartDateA(""); //報名開始日期時間
            setCouSTRegEndDateA(""); //報名結束日期時間
            setCouSTPayEndDateA(""); //繳費期限日期時間
            setCouSTPayNoticeA("00"); //催繳通知方式
            setCouSTLoseNoticeDateA("");//資格取消及備取通知日期時間
            setCouSTLoseNoticeA("00"); //資格取消及備取通知方式
            setCouSTDrawDateA(""); //抽籤日期
            setCouSTOpenDateA(""); //公告時間
            setCouSTOpenNoticeA("00"); //公告通知方式

            //備取 CouSTSNo = P
            setCouSTPayTypeP("") //備取繳費期限(A=日期，B=天數)
            setCouSTPayDaysP(null) //備取可繳費天數
            setCouSTPayEndDateP(null); //備取繳費期限日期時間
            setCouSTPayNoticeP("00"); //備取繳費通知方式
            setCouSTLoseNoticeDateP(""); //錄取通知及資格取消日期時間
            setCouSTLoseNoticeP("00"); //錄取通知及資格取消通知方式

            //開課時現場報名 CouSTSNo = C
            setCouSTOpenDateC(""); //公告時間

            //先報先取 CouSTSNo = B

            setCouSTRegStartDateB(""); //報名開始日期時間
            setCouSTRegEndDateB(""); //報名結束日期時間
            setCouSTPayTypeB(""); //繳費期限(A=日期，B=天數)
            setCouSTPayDaysB(null); //可繳費天數
            setCouSTPayEndDateB(null); //繳費期限日期時間
            setCouSTPayNoticeB("00"); //催繳通知方式
            setCouSTLoseNoticeDateB("");//錄取通知及資格取消日期時間
            setCouSTLoseNoticeB("00"); //錄取通知及資格取消日期時間通知方式
        } else {
            if (editcouSID == "") {
                return null;
            } 
            console.log('編輯');
            //取得該編輯詳細資料
            API_POST_ViewSchdget({
                "CouSID": editcouSID,
                "SchdTList": []
            }).then((response) => response.data)
                .then((data) => {
                    //setDataList(data.result);
                    //console.log(data.result);

                    setCouSID(data.result[0].CouSID); //課程ID
                    setCouSName(data.result[0].CouSName); // 課程名稱
                    setCouSDistType(data.result[0].CouSDistType);//錄取模式
                    setCouSLimitCnt(data.result[0].CouSLimitCnt); //報名場次限制(CouSDistType=A才有值)
                    setCouSPublicDate(data.result[0].CouSPublicDate);//公告開始日期時間
                    setCouSCloseDate(data.result[0].CouSCloseDate);
                    return data.result.map((item, i) => {
                        /*console.log(item);*/

                        if (item.CouSTSNo == 'A') {
                            //電腦隨機抽選 CouSTSNo = A
                            setCouSTRegStartDateA(item.CouSTRegStartDate); //報名開始日期時間
                            setCouSTRegEndDateA(item.CouSTRegEndDate); //報名結束日期時間
                            setCouSTPayEndDateA(item.CouSTPayEndDate); //繳費期限日期時間
                            setCouSTPayNoticeA(item.CouSTPayNotice); //催繳通知方式
                            setCouSTLoseNoticeDateA(item.CouSTLoseNoticeDate);//資格取消及備取通知日期時間
                            setCouSTLoseNoticeA(item.CouSTLoseNotice); //資格取消及備取通知方式
                            setCouSTDrawDateA(item.CouSTDrawDate); //抽籤日期
                            setCouSTOpenDateA(item.CouSTOpenDate); //公告時間
                            setCouSTOpenNoticeA(item.CouSTOpenNotice); //公告通知方式
                        } else if (item.CouSTSNo == 'P') {
                            //備取 CouSTSNo = P
                            if (item.CouSTPayEndDate != null) {
                                setCouSTPayTypeP("A"); //備取繳費期限(A=日期，B=天數)
                            } 
                            if (item.CouSTPayDays != 0 && item.CouSTPayDays != null && item.CouSTPayDays != "") {
                                setCouSTPayTypeP("B");//備取繳費期限(A=日期，B=天數)
                            } 
                            setCouSTPayDaysP(item.CouSTPayDays) //備取可繳費天數
                            setCouSTPayEndDateP(item.CouSTPayEndDate); //備取繳費期限日期時間
                            setCouSTPayNoticeP(item.CouSTPayNotice); //備取繳費通知方式
                            setCouSTLoseNoticeDateP(item.CouSTLoseNoticeDate); //錄取通知及資格取消日期時間
                            setCouSTLoseNoticeP(item.CouSTLoseNotice); //錄取通知及資格取消通知方式
                        } else if (item.CouSTSNo == 'C') {
                            //開課時現場報名 CouSTSNo = C
                            setCouSTOpenDateC(item.CouSTOpenDate); //公告時間
                        } else {
                            //先報先取 CouSTSNo = B
                            console.log(item);
                            if (item.CouSTPayEndDate != null) {
                                setCouSTPayTypeB("A"); //備取繳費期限(A=日期，B=天數)
                            }
                            if (item.CouSTPayDays != 0 && item.CouSTPayDays != null && item.CouSTPayDays != "") {
                                setCouSTPayTypeB("B"); //備取繳費期限(A=日期，B=天數)
                            } 

                            setCouSTRegStartDateB(item.CouSTRegStartDate); //報名開始日期時間
                            setCouSTRegEndDateB(item.CouSTRegEndDate); //報名結束日期時間
                            setCouSTPayDaysB(item.CouSTPayDays) //可繳費天數
                            setCouSTPayEndDateB(item.CouSTPayEndDate); //繳費期限日期時間
                            setCouSTPayNoticeB(item.CouSTPayNotice); //催繳通知方式
                            setCouSTLoseNoticeDateB(item.CouSTLoseNoticeDate);//錄取通知及資格取消日期時間
                            setCouSTLoseNoticeB(item.CouSTLoseNotice); //錄取通知及資格取消日期時間通知方式
                        }
                        return item;
                    });
                })
                .catch(err => {

                    console.log(err);

                })
        }
      
    }

    //新增&修改彈窗 >存儲btn
    function Edit() {

        //驗証必填欄位
        var result = formRequired();

        //驗証日期時間
        var resultDate = chkdate();

        //驗証{錄取模式}是否填寫
        if (couSDistType == '') {
            setCouSDistTypeError(true);

        } else {
            setCouSDistTypeError(false);
        }

        if (result && setCouSDistTypeError && resultDate) {


            //電腦隨機抽選
            if (couSDistType == 'A') {
               
                var SchdTList = [
                    {
                        "CouSTSNo": "A",
                        "CouSTStage": "1",
                        "CouSTRegStartDate": couSTRegStartDateA,
                        "CouSTRegEndDate": couSTRegEndDateA,
                        "CouSTPayEndDate": couSTPayEndDateA,
                        "CouSTPayNotice": couSTPayNoticeA,
                        "CouSTLoseNoticeDate": couSTLoseNoticeDateA,
                        "CouSTLoseNotice": couSTLoseNoticeA,
                        "CouSTDrawDate": couSTDrawDateA,
                        "CouSTOpenDate": couSTOpenDateA,
                        "CouSTOpenNotice": couSTOpenNoticeA
                    },
                    {
                        "CouSTSNo": "B",
                        "CouSTStage": "2",
                        "CouSTRegStartDate": couSTRegStartDateB,
                        "CouSTRegEndDate": couSTRegEndDateB,
                        "CouSTPayDays": couSTPayDaysB,
                        "CouSTPayEndDate": couSTPayEndDateB,
                        "CouSTPayNotice": couSTPayNoticeB,
                        "CouSTLoseNoticeDate": couSTLoseNoticeDateB,
                        "CouSTLoseNotice": couSTLoseNoticeB
                    },
                    {
                        "CouSTSNo": "C",
                        "CouSTStage": "3",
                        "CouSTOpenDate": couSTOpenDateC
                    }];
            } else {
                //報名順序

                var SchdTList = [
                    {
                        "CouSTSNo": "B",
                        "CouSTStage": "1",
                        "CouSTRegStartDate": couSTRegStartDateB,
                        "CouSTRegEndDate": couSTRegEndDateB,
                        "CouSTPayDays": couSTPayDaysB,
                        "CouSTPayEndDate": couSTPayEndDateB,
                        "CouSTPayNotice": couSTPayNoticeB,
                        "CouSTLoseNoticeDate": couSTLoseNoticeDateB,
                        "CouSTLoseNotice": couSTLoseNoticeB
                    },
                    {
                        "CouSTSNo": "P",
                        "CouSTStage": "2",
                        "CouSTPayDays": couSTPayDaysP,
                        "CouSTPayEndDate": couSTPayEndDateP,
                        "CouSTPayNotice": couSTPayNoticeP,
                        "CouSTLoseNoticeDate": couSTLoseNoticeDateP,
                        "CouSTLoseNotice": couSTLoseNoticeP
                    },
                    {
                        "CouSTSNo": "C",
                        "CouSTStage": "3",
                        "CouSTOpenDate": couSTOpenDateC
                    }];
            }
            return SchdTList;
        } else {
            return '';
        }
    }

    function formRequired() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });
        return result;
    }
    function chkdate() {

        var resultDate = true;
        //***電腦隨機抽選***
        if (couSDistType == 'A') {
            //驗証{報名場次限制}是否大於{ 0 }
            if (couSLimitCnt <= 0) {
                resultDate = false;
                setCouSLimitCntError(true);
            } else {

                setCouSLimitCntError(false);
            }
            
            let todaysDate = new Date()
            //驗証{公告開始日期}是否大於等於{今天(系統日)}
            if (new Date(couSPublicDate) < todaysDate) {
                resultDate = false;
                setCouSPublicDateError(true);
            } else {

                setCouSPublicDateError(false);
            }

            //驗証{報名開始日期}是否大於等於{公告開始日期}
            if (couSTRegStartDateA < couSPublicDate) {
                resultDate = false;
                setCouSTRegStartDateAError(true);
            } else {

                setCouSTRegStartDateAError(false);
            }

            //驗証{報名結束日期}是否大於等於{報名開始日期}
            if (couSTRegEndDateA < couSTRegStartDateA) {
                resultDate = false;
                setCouSTRegEndDateAError(true);
            } else {

                setCouSTRegEndDateAError(false);
            }

            //驗証{隨機抽選日期}是否大於等於{報名結束日期}
            if (couSTDrawDateA < couSTRegEndDateA) {
                resultDate = false;
                setCouSTDrawDateAError(true);
            } else {

                setCouSTDrawDateAError(false);
            }

            //驗証{名單公布日期}是否大於等於{隨機抽選日期}
            if (couSTOpenDateA < couSTDrawDateA) {
                resultDate = false;
                setCouSTOpenDateAError(true);
            } else {

                setCouSTOpenDateAError(false);
            }

            //驗証{繳費期限日期}是否大於等於{名單公布日期}
            if (couSTPayEndDateA < couSTOpenDateA) {
                resultDate = false;
                setCouSTPayEndDateAError(true);
            } else {

                setCouSTPayEndDateAError(false);
            }

            //驗証{資格取消及備取通知日期}是否大於等於{繳費期限日期}
            if (couSTLoseNoticeDateA < couSTPayEndDateA) {
                resultDate = false;
                setCouSTLoseNoticeDateAError(true);
            } else {

                setCouSTLoseNoticeDateAError(false);
            }

            //驗証{報名開始日期時間}是否大於等於{資格取消及備取通知日期}
            if (couSTRegStartDateB < couSTLoseNoticeDateA) {
                resultDate = false;
                setCouSTRegStartDateBError(true);
            } else {

                setCouSTRegStartDateBError(false);
            }

            //驗証{報名結束日期}是否大於等於{報名開始日期時間}
            if (couSTRegEndDateB < couSTRegStartDateB) {
                resultDate = false;
                setCouSTRegEndDateBError(true);
            } else {

                setCouSTRegEndDateBError(false);
            }


            if (couSTPayTypeB == "A") {  /*應該從這邊下判斷*/

                //驗証{繳費期限日期}是否大於等於{報名結束日期}
                if (couSTPayEndDateB == null || couSTPayEndDateB < couSTRegEndDateB) {
                    resultDate = false;
                    setCouSTPayEndDateBError(true);
                } else {

                    setCouSTPayEndDateBError(false);
                }

                //驗証{錄取通知及資格取消日期}是否大於等於{繳費期限日期}
                if (couSTLoseNoticeDateB < couSTPayEndDateB) {
                    resultDate = false;
                    setCouSTLoseNoticeDateBError(true);
                } else {

                    setCouSTLoseNoticeDateBError(false);
                }

                //驗証{名額公告日期}是否大於等於{錄取通知及資格取消日期}
                if (couSTOpenDateC < couSTLoseNoticeDateB) {
                    resultDate = false;
                    setCouSTOpenDateCError(true);
                } else {

                    setCouSTOpenDateCError(false);
                }

            } else {

                //驗證 錄取通知及資格取消日期時間 是否大於等於 報名結束日期
                if (couSTLoseNoticeDateB < couSTRegEndDateB) {
                    resultDate = false;
                    setCouSTLoseNoticeDateBError(true);
                } else {

                    setCouSTLoseNoticeDateBError(false);
                }

                //驗証{名額公告日期}是否大於等於{報名結束日期}
                if (couSTOpenDateC < couSTRegEndDateB) {
                    resultDate = false;
                    setCouSTOpenDateCError(true);
                } else {

                    setCouSTOpenDateCError(false);
                }

             
            }
            if (couSCloseDate < couSTOpenDateC) {
                resultDate = false;
                setCouSCloseDateError(true);
            }
            else {
                setCouSCloseDateError(false);
            }


        } else {  
            //***依報名順序***
            let todaysDate = new Date();
            //驗証{公告開始日期}是否大於等於{今天(系統日)}
            if (new Date(couSPublicDate) < todaysDate) {
                resultDate = false;
                setCouSPublicDateError(true);
            } else {

                setCouSPublicDateError(false);
            }

            //驗証{報名開始日期}是否大於等於{公告開始日期}
            if (couSTRegStartDateB < couSPublicDate) {
                resultDate = false;
                setCouSTRegStartDateBError(true);
            } else {

                setCouSTRegStartDateBError(false);
            }

            //驗証{報名結束日期}是否大於等於{報名開始日期}
            if (couSTRegEndDateB < couSTRegStartDateB) {
                resultDate = false;
                setCouSTRegEndDateBError(true);
            } else {

                setCouSTRegEndDateBError(false);
            }

            //驗證 資格取消及備取通知日期時間 是否大於等於 報名結束日期
            if (couSTRegEndDateB < couSTRegStartDateB) {
                resultDate = false;
                setCouSTRegEndDateBError(true);
            } else {

                setCouSTRegEndDateBError(false);
            }

            //驗證 錄取通知及資格取消時間 是否大於等於 報名結束日期
            if (couSTRegEndDateB < couSTRegStartDateB) {
                resultDate = false;
                setCouSTRegEndDateBError(true);
            } else {

                setCouSTRegEndDateBError(false);
            }

            if (couSTPayTypeB == "A") {
                //驗証{繳費期限日期}是否大於等於{報名結束日期}
                if (couSTPayEndDateB == null || couSTPayEndDateB < couSTRegEndDateB) {
                    resultDate = false;
                    setCouSTPayEndDateBError(true);
                } else {

                    setCouSTPayEndDateBError(false);
                }
                //驗証{資格取消及備取通知日期}是否大於等於{繳費期限日期}
                if (couSTLoseNoticeDateB == null || couSTLoseNoticeDateB < couSTPayEndDateB) {
                    resultDate = false;
                    setCouSTLoseNoticeDateBError(true);
                } else {
                    setCouSTLoseNoticeDateBError(false);
                }
                if (couSTPayTypeP == "A") {
                    //驗証{ 備取繳費期限日期 }是否大於等於{ 資格取消及備取通知日期 }
                    if (couSTPayEndDateP == null || couSTPayEndDateP < couSTLoseNoticeDateB) {
                        resultDate = false;
                        setCouSTPayEndDatePError(true);
                    } else {

                        setCouSTPayEndDatePError(false);
                    }

                    //驗証{錄取通知及資格取消日期}是否大於等於{備取繳費期限日期}
                    if (couSTLoseNoticeDateP < couSTPayEndDateP) {
                        resultDate = false;
                        setCouSTLoseNoticeDatePError(true);
                    } else {

                        setCouSTLoseNoticeDatePError(false);
                    }

                    //驗証{第二階段名額公告日期}是否大於等於{錄取通知及資格取消日期}
                    if (couSTOpenDateC < couSTLoseNoticeDateP) {
                        resultDate = false;
                        setCouSTOpenDateCError(true);
                    } else {

                        setCouSTOpenDateCError(false);
                    }
                } else {
                    //驗証{第二階段名額公告日期}是否大於等於{資格取消及備取通知日期時間}
                    if (couSTOpenDateC < couSTLoseNoticeDateB) {
                        resultDate = false;
                        setCouSTOpenDateCError(true);
                    } else {

                        setCouSTOpenDateCError(false);
                    }
                }

            } else {
                if (couSTPayTypeP == "A") {
                    //驗証{備取繳費期限日期}是否大於等於{報名結束日期時間}
                    if (couSTPayEndDateP == null || couSTPayEndDateP < couSTRegEndDateB) {
                        resultDate = false;
                        setCouSTPayEndDatePError(true);
                    } else {

                        setCouSTPayEndDatePError(false);
                    }

                    //驗証{錄取通知及資格取消日期}是否大於等於{備取繳費期限日期}
                    if (couSTLoseNoticeDateP == null || couSTLoseNoticeDateP < couSTPayEndDateP) {
                        resultDate = false;
                        setCouSTLoseNoticeDatePError(true);
                    } else {

                        setCouSTLoseNoticeDatePError(false);
                    }

                    //驗証{第二階段名額公告日期}是否大於等於{錄取通知及資格取消日期}
                    if (couSTOpenDateC == null || couSTOpenDateC < couSTLoseNoticeDateP) {
                        resultDate = false;
                        setCouSTOpenDateCError(true);
                    } else {

                        setCouSTOpenDateCError(false);
                    }
                } else {
                    //驗証{第二階段名額公告日期}是否大於等於{報名結束日期時間}
                    if (couSTOpenDateC < couSTRegEndDateB) {
                        resultDate = false;
                        setCouSTOpenDateCError(true);
                    } else {

                        setCouSTOpenDateCError(false);
                    }
                }
                if (couSCloseDate < couSTOpenDateC) {
                    resultDate = false;
                    setCouSCloseDateError(true);
                }
                else {
                    setCouSCloseDateError(false);
                }
            }

            //當選擇 報名順序 且 繳費期限 選天數 就要判斷 資格取消及備取通知日期時間 是否有大於 報名結束日期時間
            if (couSTPayTypeB == "B") {
                if (couSTRegEndDateB > couSTLoseNoticeDateB) {
                    resultDate = false;
                    setCouSTLoseNoticeDateBError(true);
                } else {

                    setCouSTLoseNoticeDateBError(false);
                }
            }

            //當選擇 報名順序 且 繳費期限 選天數 就要判斷 錄取通知及資格取消日期時間 是否有大於 報名結束日期時間
            if (couSTPayTypeP == "B") {
                if (couSTRegEndDateB > couSTLoseNoticeDateP) {
                    resultDate = false;
                    setCouSTLoseNoticeDatePError(true);
                } else {

                    setCouSTLoseNoticeDatePError(false);
                }
            }

        }
        return resultDate;
    }
    
    function Cancel() { //取消
        required()

        if (couSDistType != '') {
            setCouSDistType("");
        }

        setCouSTPayTypeB("");
        setCouSTPayTypeP("");
    }

    function required() { //驗證恢復預設

        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });
        setCouSLimitCntError(false);//報名場次限制
        setCouSDistTypeError(false);//錄取模式
        setCouSPublicDateError(false); //公告時間
        setCouSTRegStartDateAError(false); //報名開始日期
        setCouSTRegEndDateAError(false); //報名結束日期
        setCouSTDrawDateAError(false); //隨機抽選日期
        setCouSTPayEndDateAError(false); //繳費期限日期
        setCouSTOpenDateAError(false); //名單公布日期
        setCouSTLoseNoticeDateAError(false); //資格取消及備取通知日期

        setCouSTRegStartDateBError(false); //報名開始日期時間 (先報先取)
        setCouSTRegEndDateBError(false); //報名結束日期 (先報先取)
        setCouSTPayEndDateBError(false); //繳費期限日期 (先報先取)
        setCouSTLoseNoticeDateBError(false); //錄取通知及資格取消日期 (先報先取)

        setCouSTPayEndDatePError(false); //備取繳費期限日期時間 (先報先取)
        setCouSTLoseNoticeDatePError(false); //錄取通知及資格取消日期時間 (先報先取)

        setCouSTOpenDateCError(false); //名額公告日期
        setCouSCloseDateError(false);

    }

    function Add() { //新增 另存新檔
        var SchdTList = Edit();

        if (SchdTList != '') {
            //新增
            console.log("post");

            API_POST_CourseSchdData({
                CouSDistType: couSDistType,
                CouSName: couSName,
                CouSPublicDate: couSPublicDate,
                CouSCloseDate: couSCloseDate,
                CouSLimitCnt: couSLimitCnt,
                SchdTList: SchdTList
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        window.location.reload();                        
                    } else {
                        openModalError("新增", data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        }


    }

    function UpData() { //更新資料
        var SchdTList = Edit();

        if (couSID == '') {
            Add();
        } else {
            if (SchdTList != '') {
                //編輯
                console.log("put");

                API_Put_CourseSchdData({
                    CouSID: couSID,
                    CouSDistType: couSDistType,
                    CouSName: couSName,
                    CouSDisplay: true,
                    CouSPublicDate: couSPublicDate,
                    CouSCloseDate: couSCloseDate,
                    CouSLimitCnt: couSLimitCnt,
                    SchdTList: SchdTList
                }).then((response) => response.data)
                    .then((data) => {
                        console.log(data);
                        if (data.code == "0000") {
                            window.location.reload();
                        } else {
                            openModalError("更新", data.message);
                        }

                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })

            }
        }

    }

    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    function handleshow(e) {
        const getshwo = e;
        setCouSDistType(getshwo);
        //電腦隨機抽選 CouSTSNo = A
        setCouSLimitCnt("0"); //報名場次限制(CouSDistType=A才有值)
        setCouSTRegStartDateA(""); //報名開始日期時間
        setCouSTRegEndDateA(""); //報名結束日期時間
        setCouSTPayEndDateA(""); //繳費期限日期時間
        setCouSTPayNoticeA("00"); //催繳通知方式
        setCouSTLoseNoticeDateA("");//資格取消及備取通知日期時間
        setCouSTLoseNoticeA("00"); //資格取消及備取通知方式
        setCouSTDrawDateA(""); //抽籤日期
        setCouSTOpenDateA(""); //公告時間
        setCouSTOpenNoticeA("00"); //公告通知方式

        setCouSTPayTypeB("A")
        setCouSTPayTypeP("A")

        //備取 CouSTSNo = P
    /*    setCouSTPayTypeP(""); //備取繳費期限(A=日期，B=天數)*/  //為了預設為日期 先隱藏
        setCouSTPayDaysP(null); //備取可繳費天數
        setCouSTPayEndDateP(null); //備取繳費期限日期時間
        setCouSTPayNoticeP("00"); //備取繳費通知方式
        setCouSTLoseNoticeDateP(""); //錄取通知及資格取消日期時間
        setCouSTLoseNoticeP("00"); //錄取通知及資格取消通知方式

        //開課時現場報名 CouSTSNo = C
        setCouSTOpenDateC(""); //公告時間

        //備取報名 CouSTSNo = B
        /*setCouSTPayTypeB("0"); //繳費期限(A=日期，B=天數)*/ //為了預設為日期 先隱藏
        setCouSTRegStartDateB(""); //報名開始日期時間
        setCouSTRegEndDateB(""); //報名結束日期時間
        setCouSTPayDaysB(null); //可繳費天數
        setCouSTPayEndDateB(null); //繳費期限日期時間
        setCouSTPayNoticeB("00"); //催繳通知方式
        setCouSTLoseNoticeDateB("");//錄取通知及資格取消日期時間
        setCouSTLoseNoticeB("00"); //錄取通知及資格取消日期時間通知方式


        //驗證恢復預設
        required();
    }

    function handleshowB(e) {
        const CouSTPayType = e;
        setCouSTPayTypeB(CouSTPayType);

        setCouSTPayEndDateB(null); //備取繳費期限日期時間
        setCouSTLoseNoticeDateB(null); //資格取消及備取通知日期時間
        
        setCouSTPayDaysB(null); //備取可繳費天數

        //驗證恢復預設
        required();
    }

    function handleshowP(e) {
        const CouSTPayType = e;
        setCouSTPayTypeP(CouSTPayType);

        setCouSTPayEndDateP(null); //備取繳費期限日期時間
        setCouSTLoseNoticeDateP(null); //備取錄取通知及資格取消日期時間
        setCouSTPayDaysP(null); //備取可繳費天數

        //驗證恢復預設
        required();
    }

    return (
        <>
            <div className="modal-container">
                <div className="modal-header">
                    <h3 className="fz-B has-border">報名作業時間設定</h3>
                </div>
                <div className="modal-body">
                    <div className="form-container">
                        <div className="form-item thirty" style={{ marginBottom: '25px' }}>
                            <div className="top-table">名稱</div>
                            <div className="top-value">
                                <input id="SName" type="text" value={couSName} onChange={(e) => { setCouSName(e.target.value) }} data-required />
                            </div>
                        </div>
                        <span className={`form-item must ${couSDistTypeError ? "show-help" : ""}`} style={{ width: '400px' }}>
                            <div className="form-item thirty" style={{ width: '360px' }}>
                                <div className="top-table">錄取模式</div>
                                <div className="top-value multi-inline" >
                                    {couSDistType == 'A' ? <input type="radio" id="f222-1" name="f222" value="A" disabled={couSID == "" ? false : true} checked={true} onClick={(e) => handleshow("A")} data-required />
                                        : <input type="radio" id="f222-1" name="f222" value="A" disabled={couSID == "" ? false : true}   onClick={(e) => handleshow("A")} data-required />}
                                    <label for="f222-1" className="mr-1">電腦隨機抽選</label>

                                    {couSDistType == 'B' ? <input type="radio" id="f222-2" name="f222" value="B" disabled={couSID == "" ? false : true} checked={true} onClick={(e) => handleshow("B")} data-required />
                                        : <input type="radio" id="f222-2" name="f222" value="B" disabled={couSID == "" ? false : true}  onClick={(e) => handleshow("B")} data-required />}
                                    <label for="f222-2">依報名順序</label>
                                </div>
                            </div>
                            <span className="help-word"> 請選擇錄取模式</span>
                        </span>
                    </div>

                    <div className="form-container">
                        <div className="form-item multi-inline">
                            <span className={`form-item must ${couSPublicDateError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                <div className="multi-inline">
                                    <div className="top-table" style={{ width: '130px', marginLeft: '30px', marginRight: '30px' }}>公告開始日期時間</div>

                                    <div className="top-value" style={{ width: '300px' }}>

                                        <input id="datetime-local" type="datetime-local" style={{ width: '250px' }} value={couSPublicDate} onChange={(e) => { setCouSPublicDate(e.target.value) }} data-required />
                                    </div>
                                </div>
                                <span className="help-word"> 公告開始日期時間 需大於等於 今日</span>
                            </span>
                            <span className={`form-item must ${couSCloseDateError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                <div className="multi-inline">
                                    <div className="top-table" style={{ width: '130px', marginLeft: '30px', marginRight: '30px' }}>公告結束日期時間</div>

                                    <div className="top-value" style={{ width: '300px' }}>

                                        <input id="datetime-local" type="datetime-local" style={{ width: '250px' }} value={couSCloseDate} onChange={(e) => { setCouSCloseDate(e.target.value) }} data-required />
                                    </div>
                                </div>
                                <span className="help-word"> 公告結束日期時間 需大於等於 最後名額公告開始日期時間</span>
                            </span>
                            {/*<div className="top-table" style={{ width: '130px', marginLeft: '30px', marginRight: '30px' }}>公告開始日期時間</div>*/}
                            {/*<div className="top-value" style={{ width: '300px' }}>*/}

                            {/*    <input type="datetime-local" style={{ width: '250px' }} value={couSPublicDate} onChange={(e) => { setCouSPublicDate(e.target.value) }} data-required />*/}
                            {/*</div>*/}
                            
                        </div>
                        <div className="form-item multi-inline">
                            {
                                couSDistType === 'A' && (
                                    <span className={`form-item must ${couSLimitCntError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                        <div className="form-item multi-inline">
                                            <div className="top-table" style={{ width: '130px',  marginRight: '45px' }}>報名場次限制</div>
                                            <div className="top-value" style={{ width: '300px' }}>
                                                <input type="text" style={{ width: '250px' }} value={couSLimitCnt} onChange={(e) => { setCouSLimitCnt(e.target.value) }} data-required />
                                            </div>
                                        </div>
                                        {couSLimitCntError ? <span className="help-word"> 報名場次限制 需大於 0</span> : ""}                         
                                    </span>
                                )
                            }
                        </div>
                    </div>
                    {
                        //電腦隨機選取
                        couSDistType === 'A' && (

                            <div className="form-container">
                                <div className="form-item half">
                                    <div className="form-item">
                                        <h3 className="mr-2 text-red">第一階段錄取方式採電腦隨機抽</h3>
                                    </div>{couSTRegStartDateAError}
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTRegStartDateAError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名開始日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegStartDateA} onChange={(e) => { setCouSTRegStartDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegStartDateAError ? <span className="help-word"> 報名開始日期時間 需大於等於 公告開始日期時間</span> : "" }
                                            
                                        </span>

                                        <span className={`form-item must ${couSTRegEndDateAeError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名結束日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegEndDateA} onChange={(e) => { setCouSTRegEndDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegEndDateAeError ? <span className="help-word"> 報名結束日期時間 需大於等於 報名開始日期時間</span> : "" }
                                            
                                        </span>
                                    </div>
                                    <div className="sign-up-card">    
                                        <span className={`form-item must ${couSTDrawDateAError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">隨機抽選日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTDrawDateA} onChange={(e) => { setCouSTDrawDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTDrawDateAError ? <span className="help-word"> 隨機抽選日期時間 需大於等於 報名結束日期時間</span> : ""}                              
                                        </span>

                                        <span className={`form-item must ${couSTOpenDateAError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">名單公布日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTOpenDateA} onChange={(e) => { setCouSTOpenDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTOpenDateAError ? <span className="help-word"> 名單公布日期時間 需大於等於 隨機抽選日期時間</span> : ""}                                            
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">通知方式</div>
                                            <div className="value multi-inline">
                                            
                                                {couSTOpenNoticeA == '10' || couSTOpenNoticeA == '11'
                                                    ? <input type="checkbox" id="f1021-1" name="f1021" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTOpenNoticeA == '01') {
                                                                setCouSTOpenNoticeA('11');
                                                            } else {
                                                                setCouSTOpenNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTOpenNoticeA == '11') {
                                                                setCouSTOpenNoticeA('01');
                                                            } else {
                                                                setCouSTOpenNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTOpenNoticeA[0] == "1" ? true : false} data-required />
                                                    : <input type="checkbox" id="f1021-1" name="f1021"  onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTOpenNoticeA == '01') {
                                                                setCouSTOpenNoticeA('11');
                                                            } else {
                                                                setCouSTOpenNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTOpenNoticeA == '11') {
                                                                setCouSTOpenNoticeA('01');
                                                            } else {
                                                                setCouSTOpenNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTOpenNoticeA[0] == "1" ? true : false} data-required/>}
                                                <label for="f1021-1" className="mr-1">簡訊通知</label>

                                                {couSTOpenNoticeA == '01' || couSTOpenNoticeA == '11'
                                                    ? <input type="checkbox" id="f1021-2" name="f1021" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                        
                                                            if (couSTOpenNoticeA == '10') {
                                                                setCouSTOpenNoticeA('11');
                                                            } else {
                                                                setCouSTOpenNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTOpenNoticeA == '11') {
                                                                setCouSTOpenNoticeA('10');
                                                            } else {
                                                                setCouSTOpenNoticeA('00');
                                                            }

                                                        }
                                                    }} checked={couSTOpenNoticeA[1] == "1" ? true : false} data-required />
                                                    : <input type="checkbox" id="f1021-2" name="f1021" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTOpenNoticeA == '10') {
                                                                setCouSTOpenNoticeA('11');
                                                            } else {
                                                                setCouSTOpenNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTOpenNoticeA == '11') {
                                                                setCouSTOpenNoticeA('10');
                                                            } else {
                                                                setCouSTOpenNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTOpenNoticeA[1] == "1" ? true : false} data-required/>}
                                                <label for="f1021-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTPayEndDateAError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">繳費期限日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTPayEndDateA} onChange={(e) => { setCouSTPayEndDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTPayEndDateAError ? <span className="help-word"> 繳費期限日期時間 需大於等於 名單公布日期時間</span> : ""}                                            
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">催繳通知方式</div>
                                            <div className="value multi-inline">
                                           
                                                {couSTPayNoticeA == '10' || couSTPayNoticeA == '11'
                                                    ? <input type="checkbox" id="f1022-1" name="f1022" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeA == '01') {
                                                                setCouSTPayNoticeA('11');
                                                            } else {
                                                                setCouSTPayNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeA == '11') {
                                                                setCouSTPayNoticeA('01');
                                                            } else {
                                                                setCouSTPayNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeA[0] == "1" ? true : false}  data-required/>
                                                    : <input type="checkbox" id="f1022-1" name="f1022" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeA == '01') {
                                                                setCouSTPayNoticeA('11');
                                                            } else {
                                                                setCouSTPayNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeA == '11') {
                                                                setCouSTPayNoticeA('01');
                                                            } else {
                                                                setCouSTPayNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeA[0] == "1" ? true : false} data-required/>}
                                                <label for="f1022-1" className="mr-1">簡訊通知</label>

                                                {couSTPayNoticeA == '01' || couSTPayNoticeA == '11'
                                                    ? <input type="checkbox" id="f1022-2" name="f1022" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeA == '10') {
                                                                setCouSTPayNoticeA('11');
                                                            } else {
                                                                setCouSTPayNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeA == '11') {
                                                                setCouSTPayNoticeA('10');
                                                            } else {
                                                                setCouSTPayNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeA[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1022-2" name="f1022" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeA == '10') {
                                                                setCouSTPayNoticeA('11');
                                                            } else {
                                                                setCouSTPayNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeA == '11') {
                                                                setCouSTPayNoticeA('10');
                                                            } else {
                                                                setCouSTPayNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeA[1] == "1" ? true : false} data-required/>}
                                                <label for="f1022-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTLoseNoticeDateAError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">資格取消日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateA} onChange={(e) => { setCouSTLoseNoticeDateA(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTLoseNoticeDateAError ? <span className="help-word"> 資格取消日期時間 需大於等於 繳費期限日期時間</span> : ""}                                        
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTLoseNoticeA == '10' || couSTLoseNoticeA == '11'
                                                    ? <input type="checkbox" id="f1023-1" name="f1023" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeA == '01') {
                                                                setCouSTLoseNoticeA('11');
                                                            } else {
                                                                setCouSTLoseNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeA == '11') {
                                                                setCouSTLoseNoticeA('01');
                                                            } else {
                                                                setCouSTLoseNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeA[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1023-1" name="f1023" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeA == '01') {
                                                                setCouSTLoseNoticeA('11');
                                                            } else {
                                                                setCouSTLoseNoticeA('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeA == '11') {
                                                                setCouSTLoseNoticeA('01');
                                                            } else {
                                                                setCouSTLoseNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeA[0] == "1" ? true : false} data-required/>}
                                                <label for="f1023-1" className="mr-1">簡訊通知</label>

                                                {couSTLoseNoticeA == '01' || couSTLoseNoticeA == '11'
                                                    ? <input type="checkbox" id="f1023-2" name="f1023" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeA == '10') {
                                                                setCouSTLoseNoticeA('11');
                                                            } else {
                                                                setCouSTLoseNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeA == '11') {
                                                                setCouSTLoseNoticeA('10');
                                                            } else {
                                                                setCouSTLoseNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeA[1] == "1" ? true : false} data-required />
                                                    : <input type="checkbox" id="f1023-2" name="f1023" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeA == '10') {
                                                                setCouSTLoseNoticeA('11');
                                                            } else {
                                                                setCouSTLoseNoticeA('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeA == '11') {
                                                                setCouSTLoseNoticeA('10');
                                                            } else {
                                                                setCouSTLoseNoticeA('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeA[1] == "1" ? true : false} data-required/>}
                                                <label for="f1023-2">郵件通知</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                <div className="form-item half">
                                    <div className="form-item">
                                        <h3 className="mr-2 text-red">第二階段錄取方式採先報先取原則</h3>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTRegStartDateBError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名開始日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegStartDateB} onChange={(e) => { setCouSTRegStartDateB(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegStartDateBError ? <span className="help-word"> 報名開始日期時間 需大於等於 資格取消日期時間</span> : ""}                                            
                                        </span>

                                        <span className={`form-item must ${couSTRegEndDateBError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名結束日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegEndDateB} onChange={(e) => { setCouSTRegEndDateB(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegEndDateBError ? <span className="help-word"> 報名結束日期時間 需大於等於 報名開始日期時間</span> : ""}                                            
                                        </span>
                                    </div>

                                    <div class="sign-up-card">
                                    
                                        <div className="form-item" style={{ marginLeft: '30px'}}>
                                            <div className="field">繳費期限</div>
                                            <div className="value multi-inline">
                                                <input type="radio" id="f2026-1" name="f2026" value="A" checked={couSTPayTypeB === 'A'} onChange={(e) => handleshowB("A")} data-required />
                                                <label for="f2026-1" className="mr-1">日期</label>
                                                <input type="radio" id="f2026-2" name="f2026" value="B" checked={couSTPayTypeB === 'B'} onChange={(e) => handleshowB("B")} data-required />
                                                <label for="f2026-2">天數</label>
                                            </div>
                                        </div>

                                        {
                                            couSTPayTypeB === 'A' && (
                                                <span className={`form-item must ${couSTPayEndDateBError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">繳費期限日期時間</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="datetime-local" style={{ width: '250px' }} value={couSTPayEndDateB} onChange={(e) => { setCouSTPayEndDateB(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    {couSTPayEndDateBError ? <span className="help-word"> 繳費期限日期時間 需大於等於 報名結束日期時間</span> : ""}
                                                
                                                </span>
                                            )
                                        }
                                        {
                                            couSTPayTypeB === 'B' && (
                                                //<span className={`form-item must ${couSTPayEndDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">繳費天數</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="text" placeholder="至少3天(不含報名日)" style={{ width: '250px' }} value={couSTPayDaysB} onChange={(e) => { setCouSTPayDaysB(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    //<span className="help-word"> 備取繳費期限日期時間 需大於等於 資格取消及備取通知日期時間</span>
                                                //{/*</span>*/}
                                            )
                                        }

                                        <div className="form-item" style={{ marginLeft: '30px', width: '420px' }}>
                                            <div className="field">催繳通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTPayNoticeB == '10' || couSTPayNoticeB == '11'
                                                    ? <input type="checkbox" id="f1024-1" name="f1024" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '01') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('01');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1024-1" name="f1024" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '01') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('01');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[0] == "1" ? true : false} data-required/>}
                                                <label for="f1024-1" className="mr-1">簡訊通知</label>

                                                {couSTPayNoticeB == '01' || couSTPayNoticeB == '11'
                                                    ? <input type="checkbox" id="f1024-2" name="f1024" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '10') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('10');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1024-2" name="f1024" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '10') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('10');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[1] == "1" ? true : false} data-required/>}
                                                <label for="f1024-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="sign-up-card">
                                        {/*//todo:0505 有改判斷*/}
                                        <span className={`form-item must ${couSTLoseNoticeDateBError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">錄取通知及資格取消日期時間</div>  
                                                </div>

                                                <div className="form-item">
                                                    {couSTPayTypeB === 'B' 
                                                        ? <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateB} onChange={(e) => { setCouSTLoseNoticeDateB(e.target.value) }} data-required />
                                                        : <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateB} onChange={(e) => { setCouSTLoseNoticeDateB(e.target.value) }} data-required />
                                                    }
                                                </div>
                                            </div>
                                            {couSTPayTypeB === 'A' && couSTLoseNoticeDateBError
                                                ? <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 繳費期限日期時間</span>
                                                : (couSTPayTypeB === 'A' && !couSTLoseNoticeDateBError
                                                    ? ""
                                                    : <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 報名結束日期時間</span>
                                                )
                                            }

                                            {/*原本的*/}
                                            {/*{couSTPayTypeB === 'A' */}
                                            {/*    ? <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 繳費期限日期時間</span>*/}
                                            {/*    : <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 報名結束日期時間</span>*/}
                                            {/*}*/}
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTLoseNoticeB == '10' || couSTLoseNoticeB == '11'
                                                    ? <input type="checkbox" id="f1025-1" name="f1025" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '01') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('01');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeB[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1025-1" name="f1025" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '01') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('01');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeB[0] == "1" ? true : false} data-required/>}
                                                <label for="f1025-1" className="mr-1">簡訊通知</label>

                                                {couSTLoseNoticeB == '01' || couSTLoseNoticeB == '11'
                                                    ? <input type="checkbox" id="f1025-2" name="f1025" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '10') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('10');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeB[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f1025-2" name="f1025" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '10') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('10');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeB[1] == "1" ? true : false} data-required/>}
                                                <label for="f1025-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="sign-up-card">
                                        <div className="form-item">
                                            <h3 className="mr-2 text-red">第三階段開課時現場報名</h3>
                                        </div>
                                 
                                        <span className={`form-item must ${couSTOpenDateCError ? "show-help" : ""}`} style={{ width: '450px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">名額公告日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTOpenDateC} onChange={(e) => { setCouSTOpenDateC(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTPayTypeB === 'A' && couSTOpenDateCError
                                                ? <span className="help-word"> 名額公告日期時間 需大於等於 錄取通知及資格取消日期時間</span>
                                                : (couSTPayTypeB === 'A' && !couSTOpenDateCError)
                                                    ? ""
                                                    : <span className="help-word"> 名額公告日期時間 需大於等於 報名結束日期時間</span>                                                
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        //依報名順序
                        couSDistType === 'B' && (
                            <div className="form-container">
                                <div className="form-item half">
                                    <div className="form-item">
                                        <h3 className="mr-2 text-red">第一階段錄取方式採先報先取原則</h3>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTRegStartDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名開始日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegStartDateB} onChange={(e) => { setCouSTRegStartDateB(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegStartDateBError ? <span className="help-word"> 報名開始日期時間 需大於等於 公告開始日期時間</span> : ""}                                            
                                        </span>

                                        <span className={`form-item must ${couSTRegEndDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">報名結束日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTRegEndDateB} onChange={(e) => { setCouSTRegEndDateB(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTRegEndDateBError ? <span className="help-word"> 報名結束日期時間 需大於等於 報名開始日期時間</span> : "" }                                            
                                        </span>
                                    </div>
                                    <div className="sign-up-card">
                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">繳費期限</div>
                                            <div className="value multi-inline">
                                                <input type="radio" id="f2026-1" name="f2026" value="A" checked={couSTPayTypeB === 'A'} onChange={(e) => handleshowB("A")} data-required />
                                                <label for="f2026-1" className="mr-1">日期</label>
                                                <input type="radio" id="f2026-2" name="f2026" value="B" checked={couSTPayTypeB === 'B'} onChange={(e) => handleshowB("B")} data-required />
                                                <label for="f2026-2">天數</label>
                                            </div>
                                        </div>

                                        {
                                            couSTPayTypeB === 'A' && (
                                                <span className={`form-item must ${couSTPayEndDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">繳費期限日期時間</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="datetime-local" style={{ width: '250px' }} value={couSTPayEndDateB} onChange={(e) => { setCouSTPayEndDateB(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    {couSTPayEndDateBError ? <span className="help-word"> 繳費期限日期時間 需大於等於 報名結束日期時間</span> : "" }                                                   
                                                </span>
                                            )
                                        }
                                        {
                                            couSTPayTypeB === 'B' && (
                                                <span className={`form-item must ${couSTPayEndDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">繳費天數</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="text" placeholder="至少3天(不含報名日)" style={{ width: '250px' }} value={couSTPayDaysB} onChange={(e) => { setCouSTPayDaysB(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    <span className="help-word"> 繳費期限日期時間 需大於等於 資格取消日期時間</span>
                                                </span>
                                            )
                                        }

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">催繳通知方式</div>
                                            <div className="value multi-inline">
                                           
                                                {couSTPayNoticeB == '10' || couSTPayNoticeB == '11'
                                                    ? <input type="checkbox" id="f2021-1" name="f2021" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '01') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('01');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2021-1" name="f2021" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '01') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('01');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeB[0] == "1" ? true : false} data-required/>}
                                                <label for="f2021-1" className="mr-1">簡訊通知</label>

                                                {couSTPayNoticeB == '01' || couSTPayNoticeB == '11'
                                                    ? <input type="checkbox" id="f2021-2" name="f2021" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '10') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('10');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTPayNoticeB[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2021-2" name="f2021" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeB == '10') {
                                                                setCouSTPayNoticeB('11');
                                                            } else {
                                                                setCouSTPayNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeB == '11') {
                                                                setCouSTPayNoticeB('10');
                                                            } else {
                                                                setCouSTPayNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTPayNoticeB[1] == "1" ? true : false} data-required/>}
                                                <label for="f2021-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTLoseNoticeDateBError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">資格取消日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    {couSTPayTypeB === 'B'
                                                        ? <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateB} onChange={(e) => { setCouSTLoseNoticeDateB(e.target.value) }} data-required />
                                                        : <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateB} onChange={(e) => { setCouSTLoseNoticeDateB(e.target.value) }} data-required />
                                                    }
                                                </div>
                                            </div>

                                            {couSTPayTypeB === 'A' && couSTLoseNoticeDateBError
                                                ? <span className="help-word"> 資格取消日期時間 需大於等於 繳費期限日期時間</span>
                                                : (couSTPayTypeB === 'A' && !couSTLoseNoticeDateBError
                                                    ? ""
                                                    : <span className="help-word"> 資格取消日期時間 需大於等於 報名結束日期時間</span>
                                                )
                                            }
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTLoseNoticeB == '10' || couSTLoseNoticeB == '11'
                                                    ? <input type="checkbox" id="f2022-1" name="f2022" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '01') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('01');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeB[0] == "1" ? true : false} data-required />
                                                    : <input type="checkbox" id="f2022-1" name="f2022" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '01') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('01');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeB[0] == "1" ? true : false} data-required/>}
                                                <label for="f2022-1" className="mr-1">簡訊通知</label>

                                                {couSTLoseNoticeB == '01' || couSTLoseNoticeB == '11'
                                                    ? <input type="checkbox" id="f2022-2" name="f2022" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '10') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('10');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeB[1] == "1" ? true : false} data-required />
                                                    : <input type="checkbox" id="f2022-2" name="f2022" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeB == '10') {
                                                                setCouSTLoseNoticeB('11');
                                                            } else {
                                                                setCouSTLoseNoticeB('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeB == '11') {
                                                                setCouSTLoseNoticeB('10');
                                                            } else {
                                                                setCouSTLoseNoticeB('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeB[1] == "1" ? true : false} data-required/>}
                                                <label for="f2022-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-card">
                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                        <div className="field">繳費期限</div>
                                        <div className="value multi-inline">
                                            <input type="radio" id="f2025-1" name="f2025" value="A" checked={couSTPayTypeP === 'A'} onChange={(e) => handleshowP("A")}  data-required />
                                            <label for="f2025-1" className="mr-1">日期</label>
                                            <input type="radio" id="f2025-2" name="f2025" value="B" checked={couSTPayTypeP === 'B'} onChange={(e) => handleshowP("B")} data-required />
                                            <label for="f2025-2">天數</label>

                                            
                                         
                                        </div>                                
                                    </div>
                                        {
                                            couSTPayTypeP === 'A' && (
                                                <span className={`form-item must ${couSTPayEndDatePError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">繳費期限日期時間</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="datetime-local" style={{ width: '250px' }} value={couSTPayEndDateP} onChange={(e) => { setCouSTPayEndDateP(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    {couSTPayEndDatePError && (
                                                        ( couSTPayTypeP === 'A' && couSTPayTypeB === 'A'
                                                            ? <span className="help-word"> 備取繳費期限日期時間 需大於等於 資格取消日期時間</span>
                                                            : couSTPayTypeP === 'A' && couSTPayTypeB === 'B'
                                                                ? <span className="help-word"> 備取繳費期限日期時間 需大於等於 報名結束日期時間</span>
                                                                : <span className="help-word"> 備取繳費期限日期時間 需大於等於 資格取消日期時間</span>
                                                        )
                                                    )}
                                                   

                                                </span>
                                            )
                                        }
                                        {
                                            couSTPayTypeP === 'B' && (
                                                <span className={`form-item must ${couSTPayEndDatePError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                                    <div className="multi-inline">
                                                        <div className="form-item">
                                                            <div className="fw-b">備取繳費天數</div>
                                                        </div>

                                                        <div className="form-item">
                                                            <input type="text" placeholder="至少3天(不含報名日)" style={{ width: '250px' }} value={couSTPayDaysP} onChange={(e) => { setCouSTPayDaysP(e.target.value) }} data-required />
                                                        </div>
                                                    </div>
                                                    {couSTPayEndDatePError ? <span className="help-word"> 備取繳費期限日期時間 需大於等於 資格取消日期時間</span> : ""}                                                    
                                                </span>
                                            )
                                        }

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">繳費通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTPayNoticeP == '10' || couSTPayNoticeP == '11'
                                                    ? <input type="checkbox" id="f2023-1" name="f2023" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeP == '01') {
                                                                setCouSTPayNoticeP('11');
                                                            } else {
                                                                setCouSTPayNoticeP('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeP == '11') {
                                                                setCouSTPayNoticeP('01');
                                                            } else {
                                                                setCouSTPayNoticeP('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeP[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2023-1" name="f2023" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeP == '01') {
                                                                setCouSTPayNoticeP('11');
                                                            } else {
                                                                setCouSTPayNoticeP('10');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeP == '11') {
                                                                setCouSTPayNoticeP('01');
                                                            } else {
                                                                setCouSTPayNoticeP('00');
                                                            }
                                                        }
                                                    }} checked={couSTPayNoticeP[0] == "1" ? true : false} data-required/>}
                                                <label for="f2023-1" className="mr-1">簡訊通知</label>

                                                {couSTPayNoticeP == '01' || couSTPayNoticeP == '11'
                                                    ? <input type="checkbox" id="f2023-2" name="f2023" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeP == '10') {
                                                                setCouSTPayNoticeP('11');
                                                            } else {
                                                                setCouSTPayNoticeP('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeP == '11') {
                                                                setCouSTPayNoticeP('10');
                                                            } else {
                                                                setCouSTPayNoticeP('00');
                                                            }

                                                        }
                                                    }} checked={couSTPayNoticeP[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2023-2" name="f2023" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTPayNoticeP == '10') {
                                                                setCouSTPayNoticeP('11');
                                                            } else {
                                                                setCouSTPayNoticeP('01');
                                                            }

                                                        } else {
                                                            if (couSTPayNoticeP == '11') {
                                                                setCouSTPayNoticeP('10');
                                                            } else {
                                                                setCouSTPayNoticeP('00');
                                                            }

                                                        }
                                                    }} checked={couSTPayNoticeP[1] == "1" ? true : false} data-required/>}
                                                <label for="f2023-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-up-card">
                                        <span className={`form-item must ${couSTLoseNoticeDatePError ? "show-help" : ""}`} style={{ width: '500px' }}>
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">錄取通知及資格取消日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                
                                                    {couSTPayTypeP === 'B'
                                                        ? <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateP} onChange={(e) => { setCouSTLoseNoticeDateP(e.target.value) }} data-required />
                                                        : <input type="datetime-local" style={{ width: '250px' }} value={couSTLoseNoticeDateP} onChange={(e) => { setCouSTLoseNoticeDateP(e.target.value) }} data-required />
                                                    }
                                                    
                                                </div>
                                            </div>
                                            {couSTLoseNoticeDatePError && (
                                                ( couSTPayTypeP === 'A'
                                                    ? <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 備取繳費期限日期時間</span>
                                                    : <span className="help-word"> 錄取通知及資格取消日期時間 需大於等於 報名結束日期時間</span>
                                                )
                                            ) }
                                           
                                        </span>

                                        <div className="form-item" style={{ marginLeft: '30px' }}>
                                            <div className="field">通知方式</div>
                                            <div className="value multi-inline">

                                                {couSTLoseNoticeP == '10' || couSTLoseNoticeP == '11'
                                                    ? <input type="checkbox" id="f2024-1" name="f2024" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeP == '01') {
                                                                setCouSTLoseNoticeP('11');
                                                            } else {
                                                                setCouSTLoseNoticeP('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeP == '11') {
                                                                setCouSTLoseNoticeP('01');
                                                            } else {
                                                                setCouSTLoseNoticeP('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeP[0] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2024-1" name="f2024" value="10" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeP == '01') {
                                                                setCouSTLoseNoticeP('11');
                                                            } else {
                                                                setCouSTLoseNoticeP('10');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeP == '11') {
                                                                setCouSTLoseNoticeP('01');
                                                            } else {
                                                                setCouSTLoseNoticeP('00');
                                                            }
                                                        }
                                                    }} checked={couSTLoseNoticeP[0] == "1" ? true : false} data-required/>}
                                                <label for="f2024-1" className="mr-1">簡訊通知</label>

                                                {couSTLoseNoticeP == '01' || couSTLoseNoticeP == '11'
                                                    ? <input type="checkbox" id="f2024-2" name="f2024" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeP == '10') {
                                                                setCouSTLoseNoticeP('11');
                                                            } else {
                                                                setCouSTLoseNoticeP('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeP == '11') {
                                                                setCouSTLoseNoticeP('10');
                                                            } else {
                                                                setCouSTLoseNoticeP('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeP[1] == "1" ? true : false} data-required/>
                                                    : <input type="checkbox" id="f2024-2" name="f2024" value="01" onChange={(e) => {
                                                        // add to list
                                                        if (e.target.checked) {
                                                            if (couSTLoseNoticeP == '10') {
                                                                setCouSTLoseNoticeP('11');
                                                            } else {
                                                                setCouSTLoseNoticeP('01');
                                                            }

                                                        } else {
                                                            if (couSTLoseNoticeP == '11') {
                                                                setCouSTLoseNoticeP('10');
                                                            } else {
                                                                setCouSTLoseNoticeP('00');
                                                            }

                                                        }
                                                    }} checked={couSTLoseNoticeP[1] == "1" ? true : false} data-required/>}
                                                <label for="f2024-2">郵件通知</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="form-item" style={{ position: 'absolute', top: '0' }}>
                                        <h3 className="mr-2 text-red">第二階段開課時現場報名</h3>
                                    </div>
                                    <span className={`form-item must ${couSTOpenDateCError ? "show-help" : ""}`} style={{ width: '500px', position: 'absolute', top: '40px' }}>
                                        <div class="sign-up-card">
                                            <div className="multi-inline">
                                                <div className="form-item">
                                                    <div className="fw-b">第二階段名額公告日期時間</div>
                                                </div>

                                                <div className="form-item">
                                                    <input type="datetime-local" style={{ width: '250px' }} value={couSTOpenDateC} onChange={(e) => { setCouSTOpenDateC(e.target.value) }} data-required />
                                                </div>
                                            </div>
                                            {couSTOpenDateCError && (
                                                ( couSTPayTypeB === 'B' && couSTPayTypeP === 'B'
                                                    ? <span className="help-word"> 第二階段名額公告日期時間 需大於等於 報名結束日期時間</span>
                                                    : couSTPayTypeB === 'A' && couSTPayTypeP === 'B'
                                                        ? <span className="help-word"> 第二階段名額公告日期時間 需大於等於 資格取消日期時間</span>
                                                        : <span className="help-word"> 第二階段名額公告日期時間 需大於等於 錄取通知及資格取消日期時間</span>
                                                )
                                            ) }
                                            
                                        </div>
                                            
                                    </span>
                                </div>
                            </div>
                        )
                    }

                    <div className="btn-holder text-center">
                        <a className="btn" onClick={UpData}>
                            {/*<a className="btn" >*/}
                            <span>存檔</span>
                        </a>
                        <a className="btn btn-border" onClick={Cancel} data-close>
                            {/* <a className="btn btn-border"  data-close>*/}
                            <span>放棄</span>
                        </a>
                        {editcouSID != '0' && (
                            <a className="btn" onClick={Add}>
                                {/* <a className="btn" >*/}
                                <span>另存新檔</span>
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div id="modal-error" className="modal small" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">{modalTit}失敗</div>
                        <div className="btn-holder">失敗原因:{modalStr}</div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default SignupDetail;
