import "../css/style.css";
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import { API_POST_Menus } from '../global/constants'
//import MenuItem from '../components/Main/MenuItem'
import CarouselBase from '../components/CarouselManage/CarouselBase';
import AnnouncementBase from '../components/CarouselManage/AnnouncementBase';
import MenuItem from '../components/Menu/MenuItem';
import CourseType from '../components/CourseTypeManage/CourseType';
import CourseClassType from '../components/CourseTypeManage/CourseClassType';
import CoursePeriod from '../components/CourseTypeManage/CoursePeriod';
import CourseBase from '../components/CourseTypeManage/CourseBase';
import InformBasic from '../components/InformManage/InformBasic';
import SignupSetting from '../components/CourseTypeManage/SignupSetting';
import CourseProcess from '../components/CourseTypeManage/CourseProcess';
import CourseContent from '../components/CourseTypeManage/CourseContent';
import Lottery from '../components/CourseTypeManage/Lottery';
import CourseTeachers from '../components/CourseTypeManage/CourseTeachers';
import InformSchedule from '../components/InformManage/InformSchedule';
import InformStatistics from '../components/InformManage/InformStatistics';
import InformScheduleModify from '../components/InformManage/InformScheduleModify';
import InformModify from '../components/InformManage/InformModify';
import FirReport from '../components/FirReportManage/FirReport';
import PfmReport from '../components/PfmReportManage/PfmReport';
import QuestionList from '../components/QuestionManage/QuestionList';
import QuestionMaintain from '../components/QuestionManage/QuestionMaintain';
import QuestionResult from '../components/QuestionManage/QuestionResult';
import TraineeList from '../components/TraineeManage/TraineeList';
import TraineeDiscount from '../components/TraineeManage/TraineeDiscount';
import SpotSignup from '../components/TraineeManage/SpotSignup';
import SpotPayRefund from '../components/TraineeManage/SpotPayRefund';
import TraineeIdentity from '../components/TraineeManage/TraineeIdentity';
import RefundList from '../components/TraineeManage/RefundList';
import SignUpReport from '../components/StatisticsSearch/SignUpReport';

//import ImagesList from '../images/';
//import SaleOrderList from '../components/SaleOrder/SaleOrderList';
//import SaleOrderNew from '../components/SaleOrder/SaleOrderNew';
//import SaleOrderEdit from '../components/SaleOrder/SaleOrderEdit';

//import Product from '../components/Product/Product';
//import ProductNew from '../components/Product/ProductNew';
//import ProductEdit from '../components/Product/ProductEdit';

//import ProductCost from '../components/ProductCost/ProductCost';
//import ProductCostView from '../components/ProductCost/ProductCostView';

//import SupplierQry from '../components/Supplier/SupplierQry';
//import SupplierNew from '../components/Supplier/SupplierNew';
//import SupplierEdit from '../components/Supplier/SupplierEdit';

//import Customer from '../components/Customer/Customer';
//import CustomerNew from '../components/Customer/CustomerNew';
//import CustomerEdit from '../components/Customer/CustomerEdit';
//import Permission from '../components/Permission/Permission';
//import PermissionEdit from '../components/Permission/PermissionEdit';

//import Prepaid_CustomerQry from '../components/Prepaid/Prepaid_CustomerQry';
//import Prepaid_CustomerView from '../components/Prepaid/Prepaid_CustomerView';
//import Prepaid_CustomerEdit from '../components/Prepaid/Prepaid_CustomerEdit';
//import Prepaid_CustomerList from '../components/Prepaid/Prepaid_CustomerList';
//import Prepaid_SupplierQry from '../components/Prepaid/Prepaid_SupplierQry';
//import Prepaid_SupplierView from '../components/Prepaid/Prepaid_SupplierView';
//import Prepaid_SupplierEdit from '../components/Prepaid/Prepaid_SupplierEdit';
//import Prepaid_SupplierList from '../components/Prepaid/Prepaid_SupplierList';

//import CreditsQry from '../components/Credits/CreditsQry';
//import CreditsView from '../components/Credits/CreditsView';
import { checkAuthToken } from "../global/TokenUtil";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

function main() {

    const history = useHistory();
    const [username, setUserName] = useState("");

    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const pageArray = JSON.parse(localStorage.getItem("pagelist"));
    const [menuArray, setMenuArray] = useState([{}]);
    //console.log(pageArray);

    useEffect(() => {
        document.body.classList.remove("login");
        document.body.classList.add("dashboard");
        document.getElementById("root").classList.remove("center");
        setUserName(localStorage.getItem("userName"));

        getData();
        //console.log(path);
    }, []);

    function getData() {
        API_POST_Menus({
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                
                setMenuArray(data.result);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //const menuArrayLen = menuArray.filter(item => item.FuncListType === "-1").length;
    //const action = localStorage.getItem('action');

    function logout() {
        localStorage.clear();
        history.push("/");
    }

    //延遲一秒在執行js事件綁定 在取消掉timeout 避免一直執行 導致一個功能要按好幾次才會出現

    useEffect(() => {

        const timeout = setTimeout(() => {

            /*slide toggle 往下展開*/

            if (document.querySelector('[data-slidetoggle]')) {

                const items = document.querySelectorAll('[data-slidetoggle]');

                items.forEach(element => {

                    var targetID = element.dataset.slidetoggle;

                    var target = document.querySelector('#' + targetID)

                    console.log(target);

                    element.addEventListener('click', function () {

                        element.classList.toggle('rotate');

                        target.classList.toggle('hide');

                    })
                });

            }

            /*顯示下拉*/

            const datalist = document.querySelectorAll("[data-datalist]");

            datalist.forEach((element) => {

                var dropmenu = element.querySelector(".datalist");

                var input = element.querySelector("input");

                input.addEventListener("keyup", function () {

                    element.classList.add("show-datalist");

                });

                document.body.addEventListener("click", function (e) {

                    var target = e.target;
                    if (element.contains(target)) {

                        //console.log(target);

                        if (dropmenu.contains(target)) {

                            var value = target.dataset.value;

                            input.value = value;

                            element.classList.remove("show-datalist");

                        } else {

                            //console.log('ddd');

                        }

                    } else {

                        element.classList.remove("show-datalist");

                    }

                });

            });

            /*menu*/

            var hasSubMenu = document.querySelectorAll(".has-dropmenu");

            hasSubMenu.forEach(element => {

                element.addEventListener('click', function (e) {

                    var menu = document.querySelectorAll(".item");

                    menu.forEach((e) => {

                        if (e.classList.contains("active") && element !== e) {

                            e.classList.remove("active");

                            // 變更active的icon
                            e.childNodes[0].childNodes[2].classList.remove("icon-13");
                            e.childNodes[0].childNodes[2].classList.add("icon-12");
                        }

                    });

                    if (element.classList.contains('active')) {

                        element.classList.remove('active');

                        // 變更active的icon
                        element.childNodes[0].childNodes[2].classList.remove("icon-13");
                        element.childNodes[0].childNodes[2].classList.add("icon-12");

                    } else {

                        element.classList.add('active');

                        // 變更active的icon
                        element.childNodes[0].childNodes[2].classList.remove("icon-12");
                        element.childNodes[0].childNodes[2].classList.add("icon-13");

                    }

                });

            });

            /*客製化下拉選單*/

            const dps = document.querySelectorAll("[data-dropmenu]");

            dps.forEach((element) => {

                var selectedValue;

                var trigger = element.querySelector(".dropmenu-value");

                var dropmenu = element.querySelector(".dropmenu");

                var options = element.querySelector("[data-value]");

                trigger.addEventListener("click", function (e) {

                    element.classList.add("active");

                });

                dropmenu.addEventListener("click", function (e) {

                    if (e.target.matches("[data-value]")) {

                        selectedValue = e.target.dataset.value;

                        trigger.textContent = selectedValue;

                        element.classList.remove("active");

                    }

                });

            });

            var currentModal;

            let currentLevel = 1;

            const modals = document.querySelectorAll('[data-modal]');

            const modalBtns = document.querySelectorAll('[data-openmodal]');

            modals.forEach(element => {

                var modalBody = element.querySelector('.modal-container');

                var modalClose = element.querySelectorAll('[data-close]');

                var modalsBack = element.querySelector('[data-back]');

                //console.log(modalClose);

                element.addEventListener('click', function (e) {



                    if (e.target == modalsBack) {

                        var showLayerClass = 'has-layer-' + (currentLevel);

                        currentLevel = currentLevel - 1;

                        element.classList.remove('active');

                        document.body.classList.remove(showLayerClass);

                    } else if (e.target == element || e.target.hasAttribute('data-close')) {

                        /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/



                        var showLayerClass = 'has-layer-' + (currentLevel);

                        if (typeof currentModal !== "undefined") {

                            currentModal.classList.remove('active');

                        } else { }

                        currentLevel = currentLevel - 1;

                        element.classList.remove('active', 'layer-1', 'layer-2', 'layer-3', 'layer-4', 'layer-5', 'layer-6');

                        document.body.classList.remove(showLayerClass);

                    }

                });

            });

        }, 1000)



        return () => clearTimeout(timeout)



    }, [])
    function openModalReview(e) {
        var id = "modal-review";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        var showLayerClass = "has-layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
        document.body.classList.add("has-modal", showLayerClass);
        e.preventDefault();
    }

    return (
        <>
            <header className="header">
                <a className="logo">
                    <i className="hlogo"></i>
                </a>
                <div className="fn-bar">
                    <div className="right ml-1" style={{ fontSize: '32px' }} >
                        <div className="right"><span className="fb-w">報名繳費系統管理平台</span></div>
                    </div>
                    <div className="left">
                        {/*<a href="" className="notice" onClick={ openModalReview}>*/}
                        {/*    <i className="icon icon-42"></i>*/}
                        {/*</a>*/}
                        <a href="">
                            <i className="icon icon-40"></i>
                            <span>{username}</span>
                        </a>
                        <a onClick={logout} className="logout">
                            <i className="icon icon-3"></i>
                            <span>登出</span>
                        </a>
                    </div>
                </div>
            </header>

            <div className="main">
                <nav className="nav">
                    <div className="nav-container">
                        {
                            menuArray.map(x =>
                                <MenuItem menuID={x.menuID} menuName={x.menuCpation} menuDUrl={x.funcUrl} subMenu={x.subMenu} key={x.menuID} />
                            )
                        }

                    </div>
                </nav>








                <div className="page-content">
                    {(() => {
                        if (path != null) {
                            switch (path.toLowerCase()) {
                                //全小寫
                                case 'carouselbase':            //首頁管理(廣告輪播)
                                    return <CarouselBase />;
                                case 'announcementbase':
                                    return <AnnouncementBase />;
                                case 'courseperiod':            //課程期別管理
                                    return <CoursePeriod />;
                                case 'coursetype':              //課程類別維護
                                    return <CourseType />;
                                case 'courseclasstype':              //課程班別維護
                                    return <CourseClassType />;
                                case 'coursebase':              //課程內容管理
                                    return <CourseBase />;
                                case 'traineelist':             //研習之友資料維護
                                    return <TraineeList />
                                case 'traineediscount':         //研習之友折扣管理
                                    return <TraineeDiscount />;
                                case 'spotsignup':              //現場報名
                                    return <SpotSignup />;
                                case 'spotpayrefund':           //現場繳費退費
                                    return <SpotPayRefund />;
                                case 'traineeidentity':         //研習之友身份證記
                                    return <TraineeIdentity />;
                                case 'refundlist':              //退費申請管理
                                    return <RefundList />;
                                case 'questionlist':            //問卷調查管理
                                    return <QuestionList />;
                                case 'questionmaintain':        //問卷維護
                                    return <QuestionMaintain />
                                case 'questionresult':          //問卷結果
                                    return <QuestionResult />
                                case 'pfmreport':               //業績報表
                                    return <PfmReport />;
                                case 'firreport':               //財務報表
                                    return <FirReport />;
                                case 'informbasic':             //發送通知設定
                                    return <InformBasic />;
                                case 'informschedule':          //通知排程
                                    return <InformSchedule />;
                                case 'informstatistics':        //通知發送統計
                                    return <InformStatistics />;
                                case 'informschedulemodify':    //基本通知編輯頁
                                    return <InformScheduleModify />;
                                case 'informmodify':            //一般通知編輯頁
                                    return <InformModify />
                                case 'signupsetting': //報名作業時間設定
                                    return <SignupSetting />
                                case 'courseprocess': //課程過程管理
                                    return <CourseProcess />
                                case 'coursecontent': //課程內容管理
                                    return <CourseContent />
                                case 'courseteachers': //師資管理
                                    return <CourseTeachers />;
                                case 'lottery': //電腦名單抽選
                                    return <Lottery />;
                                case 'signupreport':  //使用者統計報表
                                    return <SignUpReport />;
                                case 'saleordernew': //產品新增
                                    return null;
                                
                                default:
                                    return <div className="main"><div class="title-area"><div class="containerB full"><h3 class="fz-A mr-1">請點選左方功能列表</h3></div></div></div>
                            }                            
                        }
                        return <div className="main"><div class="title-area"><div class="containerB full"><h3 class="fz-A mr-1">請點選左方功能列表</h3></div></div></div>
                    })()}

                    <div
                        id="modal-review"
                        className="modal large"
                        data-modal
                        data-mid="0.1.1.1"
                    >
                        <div className="modal-container">
                            <div className="modal-header">
                                <h3 className="fz-B has-border">新增/ 管理使用人員帳號</h3>
                                <span className="close" data-close>
                                    <i className="icon icon-44"></i>
                                </span>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <h6 className="fz-D">產品新增/ 修改確認</h6>
                                    <div className="form-container inline">
                                        <ul className="list-table" style={{ width: "100%" }}>
                                            <li className="lt-row lt-th">
                                                <div className="flex-4">帳號</div>
                                                <div className="flex-4">姓名</div>
                                                <div className="flex-4">密碼</div>
                                                <div className="flex-5 fn"></div>
                                            </li>
                                            <li className="lt-row">
                                                <div className="flex-4">a</div>
                                                <div className="flex-4">b</div>
                                                <div className="flex-4">c</div>
                                                <div className="flex-5 fn">
                                                    <div className="fn-bar">
                                                        {/*<div className={`btn btn-small btn-border${userInvalid ? ' disabled' : ''}`} data-openmodal="modal-3" onClick={userInvalid ? undefined : openModal3}>*/}
                                                        {/*    <span>重置密碼</span>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="btn btn-icon" data-openmodal="modal-4" onClick={openModal4}>*/}
                                                        {/*    <i className={`icon icon-${userInvalid ? '36' : '35'}`}></i>*/}
                                                        {/*</div>*/}
                                                        {/*<div className={`btn btn-icon${userInvalid ? ' disabled' : ''}`} data-openmodal="modal-5" onClick={openModal5}>*/}
                                                        {/*    <i className="icon icon-37"></i>*/}
                                                        {/*</div>*/}d
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <footer className="footer copyright">
                        <span>2021 Digicentre Company Limited. All Rights Reserved.</span>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default main;
