import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    
    API_Get_CourseSchdData, //取得課程報名時間列表
    API_Put_CourseSchdData //刪除報名時間 && 編輯報名時間詳情
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import SignupDetail from "./SignupDetail"

function SignupSetting() {
    var history = useHistory();
    const [reGet, setReGet] = useState(false);

    useEffect(() => {
        getData();      
    }, []);

    useEffect(() => {
        if (reGet) {
            //內頁資料有刪除/修改 重抓
            getData();
        }
        setReGet(false);
    }, [reGet]);

    function getData() {
        API_Get_CourseSchdData().then((response) => response.data)
            .then((data) => {
                console.log(data);
                setDataList(data.result == null ? [{}] : data.result);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }

            })
    }
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
   
     
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([{}]);

    const [editcouSID, setEditcouSID] = useState("");
   
    function delData() {
        API_Put_CourseSchdData({
            CouSID: dataList[tmpIndex].CouSID,
            CouSDisplay: false,
            SchdTList: []
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    window.location.reload();
                } else {
                    openModalError("刪除", data.message);
                    alert(data.message);
                    return;
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
   
    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    function openModalEdit(tmpIndex) {
      
        if (tmpIndex.detail) {
            //console.log('新增');
            setEditcouSID(0);
        } else {
            //console.log('編輯');
            setEditcouSID(dataList[tmpIndex].CouSID);
        }

        var id = "modal-edit";
        var target = document.querySelector("#" + id );
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">報名作業時間設定</h3>
                            </div>
                        </div>
                        <div className="container full mt-1">
                            <div className="info-holder">
                                <div className="left">

                                </div>
                                <div className="right fc-1">
                                    <div
                                        className="btn btn-icon"
                                        onClick={openModalEdit}
                                    >
                                        <i className="icon icon-14 mr-1"></i>
                                    </div>

                                    <span className="fw-b" onClick={openModalEdit}>新增</span>
                                </div>
                            </div>

                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-2">名稱</div>
                                        <div className="flex-1">錄取模式</div>
                                        <div className="flex-2">公告開始日期時間</div>
                                        <div className="flex-1">報名場次限制</div>
                                        <div className="flex-3">報名期限</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                        <div className="flex-1">&ensp;刪除</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-2">
                                                {x.CouSName}
                                            </div>
                                            <div className="flex-1">
                                                {x.DistType}
                                            </div>
                                            <div className="flex-2">
                                                {x.CouSPublicDate}
                                            </div>
                                            <div className="flex-1">
                                                {x.CouSDistType == 'A' ? x.CouSLimitCnt  : '-'}
                                                
                                            </div>
                                            <div className="flex-3">
                                                {x.CouSTRegStartDate} ~ {x.CouSTRegEndDate}
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalEdit(i)}
                                                    >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div id="modal-edit" className="modal large" data-modal>
                    <SignupDetail editcouSID={editcouSID } setReGet={setReGet} />
                </div>

                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SignupSetting;
