import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_GET_GetAnnouncementList,
    API_POST_UpdateOpen,
    API_PUT_AnnouncementInfo,
    API_POST_AnnouncementInfo,
    API_POST_AnnouncementSortUp,
    API_POST_AnnouncementSortDown,
    API_DELETE_AnnouncementInfo,
    API_POST_AnnouncementList,
    API_HOST
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
/*import ClassicEditor from '@ckeditor/ckeditor5-build-classic';*/
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import MyUploadAdapter from '../MyUploadAdapter';
import Swal from 'sweetalert2'

function AnnouncementBase() {
    var history = useHistory();

    const [acntTypeID] = useState(localStorage.getItem("acntTypeID"));
    const [announcementArray, setAnnouncementArray] = useState([{}]);

    const [msgSub, setMsgSub] = useState();
    const [msgType, setMsgType] = useState();
    const [msgPushDate, setMsgPushDate] = useState();
    const [closeDate, setCloseDate] = useState();
    const [msgData, setMsgData] = useState();
    const [msgID, setMsgID] = useState();
    const [msgTypeList, setmsgTypeList] = useState([{}]);
    useEffect(() => {
        getData();
        //const timeout = setTimeout(() => {
        //    document.getElementById("menuitem-3").classList.add("active");

        //    // 變更active的icon
        //    const icon12 = document.getElementById("menuitem-3").childNodes[0].childNodes[2];
        //    icon12.classList.remove("icon-12");
        //    icon12.classList.add("icon-13");

        //    document.getElementById("submenuitem-5").classList.add("active");

        //}, 100)
        //return () => clearTimeout(timeout)
        
    }, []);

    function getData() {
        API_GET_GetAnnouncementList().then((response) => response.data)
            .then((data) => {
                console.log(data.result);

                data.result.map((item, i) => {
                    item.msgPushDate = item.msgPushDate.split('T')[0];
                    item.closeDate = item.closeDate.split('T')[0];
                });

                setAnnouncementArray(data.result);
            
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
        API_POST_AnnouncementList().then(res => res.data).then(data => {
            if (data.code == "0000") {
                setmsgTypeList(data.result);
            }
            else {
                setmsgTypeList([{ codeValue: "", codeText: "" }]);
                alert(data.message);
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401 || err.response.data.code == "1001") {
                localStorage.clear();
                history.push('/');
            }
        })
    }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();

    function delData() {
        //呼叫API
        //成功
        setAnnouncementArray(function (prev) {
            API_DELETE_AnnouncementInfo({
                MsgID: announcementArray[tmpIndex].msgID
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);

                    getData();
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })

            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    //function Add(e) {
    //    setDataList(function (prev) {
    //        return [...prev, { id:null,name:null,picture:null,url:null,isEdit:true}];
    //    });
    //}

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {

        if (tmpIndex.detail) {
            console.log("add");

            setMsgSub("");
            setMsgType("");
            setMsgPushDate("");
            setCloseDate("");
            setMsgData("");
            setMsgID("");
        } else {
            console.log("edit");

            //console.log(tmpIndex);

            setMsgSub(announcementArray[tmpIndex].msgSub);
            setMsgType(announcementArray[tmpIndex].msgType);
            setMsgPushDate(announcementArray[tmpIndex].msgPushDate.split("T")[0]);
            setCloseDate(announcementArray[tmpIndex].closeDate.split("T")[0]);
            setMsgData(announcementArray[tmpIndex].msgData);
            setMsgID(announcementArray[tmpIndex].msgID);

        }

        setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function isOpenChange(idx, isOpen) {

        setAnnouncementArray(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.isOpen = !isOpen;
                }
                return item;
            });
        });

        console.log(announcementArray[idx].msgID);

        API_POST_UpdateOpen({
            MsgID: announcementArray[idx].msgID,
            IsOpen: !isOpen
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function sortUp(idx) {
        API_POST_AnnouncementSortUp({
            MsgID: announcementArray[idx].msgID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);

                if (data.code == "0000") {//成功
                    getData();
                } else {
                  
                    Swal.fire({
                        icon: 'error',
                        title: '排序失敗',
                        text: data.message,
                        showConfirmButton: true,
                        showCloseButton: true
                    })

                    getData();
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function sortDown(idx) {
        API_POST_AnnouncementSortDown({
            MsgID: announcementArray[idx].msgID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);

                if (data.code == "0000") {//成功
                    getData();
                } else {
                    
                    Swal.fire({
                        icon: 'error',
                        title: '排序失敗',
                        text: data.message,
                        showConfirmButton: true,
                        showCloseButton: true
                    })
                    getData();
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == '1001') {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function upData() {
        console.log(msgData);

        console.log(msgID);

        var go = true;
        if (msgSub == '') {
            go = false;
            alert("請填寫公告主題");
        } else if (msgType == '') {
            go = false;
            alert("請選擇公告類別");
        } else if (msgPushDate == '') {
            go = false;
            alert("請選擇公告日期(開始時間)");
        } else if (closeDate == '') {
            go = false;
            alert("請選擇公告日期(結束時間)");
        } else if (msgData == '') {
            go = false;
            alert("請填寫公告內容");
        }

        if (go) {
            if (msgID != "") {
                //編輯
                console.log("put");

                API_PUT_AnnouncementInfo({
                    MsgID: msgID,
                    MsgType: msgType,
                    MsgSub: msgSub,
                    MsgData: msgData,
                    MsgPushDate: msgPushDate,
                    CloseDate: closeDate
                }).then((response) => response.data)
                    .then((data) => {
                        console.log(data);

                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })

            } else {
                //新增
                console.log("post");

                API_POST_AnnouncementInfo({
                    MsgType: msgType,
                    MsgSub: msgSub,
                    MsgData: msgData,
                    MsgPushDate: msgPushDate,
                    CloseDate: closeDate
                }).then((response) => response.data)
                    .then((data) => {
                        console.log(data);

                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
            }

            window.location.reload();
        }
    }

    //定义上传适配器
    function MyCustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            // 第二个参数设置上传图片的地址
            console.log(loader.file.name);
            var reader = new FileReader();

            var res = new MyUploadAdapter(loader, API_HOST +'/Announcement/filetest');
            console.log(res);
            return res;
        };
    }


    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">公告管理
                                </h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="info-holder">
                                <div className="left">

                                </div>
                                <div className="right fc-1">
                                    <div
                                        className="btn btn-icon"
                                        onClick={openModalEdit} >
                                        <i className="icon icon-14" ></i>
                                    </div>
                                    <span>新增</span>
                                </div>

                            </div>
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-4">廣告標題</div>
                                        <div className="flex-1">開始日期</div>
                                        <div className="flex-1">結束日期</div>
                                        <div className="flex-1">&ensp; 類別</div>
                                        <div className="flex-1">顯示</div>
                                        <div className="flex-1">&ensp; 編輯</div>
                                        <div className="flex-1">&ensp; 刪除</div>
                                        <div className="flex-1">&ensp; 排序</div>
                                    </li>
                                    {announcementArray.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-4">
                                                {x.msgSub}
                                            </div>
                                            <div className="flex-1">
                                                {x.msgPushDate}
                                            </div>
                                            <div className="flex-1">
                                                {x.closeDate}
                                            </div>
                                            <div className="flex-1">
                                                {msgTypeList.filter(f => f.codeValue == x.msgType).length > 0 ? msgTypeList.filter(f => f.codeValue == x.msgType)[0].codeText : ""}
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <input type="checkbox" key={i} id={`isOpen${i}`} name="isOpen" value={i} checked={x.isOpen ? "checked" : ""} onChange={() => isOpenChange(i, x.isOpen)} />
                                                    <label htmlFor={`isOpen${i}`}></label>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div className="btn btn-icon" onClick={(e) => openModalEdit(i)} >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => sortUp(i)}
                                                    >
                                                        <i className="icon icon-gray icon-26"></i>
                                                    </div>
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => sortDown(i)}
                                                    >
                                                        <i className="icon icon-gray icon-25"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="modal-edit" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">新增/編輯公告</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-item ">
                                    <div className="field"> 公告主題</div>
                                    <div className="value">
                                        <input type="text" value={msgSub} onChange={(e) => { setMsgSub(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="form-item ">
                                    <div className="field"> 公告類別</div>
                                    <div className="value multi-inline">
                                        {
                                            msgTypeList.map((x) => (
                                                <>
                                                    <input type="radio" id={"msgType"+x.codeValue } name="msgType" value={x.codeValue} checked={msgType == x.codeValue ? 'checked' : ''} onChange={(e) => { setMsgType(e.target.value), console.log(msgType)}}/>
                                                    <label htmlFor={"msgType" + x.codeValue} className="mr-1">{x.codeText}</label>
                                                </>
                                                ))
                                        }
                                        {/*<input type="radio" id="msgType1" name="msgType" value="1" checked={msgType == 1 ? 'checked' : ''} onChange={(e) => setMsgType(e.target.value)}/>*/}
                                        {/*<label htmlFor="msgType1" className="mr-1">一般公告</label>*/}
                                        {/*<input type="radio" id="msgType2" name="msgType" value="2" checked={msgType == 2 ? 'checked' : ''} onChange={(e) => setMsgType(e.target.value)} />*/}
                                        {/*<label htmlFor="msgType2" className="mr-1">課程表</label>*/}
                                        {/*<input type="radio" id="msgType3" name="msgType" value="3" checked={msgType == 3 ? 'checked' : ''} onChange={(e) => setMsgType(e.target.value)} />*/}
                                        {/*<label htmlFor="msgType3" className="mr-1">常見問題</label>*/}
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field"> 公告日期</div>
                                    <div className="value multi-inline">
                                        <input type="date" value={msgPushDate} onChange={(e) => setMsgPushDate(e.target.value)} /> 至 <input type="date" value={closeDate} onChange={(e) => setCloseDate(e.target.value)} />
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">公告內容</div>
                                    <div className="value" style={{ display: "block" }}>
                                        <div style={{ border: "1px solid" }}>
                                            <div id="toolbar-container"></div>
                                            <CKEditor
                                                editor={DecoupledEditor}
                                                data={msgData}
                                                config={{
                                                    language: 'zh-cn',
                                                    extraPlugins: [MyCustomUploadAdapterPlugin],
                                                }}

                                                onReady={editor => {
                                                    //console.log(editor);
                                                    const toolbarContainer = document.querySelector('#toolbar-container');

                                                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setMsgData(data);
                                                    //console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br />
                            <br />
                            <div className="btn-holder text-center">
                                <a className="btn" onClick={upData}>
                                    <span>確認</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>放棄</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-4"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AnnouncementBase;
