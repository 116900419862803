import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Get_TeacherBaseData, API_POST_AddTeacherData, API_Put_TeacherBaseData } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
function CourseTeachers() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([{}]);
    function getData() {


        API_Get_TeacherBaseData()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    console.log(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                //if (err.response.status === 401 || err.response.data.code == "1001") {
                //    localStorage.clear();
                //    history.push("/");
                //}
            });
    }
    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].teacherID == null) { //按下的是新增
            //console.log(dataList);
            const dt = {
                teacherName: dataList[index].teacherName,
                teacherExp: dataList[index].teacherExp,
                teacherEducation: dataList[index].teacherEducation,
                teacherMetier: dataList[index].teacherMetier
            }
            //console.log(JSON.stringify(dt));
            //呼叫API新增
            API_POST_AddTeacherData(dt).then((response) => response.data)
                .then((data) => {
                    //console.log(data);
                    if (data.code == "0000") {//成功
                        getData();
                    } else {
                        alert(data.message);
                    }
                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        } else if (type == 3) {
            getData();
            
        } else {
           
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            const dt = {
                                TeacherID: item.teacherID,
                                teacherName: item.teacherName,
                                teacherExp: item.teacherExp,
                                teacherEducation: item.teacherEducation,
                                teacherMetier: item.teacherMetier
                            }
                            
                            API_Put_TeacherBaseData(dt).then((response) => response.data)
                                .then((data) => {
                                    if (!checkAuthToken(data)) {
                                        history.push("/");
                                    }
                                    console.log(data);
                                    if (data.code == "0000") {//成功

                                        getData();

                                    } else {
                                        // setMsgStr(data.message);
                                        alert(data.message);
                                        getData();
                                    }
                                })
                                .catch(err => {
                                    console.log(err);

                                })
                        }
                        item.isEdit = type == 1 ? true : false;

                    }
                    return item;
                });
            });
        }

    }
    function delData() {
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
    }
    function Add(e) {
        setDataList(function (prev) {
            return [...prev, {
                teacherID: null,
                teacherName: null,
                teacherExp: null,
                teacherEducation: null,
                teacherMetier: null,
                isEdit: true
            }];
        });
    }

    function nameChg(e, idx, vName) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    switch (vName) {
                        case "teacherName":
                            item.teacherName = e.target.value;
                            break;
                        case "teacherExp":
                            item.teacherExp = e.target.value;
                            break;
                        case "teacherEducation":
                            item.teacherEducation = e.target.value;
                            break;
                        case "teacherMetier":
                            item.teacherMetier = e.target.value;
                            break;
                    }

                }
                return item;
            });
        });
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">師資管理</h3>
                            </div>
                        </div>
                        <div className="container full mt-1">
                            <div className="info-holder">
                                <div className="left">

                                </div>
                                <div className="right fc-1">
                                    <div className="btn btn-icon" onClick={Add}>
                                        <i className="icon icon-14 mr-1"></i>
                                    </div>
                                    <span className="fw-b">新增教師</span>
                                </div>
                            </div>

                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-1">姓名</div>
                                        <div className="flex-1">學歷</div>
                                        <div className="flex-1">經歷</div>
                                        <div className="flex-1">專長</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.teacherName} onChange={(e) => nameChg(e, i, "teacherName")} />                                                
                                                ) : (
                                                    x.teacherName
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    //<input type="text" value={x.teacherExp} onChange={(e) => nameChg(e, i, "teacherExp")} />
                                                    < textarea value={x.teacherEducation} onChange={(e) => nameChg(e, i, "teacherEducation")}></textarea>
                                                ) : (
                                                        x.teacherEducation
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    //<input type="text" value={x.teacherEducation} onChange={(e) => nameChg(e, i, "teacherEducation")} />
                                                    <textarea value={x.teacherExp} onChange={(e) => nameChg(e, i, "teacherExp")}> </textarea>
                                                ) : (
                                                        x.teacherExp
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    //<input type="text" value={x.teacherMetier} onChange={(e) => nameChg(e, i, "teacherMetier")} />
                                                    <textarea value={x.teacherMetier} onChange={(e) => nameChg(e, i, "teacherMetier")}></textarea>
                                                ) : (
                                                    x.teacherMetier
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    {
                                                        x.teacherID == null ?
                                                            <div className="btn btn-icon">
                                                                <i className="icon icon-orange icon-16" onClick={(e) => Edit(2, i)}></i>
                                                                <i className="icon icon-gray icon-38" onClick={(e) => Edit(3, i)}></i>
                                                            </div>

                                                            :
                                                            x.isEdit == true ?

                                                                <div className="btn btn-icon" >
                                                                    <i className="icon icon-orange icon-16" onClick={(e) => Edit(1, i)}></i>
                                                                    <i className="icon icon-gray icon-38" onClick={(e) => Edit(3, i)}></i>                                                                 
                                                                </div>
                                                                :
                                                                <div className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                                    <i className="icon icon-24"></i>
                                                                </div>
                                                    }

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CourseTeachers;
