import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_GET_DiscountHome, API_GET_DiscountInfo, API_PUT_DiscountInfo } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { doc } from "prettier";
function TraineeIdentity() {
    var history = useHistory();
    const [UserIDNo, setUserIDNo] = useState('');
    const [UserName, setUserName] = useState('');
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [modalTit, setModalTit] = useState("刪除");
    const [files, setFile] = useState([]);
    const [PrevImg, setPrevImg] = useState('');
    const LimitSize = 5120000;
    useEffect(() => {

        getData();

    }, []);

    function getData()
    {
        API_GET_DiscountHome().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function handleChange(event, USpecID) {

        if (event.target.files.length >0) {
            console.log(files);
            var nextFile = files.filter((f) => f.USpecID != USpecID);
            
            if (event.target.files[0].size > LimitSize) {
                openModalError("錯誤", "超過檔案大小");
                return;
            }
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                nextFile.push({ USpecID: USpecID, File: reader.result, Name: event.target.files[0].name });
                //更新檔案名稱
                var label = document.getElementById('fileName');
                label.innerText = "已選擇檔案:";
                //更新檔案array
                setFile(nextFile);
                setUserSpecList(function (prev) {
                    return prev.map((item) => {
                        if (item.USpecID == USpecID) {
                            console.log(item);
                            item.ATTBase64 = reader.result;
                            item.ATTFPath = event.target.files[0].name;
                        }
                        return item;
                    });
                });
            }

        }
    }
    const [tmpIndex, setTmpIndex] = useState();
    const [SpecList, setSpecList] = useState([]);
    const [UserSpecList, setUserSpecList] = useState([]);
    function Search() {
        if (UserIDNo == "" && UserName == "") {
            openModalError("錯誤", "請輸入身分證或姓名。");
            return;
        }
        API_GET_DiscountInfo(
            {
                UserIDNo: UserIDNo,
                UserName: null
            }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
               // console.log(data.result.userSpecList);
               //console.log(data.result.userName);
                setUserName(data.result.userName);
                if (data.code = "0000") {
                    var newFile = [];
                    //setSpecList(data.result.specList);
                    data.result.userSpecList.forEach((f) => {
                        if (f.ATTFPath != null) {
                            
                            newFile.push({ USpecID: f.USpecID, File: f.ATTFPath, Name: "" });
                            
                        }
                    });
                    setFile(newFile);
                    console.log(newFile);
                    var res = data.result.userSpecList;
                    res.forEach(f => {
                        if (f.Checked == 1) {
                            f.Checked = true;
                        }
                        else {
                            f.Checked = false;
                        }
                    })
                    setUserSpecList(res);
                   
                    
                }
                else {
                    openModalError("錯誤", data.message);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function Ok() {
        if (UserSpecList.length <= 0) {
            return;
        }
        API_PUT_DiscountInfo( UserSpecList ).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code = "0000") {
                    Search();
                    openModalCheck()
                }
                else {
                    openModalError("錯誤", data.message);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
                else {
                    Search();
                    openModalError("錯誤", data.message);
                }
            });
    }
    function setData(idx, e, col) {
        const nextUserSpecList = UserSpecList.map((x, i) => {
            if (i === idx) {
                if (col == "USpecEffDate") {
                    x.USpecEffDate = e.target.value == "" ? null : e.target.value ;
                }
                if (col == "USpecRMK") {
                    x.USpecRMK = e.target.value;
                }
                return x;
            } else {
                // The rest haven't changed
                return x;
            }
        });
        setUserSpecList(nextUserSpecList);
    }
    function preview(File) {
        setPrevImg(File);
        var id = "modal-Image";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalCheck() {
        setTmpIndex(tmpIndex);
        var id = "modal-ok";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function SpecChk(idx) {
        console.log(idx);
        setUserSpecList(function (prev) {
            return prev.map((item, i) => {
                if (idx == i) {
                    if (item.Checked) {
                        item.Checked = false;
                        item.ATTBase64 = null;
                        item.ATTFPath = null;
                        var nextFile = files.filter((f) => f.USpecID != item.USpecID);
                        setFile(nextFile);
    
                    }
                    else {
                        item.Checked = true;
                        item.USpecIDNo = UserIDNo;
                    }
                }
                return item;
            })
        })
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">研習之友身分登記</h3>
                            </div>
                        </div>
                        
                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item multi-inline">
                                    <div>身分/居留證號：</div>
                                    <input type="text" value={UserIDNo} onChange={(e) => { setUserIDNo(e.target.value) }} style={{ width: '150px' }} />
                                    {/*<input type="text" value={UserName} onChange={(e) => { setUserName(e.target.value) }} style={{ width: '150px' }} className="mr-1" />*/}

                                    <a className="btn btn-border btn-middle" onClick={Search}>
                                        <span>查詢</span>
                                    </a>
                                </div>
                               
                            </div>
                        </div>

                        {UserSpecList.length == 0 ? null :
                            <div>
                               
                              
                                <div className="container full mt-1">

                                    <div className="modal-body">
                                        <div className="ml-3">姓名：{UserName}</div>
                                        <ul className="list-table">

                                            <li className="lt-row lt-th">

                                                <div className="flex-0">代號</div>
                                                <div className="flex-1">身分及折扣資格</div>
                                                <div className="flex-1">折扣比例 %</div>
                                                <div className="flex-0"></div>
                                                <div className="flex-1">有效期限</div>
                                                <div className="flex-4">附註</div>
                                                <div className="flex-1">附件</div>
                                            </li>
                                            {UserSpecList.map((x, i) => (
                                                <li className="lt-row">
                                                  
                                                    <div className="flex-0">
                                                        {x.SpecNo}
                                                    </div>
                                                    <div className="flex-1">
                                                        {x.SpecName}
                                                    </div>
                                                    <div className="flex-1">
                                                        {((x.SpecDiscMoney != null ? x.SpecDiscMoney + ((x.SpecDiscType == "P") ? "%" : "元") : ""))}
                                                    </div>
                                                    <div className="flex-0">
                                                        <input type="checkbox" id={"Spec"} id={"c" + x.SpecNo } name="f1" value="f1" onClick={() => { SpecChk(i) }} checked={x.Checked ? "checked" : ""} /> {/*checked={x.USpecID != null ? "checked" : ""}*/}
                                                        <label htmlFor={"c" + x.SpecNo}></label>
                                                    </div>
                                                    <div className="flex-1">
                                                        <input type="date"
                                                            value={x.USpecEffDate}
                                                            onChange={(e) => setData(i, e, "USpecEffDate")}
                                                        />
                                                    </div>
                                                    <div className="flex-4">
                                                        <input type="text"
                                                            id="Rmk"
                                                            value={x.USpecRMK}
                                                            onChange={(e) => setData(i, e, "USpecRMK")}
                                                        />
                                                    </div>
                                                    {
                                                        x.Checked ? <div className="flex-1" style={{ alignItems: "center" }}>
                                                            <label for={"file_" + x.USpecID}><i className="icon icon-27" style={{ cursor: 'pointer' }}></i> </label>
                                                            <input type="file" id={"file_" + x.USpecID} name="file1" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleChange(e, x.USpecID)} />
                                                            <label for={"file_" + x.USpecID} style={{ cursor: "pointer" }}>上傳圖檔</label>
                                                        </div>
                                                            : <div className="flex-1"></div>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>

                                <div className="container full mt-1">
                                    <div className="form-item">
                                        <div className="value ">

                                            <div className="value ">
                                                <label id="fileName">已選擇檔案:</label>
                                            </div>
                                        </div>
                                        <div className="form-item" id="imgDiv">
                                            {files.map((x, i) => (
                                                <div className="form-border" onClick={(e) => preview(x.File)}><img style={{ maxHeight: "200px", maxWidth: "200px" }} src={x.File}></img></div>
                                            ))}
                                        </div>
                                    </div>
                                    <br />
                                    <div className="btn-holder mt-1">
                                        {/*<a className="btn" onClick="">
                                    <span>確定</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>*/}
                                        <div
                                            className="btn btn-middle btn-border" onClick={Ok}
                                        >
                                            <span>確定</span>
                                        </div>
                                        <div
                                            className="btn btn-middle btn-border" onClick={Search}
                                        >
                                            <span>取消</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div id="modal-Image" className="modal large" data-modal>
                    <div className="modal-container preview-modal">
                        <div className="modal-body">
                            <div className="preview-closebtn">
                                <div className="btn btn-middle btn-border" data-close >
                                    <span>X</span>
                                </div>
                            </div>
                            <div className="container full mt-1 center">
                                <div className="form-item preview-form">
                                    <div className="value">
                                        <div className="btn-holder mt-1">
                                            
                                        </div>
                                    </div>
                                    <div className="fields preview-fields">
                                        
                                        <div className="form-border ">
                                            <img className="preview-img" src={PrevImg} img />
                                        </div>
                                        <br />
                                    </div>
                                    
                                </div>
                                
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>

                        </div>
                        <a className="btn btn-border btn-middle btn-holder " data-close  >
                            <span>確定</span>
                        </a>
                    </div>
                </div>
                <div id="modal-ok" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-16"></i>
                            </div>
                            <div className="text-holder">成功</div>
                            <div className="btn-holder">
                                <a className="btn" data-close >
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TraineeIdentity;
