import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_Get_DropDown,
    API_Get_CourseBaseData,
    API_GetStudyInfo,
    API_CourseDtail,
    API_POST_BackCourseReg
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { OpenModal, CloseModal } from "../../global/ToolUtil";

function SpotSignup() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);
   
    function getData() {
        API_Get_CourseBaseData({
            CouPID: DropDownCouPID,
            CouTypeCID1: DropDownCouTypeCID1,
            CouTypeID: DropDownCouTypeID,
            CouName: DropDownCouName
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    alert(data.message);
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    //console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);

                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [modalTit, setModalTit] = useState("刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [courseTypeCArrayList, setcourseTypeCArrayList] = useState([{}]);//類別清單
    const [courseTypeArrayList, setcourseTypeArrayList] = useState([{}]);//班別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [DropDownCouTypeID, setDropDownCouTypeID] = useState("");//類別下拉
    const [DropDownCouTypeCID1, setDropDownCouTypeCID1] = useState("");//班別下拉
    const [DropDownCouName, setDropDownCouName] = useState("");//課程名稱

    const [chkUser, setChkUser] = useState(false); //檢查是否為研習之友
    const [UserIDSearch, setUserIDSearch] = useState(''); //身分 / 居留證號 查詢
    const [UserIDNo, setUserIDNo] = useState(''); //身分 / 居留證號
    const [UserName, setUserName] = useState(); //姓名
    const [UserPhone, setUserPhone] = useState();//手機號
    const [UserSex, setUserSex] = useState(); //姓別
    const [UserEmail, setUserEmail] = useState(); //電子信箱
    const [BirDay, setBirDay] = useState('1990-01'); //出生年月
    const [UserCity, setUserCity] = useState(''); //居住地區-城市
    const [UserArea, setUserArea] = useState(''); //居住地區-地區
    const [UserContact, setUserContact] = useState(); //緊急聯絡人姓名
    const [UserContactPhone, setUserContactPhone] = useState(); //緊急聯絡人手機號
    const [UserIsForeig, setUserIsForeig] = useState();//是否為外籍生
    const [CourseRegArrayList, setCourseRegArrayList] = useState([{}]);//特殊身份清單

    const [DeptName, setDeptName] = useState("");//開課單位
    const [CouPName, setCouPName] = useState("");//期別
    const [CouName, setCouName] = useState(""); //課程名稱
    const [CouNo, setCouNo] = useState("");//課程代號
    const [CouID, setCouID] = useState("");
    const [CouStartDate, setCouStartDate] = useState("");//開課日期
    const [CouCloseDate, setCouCloseDate] = useState("");//結束日期
    const [CouStartTime, setCouStartTime] = useState("");//上課開始時間
    const [CouCloseTime, setCouCloseTime] = useState("");//上課結束時間

    const [spec, setSpec] = useState("");

    const [ErrorMsgPhone, setErrorPhone] = useState(false);
    const [ErrorMsgCPhone, setErrorCPhone] = useState(false);
    const [ErrorMsgEmail, setErrorEmail] = useState(false);

    const [dataList, setDataList] = useState([]);

    const [AreaList, setAreaList] = useState([{}]);

    const [AreaNo, setAreaNo] = useState('');
    const [CityNo, setCityNo] = useState('');

    function setArea(CityNo) {
     /*   setUserArea(CityNo);*/
        console.log(CityNo);
        if (CityNo == "1") {
            setAreaList(
                [
                    { Value: 1, Text: "中區" },
                    { Value: 2, Text: "東區" },
                    { Value: 3, Text: "南區" },
                    { Value: 4, Text: "西區" },
                    { Value: 5, Text: "北區" },
                    { Value: 6, Text: "北屯區" },
                    { Value: 7, Text: "西屯區" },
                    { Value: 8, Text: "南屯區" },
                    { Value: 9, Text: "太平區" },
                    { Value: 10, Text: "大里區" },
                    { Value: 11, Text: "霧峰區" },
                    { Value: 12, Text: "烏日區" },
                    { Value: 13, Text: "豐原區" },
                    { Value: 14, Text: "后里區" },
                    { Value: 15, Text: "石岡區" },
                    { Value: 16, Text: "東勢區" },
                    { Value: 17, Text: "和平區" },
                    { Value: 18, Text: "新社區" },
                    { Value: 19, Text: "潭子區" },
                    { Value: 20, Text: "大雅區" },
                    { Value: 21, Text: "神岡區" },
                    { Value: 22, Text: "大肚區" },
                    { Value: 23, Text: "沙鹿區" },
                    { Value: 24, Text: "龍井區" },
                    { Value: 25, Text: "梧棲區" },
                    { Value: 26, Text: "清水區" },
                    { Value: 27, Text: "大甲區" },
                    { Value: 28, Text: "外埔區" },
                    { Value: 29, Text: "大安區" }
                ]
            );

        }
        else {
            setAreaList([{ Value: 1, Text: "其他" }]);
        }


    }

    //onChange的時候執行
    useEffect(() => {

        setArea(CityNo);

    }, [CityNo]);

    function formRequired() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        var regex = /^[0][0-9]{9}$/;
        var regexM = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (regex.test(UserPhone) == false) {
            setErrorPhone(true);
            result = false;
        } else {
            setErrorPhone(false);
        }
        if (regex.test(UserContactPhone) == false) {
            setErrorCPhone(true);
            result = false;
        } else {
            setErrorCPhone(false);
        }
        if (regexM.test(UserEmail) == false) {
            setErrorEmail(true);
            result = false;
        } else {
            setErrorEmail(false);
        }
        return result;

    }
    function openModalSuccess(title) {
        setModalTit(title);

        var id = "modal-successs";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {
        setTmpIndex(tmpIndex);
        setCouID(dataList[tmpIndex].CouID);

        setCouNo(`${dataList[tmpIndex].CouNo}`);
        setCouName(`${dataList[tmpIndex].CouName}`);
        setCouPName(`${dataList[tmpIndex].CouPName}`);

        setChkUser(false);
        setUserIDSearch("");
        setUserIDNo("");
        setUserName("");
        //setUserCity("");
        //setUserArea("");
        setBirDay("");
        setUserPhone("");
        setUserSex("");
        setUserEmail("");
        setUserContact("");
        setUserContactPhone("");
        setUserIsForeig(false);   
        setCityNo("");
        setArea("");

        API_CourseDtail({
            CouID: dataList[tmpIndex].CouID
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setDeptName(data.result.DeptName);
                    
                    setCouName(data.result.CouName);
                    setCouNo(data.result.CouNo);
                    setCouStartDate(data.result.CouStartDate);
                    setCouCloseDate(data.result.CouCloseDate);
                    setCouStartTime(data.result.CouStartTime);
                    setCouCloseTime(data.result.CouCloseTime);

                    setCourseRegArrayList(data.result.SpecList);

                    const rspelist = document.querySelectorAll("input[type=checkbox]");
                    rspelist.forEach((element) => {

                        element.checked = false;
                    });

                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
     //查詢研習之友
    function Search() {
        if (UserIDSearch == "") {
            setModalTit("查詢");
            setModalStr("請輸入身分證。");
            openModalError();
            return;
        }
        API_GetStudyInfo(
            {
                UserIDNo: UserIDSearch
            }).then((response) => response.data)
            .then((data) => {
                
                if (data.code = "0000") {
                    console.log(data);

                    var bir = data.result[0].birDay.slice(0, 7);
                    setChkUser(true);
                    setUserIDNo(UserIDSearch);
                    setUserName(data.result[0].userName);
                    //setUserCity(data.result[0].cityNo);
                    //setUserArea(data.result[0].areaNo);
                    setArea(data.result[0].cityNo);

                    setCityNo(data.result[0].cityNo); //市 台中市          
                    setAreaNo(data.result[0].areaNo); //區 北區

                    setBirDay(bir);
                    setUserPhone(data.result[0].userPhone);
                    setUserSex(data.result[0].userSex);
                    setUserEmail(data.result[0].email);
                    setUserContact(data.result[0].contact);
                    setUserContactPhone(data.result[0].contactPhone);
                    /* setUserIsForeig(data.result[0].isForeig == "0" ? false : true);  */
                    setUserIsForeig(data.result[0].isForeig == false ? "0" : "1");  
                }
                else {
                    openModalError("錯誤", data.message);
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //確認報名
    function UpData() {

        let spe = "";
        const rspelist = document.querySelectorAll("input[type=checkbox]");
        rspelist.forEach((element) => {
           
            if (element.checked == true) {
                spe = spe + element.value;
                spe = spe + ",";
                //console.log(spe);
            } 
        });
        if (spe != "") {
            //console.log(UserName);
         /*   console.log(spe.substring(0, spe.length - 1));*/
            setSpec(spe.substring(0, spe.length - 1));
            spe = spe.substring(0, spe.length - 1);
        } else {
           spe ="";

        }
      

        //驗証必填欄位
        var result = formRequired();
        if (result) {
            API_POST_BackCourseReg({
                UserIDNo: UserIDNo,
                UserName: UserName,
                BirDay: BirDay+"-01",
                UserSex: UserSex,
                IsForeig: UserIsForeig == "0" ? false : true,
                CityNo: CityNo,
                AreaNo: AreaNo,
                UserPhone: UserPhone,
                Email: UserEmail,
                Contact: UserContact,
                ContactPhone: UserContactPhone,
                Spec: spe,
                CouID: CouID,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        CloseModal("modal-edit");
                        getData();
                        openModalSuccess("報名", data.message);
                         //window.location.reload();
                    } else {
                        openModalError("報名", data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })

        }
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">現場報名</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item twenty">
                                    <div className="top-table">期別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => { setDropDownCouPID(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">類別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeCID1} onChange={(e) => { setDropDownCouTypeCID1(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {courseTypeCArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">班別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeID} onChange={(e) => { setDropDownCouTypeID(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {courseTypeArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">課程名稱</div>
                                    <div className="top-value">
                                        <input type="text" value={DropDownCouName} onChange={(e) => { setDropDownCouName(e.target.value) }} />
                                    </div>
                                </div>
                                <a className="btn btn-border btn-middle" onClick={getData}>
                                    <span>查詢</span>
                                </a>

                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">班別序號</div>
                                        <div className="flex-1">課程名稱</div>
                                        <div className="flex-1">課程期別</div>
                                        <div className="flex-1">類別</div>
                                        <div className="flex-1">班別</div>
                                        <div className="flex-1">教師</div>
                                        <div className="flex-1">開課日期</div>
                                        <div className="flex-1">報名 / 名額</div>
                                        <div className="flex-1">報名</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-1">{x.CouNo}</div>
                                            <div className="flex-1">{x.CouName}</div>
                                            <div className="flex-1">{x.CouPName}</div>
                                            <div className="flex-1">{x.Kind}</div>
                                            <div className="flex-1">{x.ClassType}</div>
                                            <div className="flex-1">{x.CouTeacherName}</div>
                                            <div className="flex-1">{x.CouStartDate}</div>
                                            <div className="flex-1">
                                                {
                                                    x.CouAmountT == null ?
                                                        (
                                                            0 + " / " + x.CouAmount
                                                        )
                                                        :
                                                        (
                                                            x.CouAmountT + " / " + x.CouAmount
                                                        )
                                                }
                                            </div>

                                            <div className="flex-1">
                                                <div className="btn btn-small btn-border" onClick={(e) => openModalEdit(i)} >
                                                    <span>報名</span>
                                                </div>
                                            </div>
                                            
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div id="modal-edit" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">現場報名</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-item">
                                    <div className="field">身分/居留證號</div>
                                    <div className="value multi-inline">
                                        <input type="text" value={UserIDSearch} onChange={(e) => { setUserIDSearch(e.target.value) }} placeholder="請填寫身分/居留證號" />
                                       

                                        <a className="btn btn-border btn-middle" onClick={Search}>
                                            <span>查詢</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="form-item" style={{ fontSize: '14px' }}>
                                    <span>如果報名者是研習之友學員或曾經報名過本市文化局下單位的課程，請輸入身分證號/居留證號後點選<span style={{ color: 'red' }}>查詢</span>，系統將自動帶入報名者的基本資料。</span>
                                </div>

                                <div className="form-item">
                                    <h3 className="mr-2">填寫基本資料</h3>
                                </div>

                                <div className="form-item">
                                    <div className="field">身分/居留證號</div>
                                    <div className="value">
                                        <input type="text" value={UserIDNo} disabled={chkUser == "" ? false : true} onChange={(e) => { setUserIDNo(e.target.value) }} placeholder="身分/居留證號" data-required />
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">外籍人士</div>
                                    <div className="value">
                                        <select name="sexType" value={UserIsForeig} disabled={chkUser == "" ? false : true} onChange={(e) => { setUserIsForeig(e.target.value) }} data-required>
                                            <option value="">請選擇</option>
                                            <option value="1">是</option>
                                            <option value="0">否</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">姓名</div>
                                    <div className="value">
                                        <input type="text" value={UserName} onChange={(e) => setUserName(e.target.value)} placeholder="中文姓名" data-required />
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">出生年月</div>
                                    <div className="value multi-inline">
                                        <input type="month" value={BirDay} onChange={(e) => setBirDay(e.target.value)} data-required/>
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">性別</div>
                                    <div className="value">
                                        <select name="sexType" value={UserSex} onChange={(e) => { setUserSex(e.target.value) }} data-required>
                                            <option value="">請選擇</option>
                                            <option value="M">男</option>
                                            <option value="F">女</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">居住地</div>
                                    <div className="value multi-inline">
                                       
                                        <select name="CityNo" id="CityNo" tilte="請選擇縣市" value={CityNo} onChange={e => { setCityNo(e.target.value), setAreaNo("") }} data-required>
                                            <option value="">請選擇</option>
                                            <option value="1">臺中市</option>
                                            <option value="2">其他</option>
                                        </select>

                                        <select name="AreaNo" id="AreaNo" tilte="請選擇地區" value={AreaNo} onChange={e => { setAreaNo(e.target.value) }} data-required>
                                            <option value="" selected disabled>請選擇</option>
                                            {
                                                AreaList.map((x) => (
                                                    <option value={x.Value}>{x.Text}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                           
                                <div className="form-item" >
                                    <div className="field">手機號</div>
                                    <div className="value">
                                        <input type="text" value={UserPhone} onChange={(e) => setUserPhone(e.target.value)} placeholder="手機號" data-required />
                                    </div>
                                    <span className={`form-item must ${ErrorMsgPhone ? "show-help" : ""}`} style={{ paddingLeft: '140px'}}>
                                        <span className="help-word" style={{ paddingLeft: '10px' }}>  請填入正確、有效的「手機號碼」(09開頭共10碼阿拉伯數字)</span>
                                    </span>
                                </div>

                                <div className="form-item ">
                                    <div className="field">電子信箱</div>
                                    <div className="value">
                                        <input type="text" value={UserEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="電子信箱" data-required />
                                    </div>
                                    <span className={`form-item must ${ErrorMsgEmail ? "show-help" : ""}`} style={{ paddingLeft: '140px' }}>
                                        <span className="help-word" style={{ paddingLeft: '10px' }}>  請輸入正確的電子信箱</span>
                                    </span>
                                </div>

                                <div className="form-item">
                                    <div className="field">緊急聯絡人姓名</div>
                                    <div className="value">
                                        <input type="text" value={UserContact} onChange={(e) => setUserContact(e.target.value)} placeholder="中文姓名" data-required />
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">緊急聯絡人手機號</div>
                                    <div className="value">
                                        <input type="text" value={UserContactPhone} onChange={(e) => setUserContactPhone(e.target.value)} placeholder="手機號" data-required />
                                    </div>
                                    <span className={`form-item must ${ErrorMsgCPhone ? "show-help" : ""}`} style={{ paddingLeft: '140px' }}>
                                        <span className="help-word" style={{ paddingLeft: '10px' }}>  請填入正確、有效的「手機號碼」(09開頭共10碼阿拉伯數字)</span>
                                    </span>
                                </div>
                                

   {/*                             <div className="form-item">*/}
   {/*                                 <div className="field">優惠身份</div>*/}
   {/*                                 <div className="value">*/}

   {/*                                     {CourseRegArrayList.map((y) => (*/}
   {/*                                         <div>*/}
   {/*                                             <input type="checkbox" id={`f${y.CouID}-${y.SpecDiscID}`} name={`f${y.SpecDiscID}`} value={y.SpecDiscID} />*/}
   {/*                                             <label htmlFor={`f${y.CouID}-${y.SpecDiscID}`}>{y.SpecName }</label>*/}
   {/*                                         </div>*/}
   {/*                                     ))}  */}
   {/*                                 </div>*/}
   {/*                             </div> */}

                                <div className="form-item mt-1" style={{ backgroundColor: '#9bc3ff', borderRadius: '10px', padding: '10px' }}>
                                    <span>
                                        班別序號：{CouNo}<br />
                                        課程名稱：{CouName}<br />
                                        課程期別：{CouPName}<br />
                                        {/*上課期間：{CouStartDate}&nbsp; ~ &nbsp;{CouCloseDate}<br />*/}
                                        {/*上課時段：{CouStartTime}&nbsp; ~ &nbsp;{CouCloseTime}*/}
                                    </span>
                                </div>
                            </div>

                            <div className="btn-holder text-center mt-1">
                                <a className="btn" onClick={UpData}>
                                    <span>確認報名</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>放棄報名</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>

                        </div>
                        <br/>
                        <a className="btn btn-border btn-middle btn-holder " data-close  >
                            <span>確定</span>
                        </a>
                    </div>
                </div>

                <div id="modal-successs" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}成功</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SpotSignup;
