import "../../css/style.css";
import React, { useState, useEffect } from "react";
//import { API_Login } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { API_POST_ExCourse, API_CoursePeriodData, API_POST_CoursePeriodData, API_Put_CoursePeriodData, API_Del_CoursePeriodData, API_POST_CopyCourseget, API_Post_CopyCoursePost, API_PUT_isPay } from "../../global/constants";
import { CloseModal } from "../../global/ToolUtil";

function CoursePeriod() {
    var history = useHistory();

    useEffect(() => {

        getData();
    }, []);

    function getData() {
        API_CoursePeriodData()
            .then((response) => response.data)
            .then((data) => {
              
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    for (var i = 0; i < data.result.periodList.length; i++) {
                        if (data.result.periodList[i].EarlyBirdDate !=  null) {
                            data.result.periodList[i].EarlyBirdDate = data.result.periodList[i].EarlyBirdDate.split('T')[0];
                        }
                    }
                    console.log('data', data.result.periodList);
                    console.log('sch', data.result.schdList)
                    setDataList(data.result.periodList);
                    setschdList(data.result.schdList);
                    console.log(dataList);
                }
                else if (data.result == null) {
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != 0);
                    });
                    alert(data.message);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const FileDownload = require("js-file-download");
    const [type, settype] = useState(0);
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState([{}]);
    const [modalTit, setModalTit] = useState("刪除");
    const [dataList, setDataList] = useState([{}]);
    const [CouList, setCouList] = useState([{}]);
    const [schdList, setschdList] = useState([{}]);
    const [OCouPName, setOCouPName] = useState("");//原期別名稱
    const [NCouPName, setNCouPName] = useState("");//新期別名稱
    const [OCouSName, setOCouSName] = useState("");//原預設報名作業時間
    const [NCouSName, setNCouSName] = useState("");//新預設報名作業時間
    const [CouPID, setCouPID] = useState("");
    const [CouSID, setCouSID] = useState("");
    const [CouPRemove, setCouPRemove] = useState("");//除外日期

    function Edit(type, index) {
    //    console.log(index);
        
        if (type == 2) { //按下的是新增        
            //呼叫API新增
            var dt = {
                CouPName: dataList[index].CouPName,
                CouSID: parseInt(dataList[index].CouSID),
                //CousPLimitCnt: dataList[index].CousPLimitCnt,
                SpecDiscMoney: dataList[index].SpecDiscMoney == "" ? null : dataList[index].SpecDiscMoney,
                SpecDiscType: dataList[index].SpecDiscType,
                CouPDeptID: dataList[index].CouPDeptID,
                EarlyBirdDate: dataList[index].EarlyBirdDate,
                CouPRemove: dataList[index].CouPRemove
            };
            console.log(JSON.stringify(dt));
            API_POST_CoursePeriodData(dt).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        getData();
                        settype(0);
                    } else {
                        alert(data.message);
                    }
                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        } else if (type == 1) {
            setDataList(function (prev) {
               return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            var dt = {
                                CouPID: item.CouPID,
                                CouPName: item.CouPName,
                                CouSID: item.CouSID,
                                //CousPLimitCnt: item.CousPLimitCnt,
                                SpecDiscMoney: item.SpecDiscMoney == "" ? null : item.SpecDiscMoney,
                                SpecDiscType:item.SpecDiscType,
                                CouPDeptID: item.CouPDeptID,
                                EarlyBirdDate: item.EarlyBirdDate,
                                CouPRemove: item.CouPRemove
                            };
                            console.log(dt);
                            API_Put_CoursePeriodData(dt).then((response) => response.data)
                                .then((data) => {
                                    console.log(data);
                                    if (data.code == "0000") {//成功
                                        Edit(0, i);
                                        getData();                                   
                                    } else {
                                        alert(data.message);
                                        return;
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        }
                        item.isEdit = type == 1 ? true : false;

                    }
                    return item;
               });
            });
        }
        else {
            settype(0);
            setDataList(function (prev) {
               return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            item.isEdit = false;
                            if (item.CouPID == null) {
                                getData();
                            }
                            
                        }
                    }
                    return item;
               });
            });
        }
        //setDataList(function (prev) {
        //    return prev.map((item, i) => {
        //        if (i == index) {
        //            item.isEdit = type == 1 ? true : false;
        //        }
        //        return item;
        //    });
        //});
    }

    function delData() {
        //呼叫API
        //成功
        setDataList(function (prev) {
            API_Del_CoursePeriodData({
                CouPID: dataList[tmpIndex].CouPID,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        getData();
                    } else {
                        alert(data.message);
                        getData();
                        return;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function Add(e) {
        settype(2);
        setDataList(function (prev) {
            return [...prev, { id: null, name: null, period: null, quota: null, discount: null, deadline: null,  isEdit: true }];
        });
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function CouPNameChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.CouPName = e.target.value;
                }
                return item;
            });
        });
    }
    function CouPRemoveChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.CouPRemove = e.target.value;
                }
                return item;
            });
        });
    }
    function couSIDChange(v, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.CouSID = v;
                }
                return item;
            });
        });
    }

    //課表下載
    function Download(index) {
        //console.log(dataList[index].CouPID);
        API_POST_ExCourse({
            CouPID: dataList[index].CouPID,
        }).then((response) => {
            FileDownload(response.data, dataList[index].CouPName + "課表.xlsx");
        });
    }

    function openModalCopy(index) {
        setOCouPName(dataList[index].CouPName); //原期別名稱
        setOCouSName(dataList[index].CouSName); //原預設報名作業時間
        setCouPID(dataList[index].CouPID);
        setCouSID(dataList[index].CouSID);

        setNCouPName("");//新期別名稱
        setNCouSName("");//新預設報名作業時間

        console.log(dataList[index].CouPID);
        console.log(dataList[index].CouSID);
        API_POST_CopyCourseget({
            CouPID: parseInt(dataList[index].CouPID),
            CouSID: parseInt(dataList[index].CouSID)
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setCouList(data.result);
                }
                else if (data.code == "4038") {
                    openModalError("複製", data.message);
                    CloseModal("modal-copy");
                }
                else {
                    openModalError("", data.message);
                    setCouList([]);
                    return;
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


        var id = "modal-copy";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //存檔
    function Save() {
  
        if (NCouPName == "") {
            CloseModal("modal-copy");
            openModalError("複製", "新期別名稱未填");
            return;
        }
        if (NCouSName == "") {
            CloseModal("modal-copy");
            openModalError("複製", "未選擇新預設報名作業時間");
            return;
        }


        API_Post_CopyCoursePost({
            oldCouPID: parseInt(CouPID),
            oldCouSID: parseInt(CouSID),
            newCouPName: NCouPName,
            newCousSID: parseInt(NCouSName)
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    CloseModal("modal-copy");
                    getData();
                }       
                else {
                    CloseModal("modal-copy");
                    openModalError("複製", data.message);                  
                    return;
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    
    
    //function CouSNameChg(e, idx) {
    //    setDataList(function (prev) {
    //        return prev.map((item, i) => {
    //            if (i == idx) {
    //                item.CouSName = e.target.value;
    //            }
    //            return item;
    //        });
    //    });
    //}
    //function CousPLimitCntChg(e, idx) {
    //    setDataList(function (prev) {
    //        return prev.map((item, i) => {
    //            if (i == idx) {
    //                item.CousPLimitCnt = e.target.value;
    //            }
    //            return item;
    //        });
    //    });
    //}
    function SpecMoneyChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecDiscMoney = e.target.value;
                }             
                return item;
            });
        });
    }
    function SpecTypeChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecDiscType = e.target.value;
                }
                return item;
            });
        });
    }    
    function EarlyBirdDateChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.EarlyBirdDate = e.target.value;
                }
                return item;
            });
        });
    }

    //判斷前台繳費關閉
    function isVisiableChange(isPay, idx) {
        console.log(idx);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.isPay = !isPay;
                    API_PUT_isPay({
                        CouSID: item.CouSID,
                        isPay: Boolean(item.isPay)
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            console.log(data);
                            if (data.code == "0000") {
                                Edit(0, i);
                            } else {
                                setMsgStr(data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

                return item;
            });
        });


    }




    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">課程期別管理</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-3">期別名稱</div>
                                        <div className="flex-2">除外日期</div>
                                        <div className="flex-4">預設報名作業期間</div>
                                       {/* <div className="flex-1">報名限制</div>*/}
                                        <div className="flex-2">早鳥折扣</div>
                                        <div className="flex-2">早鳥折扣期限</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                        <div className="flex-1">前台退費控制</div>
                                        <div className="flex-1">&ensp;刪除</div>
                                        <div className="flex-1">複製全期</div>
                                        <div className="flex-1">課表下載</div>
                                    </li>
                                    {
                                        dataList.map((x, i)  =>  (
                                        <li className="lt-row">
                                                <div className="flex-1">{i + 1}</div>
                                                <div className="flex-3">{x.isEdit == true ? (<input type="text" value={x.CouPName} onChange={(e) => CouPNameChg(e, i)} />) : (x.CouPName)}</div>
                                                <div className="flex-2">{x.isEdit == true ? (<input type="text" value={x.CouPRemove} onChange={(e) => CouPRemoveChg(e, i)} />) : (x.CouPRemove)}</div>
                                                <div className="flex-4">
                                                {x.isEdit == true ? (
                                                        //<input type="text" value={x.CouSName} onChange={(e) => CouSNameChg(e, i)} />
                                                        <select name="" id="" onChange={e => couSIDChange(e.target.value ,i)} >
                                                            <option value="">請選擇</option>
                                                            {schdList.map((y) => (                                                              
                                                                    <option value={y.CouSID} selected={y.CouSID==x.CouSID ? true : false}>{y.CouSName}</option>                                                                                                                                
                                                            ))
                                                            }
                                                        </select>
                                                ) : (
                                                      x.CouSName
                                                )}
                                            </div>
                                            {/*<div className="flex-1">*/}
                                            {/*    {x.isEdit == true ? (*/}
                                            {/*            <input type="text" value={x.CousPLimitCnt} onChange={(e) => CousPLimitCntChg(e, i)} />*/}
                                            {/*    ) : (*/}
                                            {/*                x.CousPLimitCnt*/}
                                            {/*    )}*/}
                                            {/*</div>*/}
                                            <div className="flex-2">
                                                    {x.isEdit == true ? (
                                                        <div className="multi-form disabled" style={{ display: "flex" }}>
                                                            <input type="text" value={x.SpecDiscMoney} onChange={(e) => SpecMoneyChg(e, i)} style={{ width: "50%" }} />
                                                            <div className="line"></div>
                                                            <select name="unit" id="unit" style={{ width: "38%" }} value={x.SpecDiscType} onChange={(e) => SpecTypeChg(e, i)} >
                                                                <option value="" disabled selected></option>
                                                                <option value="P" >%</option>
                                                                <option value="N" >元</option>
                                                            </select>
                                                        </div>
                                                        /*<input type="text" value={x.CodeText} onChange={(e) => CodeTextChg(e, i)} />*/
                                                    ) : (
                                                           
                                                            ((x.SpecDiscMoney != null ? x.SpecDiscMoney + ((x.SpecDiscType == "P") ? "%" : "元") : ""))

                                                            
                                                )}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                        <input type="date" value={x.EarlyBirdDate} onChange={(e) => EarlyBirdDateChg(e, i)} />
                                                ) : (
                                                            x.EarlyBirdDate
   
                                                )}
                                                </div>

                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                {                                                  
                                                    x.CouPID == null ? 
                                                    <div className="btn btn-icon" >
                                                        <i className="icon icon-orange icon-16" onClick={(e) => Edit(2, i)}></i>
                                                        <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>                                                     
                                                    </div>
                                                    :
                                                    x.isEdit == true ?
                                                    <div className="btn btn-icon" >
                                                        <i className="icon icon-orange icon-16" onClick={(e) => Edit(1, i)}></i>
                                                        <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                    </div>
                                                    :
                                                    <div className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                            <i className="icon icon-24"></i>
                                                    </div>
                                                    
                                                 }
                                                </div>
                                             </div>

                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                        <input type="checkbox" id={"vis" + i} value={i} checked={x.isPay ? "checked" : ""} onChange={() => isVisiableChange(x.isPay, i)} />
                                                    <label htmlFor={"vis" + i}></label>
                                                </div>
                                            </div>

                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                    <div className="fn-bar">
                                                        <div className="btn btn-icon" onClick={(e) => openModalCopy(i)}>
                                                        <i className="icon icon-22"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                    <div className="fn-bar">
                                                        <div className="btn btn-icon" onClick={(e) => Download(i)}>
                                                        <i className="icon icon-29"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="lt-row">
                                        <div className="btn btn-icon" onClick={type == 0 ? Add : null}>                                       
                                                <i className="icon icon-14" ></i>                                              
                                        </div>
                                            <span>新增</span>                                      
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                        <a className="btn btn-border btn-middle btn-holder " data-close>
                            <span>確定</span>
                        </a>
                    </div>
                </div>
                <div id="modal-copy" className="modal large " data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">複製全期課程</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
               
                                <div className="form-item half">
                                    <div className="field" style={{ width: '90px' }}>新期別名稱</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={NCouPName} onChange={(e) => { setNCouPName(e.target.value) }} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '150px' }}>新預設報名作業時間</div>
                                    <div className="value multi-inline" style={{ width: 'calc(100% - 150px)' }}>
                                        <select name="" value={NCouSName} onChange={(e) => { setNCouSName(e.target.value) }}>
                                            <option>請選擇</option>     
                                            {schdList.map((y) => (
                                                <option value={y.CouSID} >{y.CouSName}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '90px' }}>原期別名稱</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={OCouPName} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '150px' }}>原預設報名作業時間</div>
                                    <div className="value" style={{ width: 'calc(100% - 150px)' }}>
                                        <input type="text" value={OCouSName} />
                                    </div>
                                </div>
                                <div className="container full">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-1">ID</div>
                                            <div className="flex-2">班別序號</div>
                                            <div className="flex-2">課程名稱</div>
                                            <div className="flex-2">課程期別</div>
                                            <div className="flex-2">類別</div>
                                            <div className="flex-2">班別</div>
                                            <div className="flex-2">教師</div>
                                            <div className="flex-2">開課日期</div>
                                            <div className="flex-1">名額</div>
                                        </li>
                                        {CouList.map((x, i) => (
                                            <li className="lt-row ">
                                                <div className="flex-1">{i+1}</div>
                                                <div className="flex-2">
                                                    {x.CouNo}                                                                                         
                                                </div>
                                                <div className="flex-2">
                                                    {x.CouName}
                                                </div>
                                                <div className="flex-2">
                                                    {x.CouPName}
                                                </div>
                                                <div className="flex-2">
                                                    {x.Kind}
                                                </div>
                                                <div className="flex-2">
                                                    {x.ClassType}
                                                </div>
                                                <div className="flex-2">
                                                    {x.CouTeacherName}
                                                </div>
                                                <div className="flex-2">
                                                    {x.CouStartDate}
                                                </div>
                                                <div className="flex-1">
                                                    {x.CouAmountT}
                                                </div>
                                            </li>
                                        ))}
                                    
                                    </ul>
                                </div>
                                <br />
                                <br />
                                

                            </div>

                            <div className="btn-holder text-center mt-1">
                                <a className="btn" onClick={Save} >
                                    <span>存檔</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>放棄</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CoursePeriod;
