import "../../css/style.css";
import React, { useState, useEffect } from "react";

const MenuItem = ({ menuID, menuName, menuDUrl, subMenu }) => {
    const subMenuCount = subMenu == null ? 0 : subMenu.length;
    const path = "Main?path=" + menuDUrl;
    var times = 0;
    useEffect(() => {
        const timeout = setInterval(() => {

            const menuitemID = localStorage.getItem("menuitem");
            const submenuitemID = localStorage.getItem("submenuitem");
            const menuitem = document.getElementById(menuitemID);
            if (menuitem == null) {
                clearInterval(timeout);
                return;
            }
            if (menuitem.classList.contains("active")) {
                clearInterval(timeout);
                return;
            }
            menuitem.classList.add("active");
            // 變更active的icon
            const icon12 = document.getElementById(menuitemID).childNodes[0].childNodes[2];
            if (menuitem.classList.contains('icon-12')) {
                icon12.classList.remove("icon-12");
                icon12.classList.add("icon-13");
            }
            const submenu = document.getElementById(submenuitemID);
            if (submenu != null) {
                submenu.classList.add("active");
            }
        }, 100)
        
    }, []);
    

    function MenuClick(id) {
        localStorage.setItem("submenuitem", id);
       
    }
    function Extend(id) {
        localStorage.setItem("menuitem", id);       

    }

    return (
        <div className={`item  ${subMenuCount > 0 ? 'has-dropmenu' : ''} `} id={`menuitem-${menuID}`} onClick={() => Extend(`menuitem-${menuID}`)}>
            <a href={subMenuCount > 0 ? "#" : path} >
                <i className={`icon icon-11`}></i>

                <span id={`menuitem-span-${menuID}`} className="wp9">{menuName}</span>
                {subMenuCount > 0 ? <i className={'icon icon-12 ml-1'}></i> : ""}
            </a>

            <div className="dropmenu">
                {subMenuCount > 0 ?
                    subMenu.map((x) => (
                        <a key={x.pMenuID} href={`Main?path=${x.funcUrl}`} id={`submenuitem-${x.menuID}`} onClick={()=>MenuClick(`submenuitem-${x.menuID}`)}>
                            {x.menuCpation}
                        </a>
                    ))
                    : null}
            </div>

        </div>
    );
};

export default MenuItem;