import "../css/style.css";
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import { API_POST_HomeCenter, API_POST_AddCreateUser, API_DELETE_DeleteAcnt, API_POST_ResetPwd, API_POST_InvalidAcnt, API_GET_FuncList, API_Put_FuncList } from "../global/constants";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CloseModal, OpenModal } from "../global/ToolUtil";

function unitadmin() {
    const history = useHistory();
    const [username, setUserName] = useState("");
    const [userUnit, setUserUnit] = useState();


    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const pageArray = JSON.parse(localStorage.getItem("pagelist"));
    const menuArray = JSON.parse(localStorage.getItem("menulist"));
    const [dataList, setDataList] = useState([{}]);
    const [tmpIndex, setTmpIndex] = useState();
    const [modalStrName, setModalStrName] = useState();
    const [AcntNo_Add, setDataList_AcntNo] = useState();
    const [AcntName_Add, setDataList_AcntName] = useState();
    const [DeptIDAdd, setDeptID] = useState([]);
    const [IdentityID_Add, setIdentityID] = useState();

    //const [dataList, setDataList] = useState([
    //  { id: 1, name: "葉小", acntno: "act1", state: 0 },
    //  { id: 2, name: "葉中", acntno: "act2", state: 1 },
    //  { id: 3, name: "葉大", acntno: "act3", state: 2 },
    //]);


    useEffect(() => {
        getData();

        document.body.classList.remove("login");
        document.body.classList.add("dashboard");
        document.getElementById("root").classList.remove("center");
        setUserName(localStorage.getItem("userName"));
        setUserUnit(localStorage.getItem("acntTypeID"));
        //menu展開收合--------------------
        var hasSubMenu = document.querySelectorAll(".has-dropmenu");
        hasSubMenu.forEach((element) => {
            element.addEventListener("click", function (e) {
                console.log(element);
                if (element.classList.contains("active")) {
                    element.classList.remove("active");
                } else {
                    element.classList.add("active");
                }
            });
        });
        //menu展開收合----------

    }, []);

    function getData() {

        API_POST_HomeCenter({
        }).then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (data.code == "0000") {
                    setDataList(data.result.centerUserList);
                    setDeptID(data.result.centerUserList.AcntTypeID);
                } else {
                    console.log(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function Add(e) {
        //console.log(userUnit);
        var loading = document.getElementById('loading');
        if (AcntName_Add != null || AcntNo_Add != null) {

            loading.style.display = "block";
            var dt =
            {
                AcntName: AcntName_Add,
                AcntNo: AcntNo_Add,
                DeptID: userUnit,
                IdentityID: IdentityID_Add,
            };
            //console.log(JSON.stringify(dt));

            API_POST_AddCreateUser(dt).then((response) => response.data)
                .then((data) => {
                    //console.log(data);
                    loading.style.display = "none";
                    if (data.code == "0000") {//成功
                        setDataList(function (prev) {
                            return prev.filter((x, i) => i != tmpIndex);
                        });
                        alert(data.message);
                        setDataList_AcntName("");
                        setDataList_AcntNo("");
                        getData();

                    } else {
                        alert(data.message);
                    }
                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    loading.style.display = "none";
                    console.log(err);
                })
        } else {
            alert("名稱和帳號不得為空！");

        }
        e.preventDefault();
    }

    function delData() {
        //呼叫API
        API_DELETE_DeleteAcnt({
            AcntID: dataList[tmpIndex].AcntID
        }).then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (data.code == "0000") {//成功 
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != tmpIndex);
                    });
                    alert(data.message);
                    getData();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    function reData() {
        API_POST_ResetPwd({
            AcntID: dataList[tmpIndex].AcntID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功 
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != tmpIndex);
                    });
                    alert(data.message);
                    getData();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    function invailData() {

        //呼叫API
        API_POST_InvalidAcnt({
            AcntID: dataList[tmpIndex].AcntID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功 
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != tmpIndex);
                    });
                    alert(data.message);
                    getData();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    function nameChg(e, vName) {
        console.log(vName);
        switch (vName) {
            case "AcntName":
                //const AcntName_Add = e.target.value;
                setDataList_AcntName(e.target.value);
                break;
            case "AcntNo":
                setDataList_AcntNo(e.target.value);
                setIdentityID("2");
                break;
        }
    }

    function logout() {
        localStorage.clear();
        history.push("/");
    }
    var currentModal;
    let currentLevel = 1;
    const modals = document.querySelectorAll("[data-modal]");
    const modalBtns = document.querySelectorAll("[data-openmodal]");
    setInterval(tick, 1000);
    const [nowTime, setNowTime] = useState("");
    function tick() {
        setNowTime(
            new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
        );
    }

    modals.forEach((element) => {
        var modalBody = element.querySelector(".modal-container");
        var modalClose = element.querySelectorAll("[data-close]");
        var modalsBack = element.querySelector("[data-back]");
        //console.log(modalClose);
        element.addEventListener("click", function (e) {
            if (e.target == modalsBack) {
                var showLayerClass = "has-layer-" + currentLevel;
                currentLevel = currentLevel - 1;
                element.classList.remove("active");
                document.body.classList.remove(showLayerClass);
            } else if (e.target == element || e.target.hasAttribute("data-close")) {
                /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

                var showLayerClass = "has-layer-" + currentLevel;
                if (typeof currentModal !== "undefined") {
                    currentModal.classList.remove("active");
                } else {
                }
                currentLevel = currentLevel - 1;
                element.classList.remove(
                    "active",
                    "layer-1",
                    "layer-2",
                    "layer-3",
                    "layer-4",
                    "layer-5",
                    "layer-6"
                );
                document.body.classList.remove(showLayerClass);
            }
        });
    });
    function openDelModalCheck(tmpIndex, strName) {
        setTmpIndex(tmpIndex);

        var id = "modal-delcheck";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
        setModalStrName(strName);

    }
    function openModalReview(tmpIndex, strName) {
        setTmpIndex(tmpIndex);
        var id = "modal-review";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
        setModalStrName(strName);
    }
    function openModalInvail(tmpIndex, strName, inType) {
        setTmpIndex(tmpIndex);
        var id = "modal-invail";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";

        target.classList.add("active");
        target.classList.add(layerClass);

        if (inType == 0) {
            setModalStrName("停止【" + strName);
        } else {
            setModalStrName("恢復【" + strName);
        }
    }
    
    const [Name, setName] = useState('');
    const [AcntNo, setAcntNo] = useState('');
    const [FuncList, setFuncList] = useState([{ subFunc: [{}] }]);
    const [UserFunc, setUserFunc] = useState([{}]);
    const [RoleNo, setRoleNo] = useState("");
    function openModalFunc(dt) {
        //console.log(dt);
        setName(dt.AcntName);
        setAcntNo(dt.AcntNo);
        API_GET_FuncList({ AcntID: dt.AcntID })
            .then((res) => res.data)
            .then((data) => {
                if (data.code == "0000") {
                    setFuncList(data.result.funcList);
                    setUserFunc(data.result.userFunc);
                    setRoleNo(data.result.roleNo);
                }
                else {
                    setFuncList([{ subFunc: [{}] }]);
                    setUserFunc([{}]);
                }
            })
        var id = "modal-func";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";

        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function checkfunc(e) {
        if (e.target.checked) {
            setUserFunc(function (prev) {
                return [...prev, { roleNo: RoleNo, funcID: e.target.value }];
            })
        }
        else {
            setUserFunc(function (prev) {
                return prev.filter(f => f.funcID != e.target.value);
            })
        }
    }
    function openModalFuncCheck() {
        var id = "modal-funcCheck";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";

        target.classList.add("active");
        target.classList.add(layerClass);

    }
    function UserFuncSubmit() {
        API_Put_FuncList({ roleLimits: UserFunc,RoleNo:RoleNo })
            .then(res => res.data)
            .then(data => {
                if (data.code == "0000") {
                    OpenModal('modal-ok');
                }
                else {
                    OpenModal('modal-error');
                }
            })
    }
    //function upData(id) {
    //    var index = e.target.dataset.index;
    //    setProductData(function (prev) {
    //        return prev.map(x,i => {
    //            if (i == index) {
    //                item.amortized = e.target.value;
    //            }
    //            return item;
    //        });
    //    });

    //}

    return (
        <>
            <header className="header">
                <a href="/Dashboard" className="logo">
                    <i className="hlogo"></i>
                </a>

                <div className="fn-bar">
                    <div className="left">
                        <div className="logotxt ">報名繳費系統管理平台</div>
                    </div>
                    <div className="right multi-inline">

                        <div className="form-item">
                            <span className="notice">歡迎光臨 {username}</span>
                        </div>
                        <div className="form-item">
                            <div className="btn btn-icon" onClick={logout}>
                                <i className="icon icon-3"></i>
                            </div>
                            <span className="fw-b">登出</span>
                        </div>
                    </div>
                </div>

            </header>

            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="container mid">
                                <div className="left multi-inline">
                                    <h3 className="fz-A mr-1 fw-b">單位使用者帳號管理</h3>
                                    <h3 className="fz-B fw-b">{username}</h3>
                                </div>
                                <div className="right fc-1">
                                    <div className="btn btn-icon">
                                        <i className="icon icon-33 mr-1"></i>
                                    </div>
                                    <span className="fw-b">查看操作紀錄</span>
                                </div>
                            </div>
                        </div>

                        <div className="container mid">
                            <div className="modal-body">
                                <form action="">
                                    <h6 className="fz-D">新增單位使用者帳號</h6>
                                    <div className="form-container inline">
                                        <div className="form-item">
                                            <div className="field">名稱</div>
                                            <div className="value">
                                                <input type="text" value={AcntName_Add} onChange={(e) => nameChg(e, "AcntName")} />
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="field">帳號</div>
                                            <div className="value">
                                                <input type="text" value={AcntNo_Add} onChange={(e) => nameChg(e, "AcntNo")} />
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="field big">預設密碼</div>
                                            <div className="value read">
                                                <input type="text" placeholder="seat1234" disabled />
                                            </div>
                                        </div>

                                        <div className="form-item btn-area">
                                            <button className="btn btn-small" data-openmodal="modal-1" onClick={(e) => Add(e)}>
                                                <span>確定新增</span>
                                            </button>
                                        </div>
                                    </div>
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-4">名稱</div>
                                            <div className="flex-4">帳號</div>
                                            <div className="flex-2">&ensp;權限設置</div>
                                            <div className="flex-2 fn">&ensp;重置密碼</div>
                                            <div className="flex-1 fn">&ensp;停權</div>
                                            <div className="flex-1 fn">&ensp;刪除</div>
                                        </li>
                                        {dataList.map((x, i) => (
                                            <li className="lt-row">
                                                <div className="flex-4">{x.AcntName}</div>
                                                <div className="flex-4">{x.AcntNo}</div>
                                                <div className="flex-2">
                                                    <div className="fn-bar">
                                                        <div
                                                            className="btn btn-small btn-border"
                                                            onClick={(e) => openModalFunc(x)}
                                                        >
                                                            <span>權限設置</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-2 fn">
                                                    <div className="fn-bar">
                                                        {
                                                            x.AcntInvalid == 0 ?
                                                                <div className="btn btn-small btn-border" onClick={(e) => openModalReview(i, x.AcntNo)}>
                                                                    <span>重置密碼</span>
                                                                </div>
                                                                :
                                                                <div className="btn btn-small btn-border disabled" >
                                                                    <span>重置密碼</span>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex-1 fn">
                                                    <div className="fn-bar">
                                                        <div className="btn btn-icon" onClick={(e) => openModalInvail(i, x.AcntNo, x.AcntInvalid)} >
                                                            {
                                                                x.AcntInvalid == 0 ?
                                                                    <i className="icon icon-5"></i>
                                                                    :
                                                                    <i className="icon icon-6"></i>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 fn">
                                                    <div className="fn-bar">
                                                        <div
                                                            className="btn btn-icon"
                                                            onClick={(e) => openDelModalCheck(i, x.AcntNo)}
                                                        >
                                                            <i className="icon icon-4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-delcheck" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認要刪除該帳號：【{modalStrName}】?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-review" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確定要重置【{modalStrName}】的密碼 ?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={reData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-invail" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確定要{modalStrName}】的權限 ?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={invailData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/*權限設置modal*/}
                <div id="modal-func" className="modal large" data-modal data-mid="1.1.1.1">
                    <div className="modal-container ">
                        <div className="title-area">
                            <div className="container">
                                <h3 className="fz-B has-border">權限設定</h3>
                            </div>
                        </div>

                        <div className="container">
 
                                <div className="form-container">
                                    <div className="block-area">
                                        <div className="form-item col">
                                            {
                                            FuncList.map((x) => (
                                                <>
                                                    {x.subFunc == null ?
                                                    <>
                                                        <div className="field">
                                                            <input type="checkbox" id={"I-" + x.funcID} name={"G-" + x.funcID} value={x.funcID} checked={
                                                                    UserFunc.filter(f => f.funcID == x.funcID).length > 0 ? "checked" : ""} onClick={checkfunc} />
                                                            <label for={"I-" + x.funcID}>{x.funcName}</label>
                                                        </div>
                                                        <div className="value" style={{ paddingLeft: "2em" }}>

                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="field">
                                                          {/*  <input type="checkbox" id={"I-" + x.funcID} name={"G-" + x.funcID} value={x.funcID} />*/}
                                                            <label for={"I-" + x.funcID}>{x.funcName}</label>
                                                        </div>
                                                        <div className="value" style={{ paddingLeft: "2em" }}>
                                                            {
                                                                x.subFunc.map((y) => (
                                                                    <div>
                                                                        <input type="checkbox" id={"I-" + y.funcID} onClick={checkfunc} name={"G-" + y.parentFuncID} value={y.funcID} checked={UserFunc.filter(f => f.funcID == y.funcID).length > 0 ? "checked" : ""} />
                                                                        <label for={"I-" + y.funcID}>{y.funcName}</label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </>
                                                    }
                                                    <span className="help-word">請至少選擇一個</span>
                                                </>
                                            ))
                                            }
                                            
                                        </div>
                                        {/*<div className="row field">*/}
                                        {/*    <div className="left">*/}
                                        {/*        <span>姓名：{Name}</span>*/}
                                        {/*        <span>帳號：{AcntNo}</span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="form-item col">*/}
                                        {/*    <div className="field jcsb">*/}
                                        {/*        <span> 功能權限</span>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="form-item">*/}
                                        {/*        <div class="value col" style={{ width:"25%"}}>*/}
                                        {/*            <div style={{ width: "100%" }}>*/}
                                        {/*                <input type="checkbox" id="checkbox1" name="checkbox" value="checkbox1"/>*/}
                                        {/*                    <label for="checkbox1">銷貨訂單</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>*/}
                                        {/*                <div style={{ width: "30px", height: "1px" }}></div>*/}
                                        {/*                <input type="checkbox" id="checkbox2" name="checkbox" value="checkbox2" />*/}
                                        {/*                <label for="checkbox2">帳務沖銷</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*        <div class="value col" style={{ width: "25%" }}>*/}
                                        {/*            <div style={{ width: "100%" }}>*/}
                                        {/*                <input type="checkbox" id="checkbox1" name="checkbox" value="checkbox1" />*/}
                                        {/*                <label for="checkbox1">銷貨訂單</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>*/}
                                        {/*                <div style={{ width: "30px", height: "1px" }}></div>*/}
                                        {/*                <input type="checkbox" id="checkbox2" name="checkbox" value="checkbox2" />*/}
                                        {/*                <label for="checkbox2">帳務沖銷</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*        <div class="value col" style={{ width: "25%" }}>*/}
                                        {/*            <div style={{ width: "100%" }}>*/}
                                        {/*                <input type="checkbox" id="checkbox1" name="checkbox" value="checkbox1" />*/}
                                        {/*                <label for="checkbox1">銷貨訂單</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>*/}
                                        {/*                <div style={{ width: "30px", height: "1px" }}></div>*/}
                                        {/*                <input type="checkbox" id="checkbox2" name="checkbox" value="checkbox2" />*/}
                                        {/*                <label for="checkbox2">帳務沖銷</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*        <div class="value col" style={{ width: "25%" }}>*/}
                                        {/*            <div style={{ width: "100%" }}>*/}
                                        {/*                <input type="checkbox" id="checkbox1" name="checkbox" value="checkbox1" />*/}
                                        {/*                <label for="checkbox1">銷貨訂單</label>*/}
                                        {/*            </div>*/}
                                        {/*            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>*/}
                                        {/*                <div style={{ width: "30px", height: "1px" }}></div>*/}
                                        {/*                <input type="checkbox" id="checkbox2" name="checkbox" value="checkbox2" />*/}
                                        {/*                <label for="checkbox2">帳務沖銷</label>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                            
                                        {/*    <span className="help-word">請至少選擇一個</span>*/}
                                        {/*</div>*/}
                                       
                                    </div>

                                    <div className="form-item btn-area sp">
                                        <div className="left"></div>
                                        <div className="right">
                                            <a className="btn btn-border" data-close>
                                                <span>取消</span>
                                            </a>
                                        <button className="btn" data-openmodal="modal-1" onClick={openModalFuncCheck}>
                                                <span>確定</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                    
                        </div>
                    </div>

                </div>
                <div id="modal-funcCheck" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確定要賦予{Name}權限 ?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={UserFuncSubmit}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-ok" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">修改成功</div>
                            <div className="btn-holder">
                                <a className="btn" data-close onClick={() => { CloseModal("modal-func") }}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">修改失敗</div>
                            <div className="btn-holder">
                                <a className="btn" data-close>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/*權限設置modal*/}
            </div>
        </>
    );
}

export default unitadmin;
