import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_Get_NoticeItem,
    API_Post_SendRecget,
    API_Post_SendRecCheckData
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
function InformStatistics() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        //下拉選單
        API_Get_NoticeItem()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Post_SendRecget({
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    setDataList(data.result);
                }
                else {
                    setDataList([]);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([]);
    const [dataList2, setDataList2] = useState([]);

    //下拉選單 選定期別 連動班別序號
    function selCouPID(PID) {
        setDropDownCouPID(PID);
        //console.log(PID);
        if (PID == "") {
            API_Post_SendRecget({})
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        console.log(data);
                        setDataList(data.result);
                    }
                    else {
                        alert(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            API_Post_SendRecget({
                CouPID: PID
            })
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        console.log(data);
                        setDataList(data.result);
                    }
                    else {
                        alert(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        API_Post_SendRecget({
            CouPID: PID
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function openModalCheck(SID) {

        API_Post_SendRecCheckData({
            NoticeSID: SID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    setDataList2(data.result);
                }
                else {
                    setDataList2([]);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

        setTmpIndex(tmpIndex);
        var id = "modal-show";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB">
                                <h3 className="fz-A mr-1">通知發送結果統計</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="form-item quarter">
                               <div className="top-table">
                                    期別
                                </div>
                                <div className="top-value ">
                                    <select value={DropDownCouPID} onChange={(e) => { selCouPID(e.target.value) }} >
                                        <option value="">請選擇</option>
                                        {coursePeriodArrayList.map((y) => (
                                            <option value={y.id}>{y.name} </option>
                                        ))}
                                    </select>
                                </div>
                                
                            </div>
                           
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-2">期別</div>
                                        <div className="flex-3 ">課程名稱</div>
                                        <div className="flex-6 text-center">通知內容</div>
                                        <div className="flex-2">通知時間</div>
                                        <div className="flex-2">對象</div>
                                        <div className="flex-1">方式</div>
                                        <div className="flex-1">總數</div>
                                        <div className="flex-1">郵件<br/>成功</div>
                                        <div className="flex-1">郵件<br />失敗</div>
                                        <div className="flex-1">簡訊<br />成功</div>
                                        <div className="flex-1">簡訊<br />失敗</div>
                                        <div className="flex-1">查看</div>

                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-2">
                                                {x.CouPName}
                                            </div>
                                            <div className="flex-3">
                                                {x.CouName}
                                            </div>
                                            <div className="flex-6">
                                                {x.SMSMAil }
                                            </div>
                                            <div className="flex-2">
                                                {x.NoticeSendDate}
                                            </div>
                                            <div className="flex-2">
                                                {x.CodeText }
                                            </div>
                                            <div className="flex-1">
                                                {x.Notice }
                                            </div>
                                            <div className="flex-1">
                                                {x.ALLCnt }
                                            </div>
                                            <div className="flex-1">
                                                {x.mailOK}
                                            </div>
                                            <div className="flex-1">
                                                {x.mailNO}
                                            </div>
                                            <div className="flex-1">
                                                {x.SMSOK} 
                                            </div>
                                            <div className="flex-1">
                                                {x.SMSNO}
                                            </div>
                                           
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(x.NoticeSID)}
                                                    >
                                                        <i className="icon icon-20"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="modal-show" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">查看通知發送結果</h3>
                        </div>
                        <div className="modal-body">
                            <ul className="list-table">
                                <li className="lt-row lt-th">
                                    <div className="flex-1">ID</div>
                                    <div className="flex-3">身份證字號</div>
                                    <div className="flex-2">簡訊<br/>發送狀態</div>
                                    <div className="flex-2">Email<br />發送狀態</div>
                                    <div className="flex-3">發送時間</div>
                                </li>
                                {dataList2.map((x, i) => (
                                    <li className="lt-row">
                                        <div className="flex-1">{i + 1}</div>
                                        <div className="flex-3">
                                            {x.URegIDNo}
                                        </div>
                                        <div className="flex-2">
                                            {x.SMSState == "" ? (
                                                "-"
                                            ) : (
                                                    x.SMSState
                                            )}
                                        </div>
                                        <div className="flex-2">
                                            {x.EmailState == "" ? (
                                                "-"
                                            ) : (
                                                    x.EmailState
                                            )}
                                        </div>
                                        <div className="flex-3">
                                            {x.NoticeSendDate}
                                        </div>
                                    </li>
                                ))}

                            </ul>

                            <div className="btn-area sp"><br/>
                                <div className="btn-holder text-center">
                                    <a className="btn btn-border" data-close>
                                        <span>確認</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}
export default InformStatistics;
