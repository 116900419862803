import '../../css/style.css';
import React, { useState } from 'react';
import { API_Login } from '../../global/constants';
import { useHistory } from 'react-router-dom';
import ClientCaptcha from 'react-client-captcha';

function Loginform() {
    var history = useHistory();
    const [captchaa, setcaptcha] = useState();
    function showpassword() {
        if (document.getElementById('Tpassword').type === "password") {
            document.getElementById('Tpassword').type = "text";
            document.getElementById('eye').classList.add('active');
        } else {
            document.getElementById('Tpassword').type = "password";
            document.getElementById('eye').classList.remove('active');
        }
    }

    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                submit();
            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 13) {
                submit();
            }
        }
    };

//    const [verify, setverify] = useState('')
    const [username, setUserName] = useState('');
    function usernameChange(e) {
        setUserName(e.target.value);
    }

    const [password, setPassword] = useState('');
    function passwordChange(e) {
        setPassword(e.target.value);
    }

    const [captchaCode, setCaptchaCode] = useState('');
    const [UserCaptchaCode, setUserCaptchaCode] = useState('');
    //function captchaCodeChange(e) {
    //    setUserCaptchaCode(e.target.value);
    //}

    const [msgStr, setMsgStr] = useState('');

    

    function submit() {
        if (username === '' || password === '') {
            alert('請輸入帳密');
            return false;
        }

        if (captchaCode === '') {
            alert('請輸入驗證碼');
            return false;
        }

        console.log(captchaCode)
        console.log(ClientCaptcha.getElementById)

         

        if (captchaCode.toLowerCase() != UserCaptchaCode.toLowerCase()) {
            alert('驗證碼輸入錯誤');
            return false;
        }
        //localStorage.setItem('token', data.token.token);
        //localStorage.setItem('user', data.result.loginData.userNo);
        //localStorage.setItem('userName', data.result.loginData.userName);
        //localStorage.setItem('userID', data.result.loginData.userID);

        //if (username === '1') {
        //    history.push('/AcntAdmin')
        //}
        //else if (username === '2') {
        //    history.push('/UnitAdmin')
        //}
        //else {
        //    history.push('/Main?path=carouselBase')
        //}

        API_Login({
            AcntNo: username,
            AcntPwd: password
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                setMsgStr('');

                localStorage.clear();
                if (data.code == "1023") {
                    alert('帳號首次登入，請重新變更密碼。');
                    localStorage.setItem('token', data.token.token);
                    history.push('/changePassword');
                }
                if (data.code == "0000" || data.code == "1027") {
                    if (data.code == "1027") { 
                        alert(data.message);
                    }

                    localStorage.setItem('token', data.token.token);
                    localStorage.setItem('user', data.result.loginData.acntNo);
                    localStorage.setItem('userName', data.result.loginData.acntName);
                    localStorage.setItem('userID', data.result.loginData.acntID);
                    localStorage.setItem('acntTypeID', data.result.loginData.acntTypeID); //單位序號
                    localStorage.setItem("islogin", "1");
                    if (data.result.menuList.length == 0) {
                        history.push('/Dashboard');
                    }
                    if (data.result.loginData.acntNo == "dadunuserA")
                    { //大墩A先生
                        history.push('/Main?path=carouselBase')
                    } else if (data.result.loginData.acntNo == "artadmin") {
                        history.push('/UnitAdmin')
                    } else {
                        for (var i = 0; i < data.result.menuList.length; i++) {
                            if (data.result.menuList[i].subMenu.length <= 0) {
                                history.push(data.result.menuList[i].FuncUrl);
                                break;
                            }
                            else {
                                history.push('/Main?path=' + data.result.menuList[i].subMenu[0].FuncUrl);
                                break;
                            }
                        }
                        
                    }

                    //getDashboard();
                } else {
                    setMsgStr(data.message);
                }

                //console.log(localStorage.getItem('menulist'));
            })
            .catch(err => {
                console.log(err);
            })

        //console.log(username , password);
        //API_Login({
        //    UserNo: username,
        //    Password: password
        //}).then((response) => response.data)
        //    .then((data) => {
        //        console.log(data);
        //        setMsgStr('');
        //        if (data.code == "1025") {
        //            //getDashboard();
        //            localStorage.setItem('token', data.token.token);
        //            localStorage.setItem('user', data.result.loginData.userNo);
        //            localStorage.setItem('userName', data.result.loginData.userName);
        //            localStorage.setItem('userID', data.result.loginData.userID);
        //            history.push('?path=changepassword')

        //        }
        //        else if (data.code == "0000") {
        //            localStorage.setItem('token', data.token.token);
        //            localStorage.setItem('user', data.result.loginData.userNo);
        //            localStorage.setItem('userName', data.result.loginData.userName);
        //            localStorage.setItem('userID', data.result.loginData.userID);
        //            history.push('?path=authenticator')
        //            //getDashboard();
        //        } else if (data.code == "1002") {
        //            document.getElementById('userDiv').classList.add('show-help');
        //            document.getElementById('UserNo').classList.add('error');
        //        } else if (data.code == "1020") {
        //            setMsgStr(data.message);
        //            document.getElementById('userPwdDiv').classList.add('show-help');
        //            document.getElementById('Tpassword').classList.add('error');
        //        } else {
        //            setMsgStr(data.message);
        //        }

        //        //console.log(localStorage.getItem('menulist'));
        //    })
        //    .catch(err => {
        //        console.log(err);
        //    })
    }

    return (
        <div id="div_login">
            <form action="" className="form-container">
                <div className="form-item lg" id="userDiv">
                    <div className="field">
                        <i className="icon icon-0"></i>
                    </div>
                    <div className="value">
                        <input type="text" id="UserNo" value={username} onChange={usernameChange} placeholder="請輸入帳號" />
                    </div>
                    <span className="help-word">
                        帳號錯誤
                    </span>
                </div>
                <div className="form-item lg" id="userPwdDiv">
                    <div className="field">
                        <i className="icon icon-1"></i>
                    </div>
                    <div className="value">
                        <div className="password" >
                            <input type="password" id="Tpassword" value={password} onChange={passwordChange} placeholder="請輸入密碼" />
                            <i className="eye" id="eye" onClick={showpassword}></i>
                        </div>
                    </div>
                    <span className="help-word">
                        密碼錯誤
                    </span>
                </div>
            
                <div className="form-item lg" id="userPwdDivuserPwdDiv">
                    <div className="field">
                        <i className="icon icon-2"></i>
                    </div>
                    <div className="value" style={{width:'450px'}}  >
                        <div className="password" style={{ display: "flex"}}>
                            <input id="Tpassword" value={UserCaptchaCode}  onChange={(e) => { setUserCaptchaCode(e.target.value) }} placeholder="請輸入驗證碼" />
                            {/*驗證碼*/}
                            <ClientCaptcha captchaCode={(code) => setCaptchaCode(code)} chars="0123456789" />

                        </div>
                    </div>

                    <span className="help-word">
                        驗證碼錯誤
                    </span>
                </div>


                {/* 錯誤訊息通知*/}
                <div className="form-item">
                    <div className="notification">
                        {msgStr}
                    </div>
                </div>
                 

                <div className="form-item short btn-area">
                    <a className="btn full btn-dark" onClick={submit}><span>登入</span></a>
                </div>
            </form>
        </div>
    );



}
export default Loginform;
