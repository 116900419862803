//�d����
export function NumFormart(num) {
    if (num) {
        num += "";
        var arr = num.split(".");
        var re = /(\d{1,3})(?=(\d{3})+$)/g;
        return arr[0].replace(re, "$1,") + (arr.length == 2 ? "." + arr[1] : "");
    } else {
        return num;
    }
}
//�Φ���k �_�h�s�W����Ʒ|����۵�������null
//https://blog.csdn.net/arthur_d/article/details/7323733
export function Clone(obj) {
    var o, obj;
    if (obj.constructor == Object) {
        o = new obj.constructor();
    } else {
        o = new obj.constructor(obj.valueOf());
    }
    for (var key in obj) {
        if (o[key] != obj[key]) {
            if (typeof (obj[key]) == 'object') {
                o[key] = Clone(obj[key]);
            } else {
                o[key] = obj[key];
            }
        }
    }
    o.toString = obj.toString;
    o.valueOf = obj.valueOf;
    return o;
}
///Url��X
export function UrlDecode(str) {
    var uzipStr = '';
    for (var i = 0; i < str.length; i++) {
        var chr = str.charAt(i);
        if (chr === '+') {
            uzipStr = ' ';
        } else if (chr === '%') {
            var asc = str.substring(i + 1, i + 3)
            if (parseInt('0x' + asc) > 0x7f) {
                uzipStr += decodeURI('%' + asc.toString() + str.substring(i + 3, i + 9).toString())
                i += 8;
            } else {
                uzipStr += AsciiTostring(parseInt('0x' + asc));
                i += 2;
            }
        } else {
            uzipStr += chr;
        }
    }
    return uzipStr;
}
//���}Modal
export function OpenModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.add('active');
        target.classList.add(layerClass);
    }
}
//����Modal
export function CloseModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

}
//JS�Ѽ�
export function InitJS() {
    /*hover�q�X�p���*/
    if (document.querySelector('[data-hoverTrigger]')) {
        const hoverBtns = document.querySelectorAll('[data-hoverTrigger]');
        const hoverItems = document.querySelectorAll('[data-hoverItems]');
        //��������ť�Ҧ�
        hoverBtns.forEach(element => {
            element.mouseenter = null;
        });
        hoverItems.forEach(element => {
            element.mouseleave = null;
        });
        //�ᤩ��ť
        hoverBtns.forEach(element => {
            var parent = element.closest('.lt-row');
            element.addEventListener('mouseenter', function () {
                parent.classList.add('show-hovers')
            })
        });
        hoverItems.forEach(element => {
            var parent = element.closest('.lt-row');

            element.addEventListener('mouseleave', function () {
                parent.classList.remove('show-hovers')
            })
        });
    }

    /*��ܤU��*/
    const datalist = document.querySelectorAll("[data-datalist]");

    datalist.forEach((element) => {
        var dropmenu = element.querySelector(".datalist");
        var input = element.querySelector("input");
        //������ť
        input.keyup = null;
        input.addEventListener("keyup", function () {
            element.classList.add("show-datalist");
        });
        document.body.addEventListener("click", function (e) {
            var target = e.target;
            if (element.contains(target)) {
                //console.log(target);
                if (dropmenu.contains(target)) {
                    var value = target.dataset.value;
                    input.value = value;
                    element.classList.remove("show-datalist");
                } else {
                    //console.log('ddd');
                }
            } else {
                element.classList.remove("show-datalist");
            }
        });
    });
    

    var currentModal;
    let currentLevel = 1;
    const modals = document.querySelectorAll('[data-modal]');
    const modalBtns = document.querySelectorAll('[data-openmodal]');
    modals.forEach(element => {
        var modalBody = element.querySelector('.modal-container');
        var modalClose = element.querySelectorAll('[data-close]');
        var modalsBack = element.querySelector('[data-back]');
        element.click = null;
        //console.log(modalClose);
        element.addEventListener('click', function (e) {
            if (e.target == modalsBack) {
                var showLayerClass = 'has-layer-' + (currentLevel);
                currentLevel = currentLevel - 1;
                element.classList.remove('active');
                body.classList.remove(showLayerClass);
            } else if (e.target == element || e.target.hasAttribute('data-close')) {
                /*�o��i��n�A�W�[�@�ӧP�_�A�P�_�o��[data-close]�O�_�n����w�h��*/

                var showLayerClass = 'has-layer-' + (currentLevel);
                if (typeof currentModal !== "undefined") {
                    currentModal.classList.remove('active');
                } else { }
                currentLevel = currentLevel - 1;
                element.classList.remove('active', 'layer-1', 'layer-2', 'layer-3', 'layer-4', 'layer-5');
                body.classList.remove('has-modal', showLayerClass);
            }
        });
    });
    
    /*�W��*/
    if (document.querySelector('[data-fakeUpload]')) {
        const item = document.querySelector('[data-fakeUpload]');
        const real = item.querySelector('.real');
        const fake = item.querySelector('.fake');
        real.change = null;
        real.addEventListener('change', function (e) {
            var path = e.target.value;
            var filename = path.replace(/^.*[\\\/]/, '');
            fake.value = filename;

        })
    }

   
    /*slide toggle ���U�i�}*/
    if (document.querySelector('[data-slidetoggle]')) {
        const items = document.querySelectorAll('[data-slidetoggle]');
        items.forEach(element => {
            var targetID = element.dataset.slidetoggle;
            var parent = element.closest('.lt-row');
            var target = document.querySelector('#' + targetID)
            console.log(target);
            element.click = null;
            element.addEventListener('click', function () {
                element.classList.toggle('rotate');
                target.classList.toggle('hide');
                parent.classList.toggle('highlight');
            })

        });
    }

    /*�Ȼs�ƤU�Կ��*/
    const dps = document.querySelectorAll("[data-dropmenu]");
    dps.forEach((element) => {
        var selectedValue;
        var trigger = element.querySelector(".dropmenu-value");
        var dropmenu = element.querySelector(".dropmenu");
        var options = element.querySelector("[data-value]");
        trigger.click = null;
        trigger.addEventListener("click", function (e) {
            element.classList.add("active");
        });
        dropmenu.click = null;
        dropmenu.addEventListener("click", function (e) {
            if (e.target.matches("[data-value]")) {
                selectedValue = e.target.dataset.value;
                trigger.textContent = selectedValue;
                element.classList.remove("active");
            }
        });

    });

}
export function Init_Menu() {
    /*menu*/
    var hasSubMenu = document.querySelectorAll(".has-dropmenu");
    hasSubMenu.forEach(element => {
        element.click = null;
        element.addEventListener('click', function (e) {
            var menu = document.querySelectorAll(".item");
            menu.forEach((e) => {
                if (e.classList.contains("active") && element !== e) {
                    e.classList.remove("active");
                }
            });
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            } else {
                element.classList.add('active');
            }
        });
    });
}

