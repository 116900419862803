import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_GET_GetCarouselList,
    API_PUT_CarouselInfo,
    API_POST_CarouselInfo,
    API_POST_UpdateHome,
    API_DELETE_delData,
    API_POST_CarouselSortUp,
    API_POST_CarouselSortDown
} from "../../global/constants";

import { useHistory } from "react-router-dom";
import { OpenModal, CloseModal } from "../../global/ToolUtil";
import Swal from 'sweetalert2'

function CarouselBase() {
    var history = useHistory();
    const [acntTypeID] = useState(localStorage.getItem("acntTypeID"));
    const [carouselArray, setCarouselArray] = useState([{}]);
    const [caroCaption, setCaroCaption] = useState();
    const [caroUrl, setCaroUrl] = useState();
    const [caroImage, setCaroImage] = useState();
    const [caroID, setCaroID] = useState();
    const [file, setFile] = useState();

    //Base64 轉成 圖片
    const [demoPic, setDemoPic] = useState("");

    useEffect(() => {
        getData();     
    }, []);

    function getData() {

        //取得輪播廣告列表
        API_GET_GetCarouselList().then((response) => response.data)
            .then((data) => {   
                setCarouselArray(data.result);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //錯誤訊息
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();

    //刪除
    function delData() {
        //呼叫API

        API_DELETE_delData({ "CaroID": carouselArray[tmpIndex].caroID })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {

                    //刪除成功後 重新run一次 列表
                    Swal.fire({
                        icon: 'error',
                        title: '刪除',
                        text: '完成刪除。',
                        showConfirmButton: true,
                        showCloseButton: true
                    })

                    getData();
                    
                } else {
                    openModalError("刪除輪播", data.message);
                }

            })
            .catch(err => {
                console.log(err);

            })

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
     
    }

    function upData() {
        var go = true;

        if (caroCaption == '') {
            go = false;
          /*  alert("請填寫廣告標題");*/
            Swal.fire({
                icon: 'warning',
                title: '',
                text: '請填寫廣告標題。',
                showConfirmButton: true,
                showCloseButton: true
            })
            return;

        } else if (demoPic == '') {
            go = false;
           /* alert("請選擇檔案");*/
            Swal.fire({
                icon: 'warning',
                title: '',
                text: '請上傳圖檔。',
                showConfirmButton: true,
                showCloseButton: true
            })
            return;

        } else if (caroUrl == '') {
            go = false;
           /* alert("請填寫鏈接網址");*/
            Swal.fire({
                icon: 'warning',
                title: '',
                text: '請填寫鏈接網址。',
                showConfirmButton: true,
                showCloseButton: true
            })
            return;
        }

        // 檢查副檔名
        //var imgArr = ['jpg', 'jpeg', 'png'];
        //var fExt = demoPic.split(".").pop().toLowerCase();
        //if (imgArr.indexOf(fExt) == '-1') {
        //    go = false;
        //    //alert("檔案格式限制 *.jpg *.jpeg *.png");

        //    Swal.fire({
        //        icon: 'warning',
        //        title: '',
        //        text: '檔案格式限制 *.jpg *.jpeg *.png。',
        //        showConfirmButton: true,
        //        showCloseButton: true
        //    })
        //    return;
        //}

        if (go) {
            if (caroID != "") {
                //編輯
                console.log("put");
                API_PUT_CarouselInfo({
                    CaroID: caroID,
                    CaroCaption: caroCaption,
                    CaroUrl: caroUrl,
                    CaroImage: caroImage,
                    CaroImageBase64: demoPic


                }).then((response) => response.data)
                    .then((data) => {
                        console.log(data);
                        getData();
                        Swal.fire(
                            "成功", "", "success"
                        ).then(res => {
                            CloseModal("modal-edit");
                            setDemoPic("");
                        })
                        
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
                //var reader = new FileReader();

                //reader.readAsDataURL(file);
                //reader.onload = function () {
                   
                //}

            } else {
                //新增
                console.log("post");
                API_POST_CarouselInfo({
                    CaroCaption: caroCaption,
                    CaroUrl: caroUrl,
                    CaroImage: caroImage,
                    CaroImageBase64:demoPic
                }).then((response) => response.data)
                    .then((data) => {
                        //console.log(data);
                        getData();
                        Swal.fire(
                            "成功","","success"
                        ).then(res => {
                            CloseModal("modal-edit"); setDemoPic("");
                        })                       

                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
                //var reader = new FileReader();

                //reader.readAsDataURL(file);
                //reader.onload = function () {
                 
                    
                //}
            }
        }
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        OpenModal("modal-check");
         
    }

    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);
        OpenModal("modal-error");
        
    }

    function openModalEdit(tmpIndex) {

        //新增的時候是1 此時要清空所有欄位
        if (tmpIndex == -1) {
            console.log("add");
            setCaroCaption("");
            setCaroUrl("");
            setCaroImage("");
            setCaroID("");
            setFile("");
            setDemoPic("");

        } else {
            //編輯的時候 把編輯資料帶入
            console.log("edit");


            setCaroCaption(carouselArray[tmpIndex].caroCaption);
            setCaroUrl(carouselArray[tmpIndex].caroUrl);
            //setCaroImage(carouselArray[tmpIndex].caroImage);
            setCaroID(carouselArray[tmpIndex].caroID);
            setFile(carouselArray[tmpIndex].caroImage);
            setDemoPic(carouselArray[tmpIndex].caroImage);
                
        }
        OpenModal("modal-edit");
        
    }

    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }
    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }

    function handleChange() {
        var FileInput = document.getElementById("file");
 
        var _URL = window.URL || window.webkitURL;
        if (FileInput.files.length > 0) {
            
            var img = new Image();

            //如果格式錯誤　就會報錯
            img.onerror = function () {
      
                Swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '格式錯誤，請重新上傳。',
                    showConfirmButton: true,
                    showCloseButton: true
                })
                //每次開啟後要清空上傳的input
                FileInput.value = "";
            };
            img.onload = function () {
                var rate = Math.round10((img.width / img.height), -2);
                console.log(rate);
                if (rate > 1.72 || rate < 1.71) {
                    /*   alert("提醒您！\n圖片長寬比為6:3.5 不符合比例前台顯示會造成異常。")*/
                    Swal.fire({
                        icon: 'warning',
                        title: '提醒你',
                        text: '圖片長寬比為6:3.5 不符合比例前台顯示會造成異常。',
                        showConfirmButton: true,
                        showCloseButton: true
                    })
                }
            };
            var reader = new FileReader();
            reader.readAsDataURL(FileInput.files[0]);
            reader.onload = function () {
                setCaroImage(FileInput.files[0].name);
                img.src = reader.result;
                setDemoPic(reader.result);
            }
           
        }
    }

    //首頁顯示
    function pushHomeChange(idx, pushHome) {

        setCarouselArray(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.pushHome = !pushHome;
                }
                return item;
            });
        });

        console.log(carouselArray[idx].caroID);

        API_POST_UpdateHome({
            CaroID: carouselArray[idx].caroID,
            PushHome: !pushHome
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //排序 往上
    function CarouselSortUp(CaroID) {
        API_POST_CarouselSortUp({
            "CaroID": CaroID,
            "DeptID": acntTypeID
        }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    getData();
                } else {
                 /*   openModalError("排序", data.message);*/
                    Swal.fire({
                        icon: 'error',
                        title: '排序失敗',
                        text: data.message,
                        showConfirmButton: true,
                        showCloseButton: true
                    })
                    getData();
                }
            })
            .catch(err => {
                console.log(err);

            })
    }

    //排序 往下
    function CarouselSortDown(CaroID) {
        API_POST_CarouselSortDown({
            "CaroID": CaroID,
            "DeptID": acntTypeID
        }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    getData();
                } else {
                    /* openModalError("排序", data.message);*/
                    Swal.fire({
                        icon: 'error',
                        title: '排序失敗',
                        text: data.message,
                        showConfirmButton: true,
                        showCloseButton: true
                    })
                    getData();
                }

            })
            .catch(err => {
                console.log(err);

            })
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">輪播廣告設定
                                </h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="info-holder">
                                <div className="left">

                                </div>
                                <div className="right fc-1">
                                    <div
                                        className="btn btn-icon"
                                        onClick={()=>openModalEdit(-1)} >
                                        <i className="icon icon-14" ></i>
                                    </div>
                                    <span>新增</span>
                                </div>
                            </div>

                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-4">廣告標題</div>
                                        <div className="flex-3">&ensp; 縮&ensp; 圖</div>
                                        <div className="flex-5">鏈接網址</div>
                                        <div className="flex-1">顯示</div>
                                        <div className="flex-1">&ensp; 編輯</div>
                                        <div className="flex-1">&ensp; 刪除</div>
                                        <div className="flex-2">&ensp; 排&ensp;  序</div>
                                    </li>
                                    {carouselArray.map((x, i) => (
                                        <li key={"carouselArray" + i} className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-4">
                                                {x.caroCaption}
                                            </div>
                                            <div className="flex-3">
                                                <img src={x.caroImage} />
                                            </div>
                                            <div className="flex-5">
                                                {x.caroUrl}
                                            </div>

                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <input type="checkbox" key={i} id={`isShow${i}`} name="isShow" value={i} checked={x.pushHome ? "checked" : ""} onChange={() => pushHomeChange(i, x.pushHome)} />
                                                    <label htmlFor={`isShow${i}`}></label>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div className="btn btn-icon" onClick={(e) => openModalEdit(i)} >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-2">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => CarouselSortUp(x.caroID)}
                                                    >
                                                        <i className="icon icon-gray  icon-26"></i>
                                                    </div>
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => CarouselSortDown(x.caroID)}
                                                    >
                                                        <i className="icon icon-gray icon-25"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-edit" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">新增/編輯輪播廣告</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-item ">
                                    <div className="field">廣告標題</div>
                                    <div className="value">
                                        <input type="text" value={caroCaption} onChange={(e) => { setCaroCaption(e.target.value) }} />
                                    </div>
                                </div>

                                <div className="form-item"  >
                                    <div className="value" onChange={handleChange} >
                                        <label for="file" style={{ cursor: 'pointer' }}><i className="icon icon-27" ></i>上傳圖檔</label>
                                        <input type="file"  id="file" accept="image/*" style={{ display: 'none' }}  />
                                    </div>
                                    <div className="form-border">
                                        <img src={demoPic} id="viewImg" ></img>
                                        <div>{file  }</div>
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field">鏈接網址</div>
                                    <div className="value multi-inline">
                                        <input type="text" value={caroUrl} onChange={(e) => { setCaroUrl(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="btn-holder text-center">
                                <a className="btn" onClick={upData}>
                                    <span>確認</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>放棄</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-ok" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">
                                {modalStr}
                            </div>
                            <div className="btn-holder">
                                <a className="btn" data-close >
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-ok" className="modal small" data-modal>
                <div className="modal-container">
                    <div className="modal-body">
                        <div className="icon-holder">
                            <i className="icon icon-43"></i>
                        </div>
                        <div className="text-holder">
                            {modalStr}
                        </div>
                        <div className="btn-holder">
                            <a className="btn" data-close>
                                <span>確認</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CarouselBase;
