import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_Get_NoticeItem,
    API_Put_CouNoget,
    API_Get_NoticeSetDataG,
    API_Put_NoticeSetG_Dput,
    API_Post_NoticeSetG_Mget,
    API_Post_NoticeSetGpost,
    API_Get_NoticeSendData,
    API_Put_NoticeSetG_Mput,
    API_Put_NoticeSend_Dput,
    API_Post_NoticeSendpost,
    API_Post_GetSendNumber,
    API_Post_DeptNoticeTest
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import Loading from "../Loading/Loading";

function InformSchedule() {
    var history = useHistory();

    useEffect(() => {
        getData();

        
    }, []);

    function getData() {
        API_Get_NoticeSetDataG().then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    setDataList(data.result);
                }
                else {
                    setDataList([]);
                }
            })
            .catch(err => {
                console.log(err);

            })

        API_Get_NoticeSendData().then((response) => response.data)
            .then((data) => {
                //console.log(data);
                
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    setDataList2(data.result);
                }
                else {
                    setDataList2([]);
                }
            })
            .catch(err => {
                console.log(err);

            })

    }
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [tmpIndex2, setTmpIndex2] = useState();
    const [dataList, setDataList] = useState([]);
    const [dataList2, setDataList2] = useState([]);
/*    const [noticeType, setNoticeType] = useState();*/
    const [noticeID, setNoticeID] = useState();
    const [noticeSID, setNoticeSID] = useState();
    const [noticeName, setNoticeName] = useState("");
    const [noticeEmail, setNoticeEmail] = useState("");
    const [noticeSMS, setNoticeSMS] = useState("");
    const [SMSLen, setSMSLen] = useState(0);//計算字數

    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉

    const [CouNoArrayList, setCouNoArrayList] = useState([{}]);//課程清單
    const [DropDownCouNo, setDropDownCouNo] = useState(""); //課程下拉

    const [regDPArrayList, setregDPArrayList] = useState([{}]);//通知對象
    const [DropDownRegDP, setDropDownRegDP] = useState(""); //通知對象下拉

    const [CouPName, setCouPName] = useState(""); //期別名稱
    //const [CouPID, setCouPID] = useState("");//期別代號
    const [CouName, setCouName] = useState(""); //課程名稱
    //const [CouNo, setCouNo] = useState("");//課程代號
    const [NoticeSType, setNoticeSType] = useState("");//--A-即時;B-排程
    const [NoticeSendDate, setNoticeSendDate] = useState("");//預計發送時間
    //const [DPCode, setDPCode] = useState("");//通知對象
    const [NoticeNType, setNoticeNType] = useState("00");//發送方式--00-無;10-簡訊;01-郵件通知;11-簡訊及郵件通知
    const [cnt, setCnt] = useState(0);//--人數

      //驗證
    const [NoticeSTypeError, setNoticeSTypeError] = useState(false);//--A-即時;B-排程
    const [NoticeSendDateError, setNoticeSendDateError] = useState(false);//預計發送時間
    const [NoticeNTypeError, setNoticeNTypeError] = useState(false);//發送方式

    //Loading
    const [isLoading, setIsLoading] = useState(false);
    //存儲模板
    function Edit() { 

        //驗証必填欄位
        var result = formRequired("G");
        if (result) {
            if (!noticeID) {
                //console.log('add');
                API_Post_NoticeSetGpost({
                    NoticeName: noticeName,
                    NoticeSMS: noticeSMS,
                    NoticeEmail: noticeEmail
                }).then((response) => response.data)
                    .then((data) => {
                        //console.log(data);
                        if (data.code == "0000") {
                            //window.location.reload();
                            openModalSuccess("更新", data.message);
                        } else {
                            openModalError("更新", data.message);
                        }
                        getData();
                    })
                    .catch(err => {
                        //console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
            } else {
                //console.log('edit');
                //console.log(noticeID);
                //console.log(noticeType);
                API_Put_NoticeSetG_Mput({
                    NoticeID: noticeID,
                    NoticeName: noticeName,
                    NoticeSMS: noticeSMS,
                    NoticeEmail: noticeEmail
                }).then((response) => response.data)
                    .then((data) => {
                        //console.log(data);
                        if (data.code == "0000") {
                            //window.location.reload();

                            API_Post_NoticeSetG_Mget({
                                TempYN: "Y",
                                NoticeID: data.result[0].noticeID
                            }).then((response) => response.data)
                                .then((data) => {
                                    console.log(data);
                                    /* setDataList2(data.result);*/
                                    setNoticeID(data.result[0].noticeID);
                                    setNoticeName(data.result[0].noticeName);
                                    setNoticeEmail(data.result[0].noticeEmail);
                                    setNoticeSMS(data.result[0].noticeSMS);
                                    getData();
                                    //setSMSLen(data.result[0].NoticeSMS.length);
                                })
                                .catch(err => {
                                    console.log(err);

                                })

                            openModalSuccess("更新", data.message);
                        } else {
                            openModalError("更新", data.message);
                        }
                    })
                    .catch(err => {
                        //console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
            }
        }
    }
     //驗證
    function formRequired(type) {
        var result = true;

        if (type == "G") {
            const requiredlist = document.querySelectorAll(".Gtype");

            requiredlist.forEach((element) => {

                if (element.value == "") {
                    element.classList.add("error");
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            });
        } else if (type == "S") {
            const requiredlist = document.querySelectorAll("select[data-required]");
            requiredlist.forEach((element) => {

                if (element.value == "") {
                    element.classList.add("error");
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            });

            if (NoticeSType == "") {
                result = false;
                setNoticeSTypeError(true);
            } else {
                setNoticeSTypeError(false);
                if (NoticeSType == "B") {
                    let todaysDate = new Date();

                    if (NoticeSendDate == "" || NoticeSendDate == null) {
                        result = false;
                        setNoticeSendDateError(true);
                    } else {
                        setNoticeSendDateError(false);
                        //驗證{預計發送日期}是否大於等於{當天}
                        if (new Date(NoticeSendDate) <= todaysDate) {
                            result = false;
                            setNoticeSendDateError(true);
                        } else {
                            setNoticeSendDateError(false);
                        }
                    }
                }
            }

            if (NoticeNType == "00") {
                result = false;
                setNoticeNTypeError(true);
            } else {
                setNoticeNTypeError(false);
            }
        } else if(type="T"){ //測試
            const requiredlist = document.querySelectorAll(".test");
            requiredlist.forEach((element) => {

                if (element.value == "") {
                    element.classList.add("error");
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            });
        }
        
        

       
        return result;
    }
    //刪除 一般通知模板
    function delDataG() { 
        API_Put_NoticeSetG_Dput({
            NoticeID: dataList[tmpIndex].noticeID
        }).then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (data.code == "0000") {
                    window.location.reload();
                } else {
                    openModalError("刪除", data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //刪除 通知排程
    function delDataS() { 
        API_Put_NoticeSend_Dput({
            NoticeID: dataList2[tmpIndex2].NoticeID
        }).then((response) => response.data)
            .then((data) => {
                //console.log(data);
                if (data.code == "0000") {
                    window.location.reload();
                } else {
                    openModalError("刪除", data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function openModalCheck(tmpIndex, type) {
        if (type == "G") {
            setTmpIndex(tmpIndex);
            var id = "modal-check-G";
        } else {
            setTmpIndex2(tmpIndex);
            var id = "modal-check-S";
        }
       
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalSuccess(title) {
        setModalTit(title);

        var id = "modal-successs";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex, type) {
        //setNoticeType(type);
        Cancel();
        if (type == "G") {
            setTmpIndex(tmpIndex);
            selNotice("");
            API_Post_NoticeSetG_Mget({
                TempYN: "Y",
               NoticeID: dataList[tmpIndex].noticeID
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    /* setDataList2(data.result);*/
                    setNoticeID(data.result[0].noticeID);
                    setNoticeName(data.result[0].noticeName);
                    setNoticeEmail(data.result[0].noticeEmail);
                    setNoticeSMS(data.result[0].noticeSMS);
                    setSMSLen(data.result[0].noticeSMS.length);
                })
                .catch(err => {
                    console.log(err);
                })
            
        } else if (type == "S") {
            setTmpIndex2(tmpIndex);
            selNotice("");
            API_Post_NoticeSetG_Mget({
                TempYN: "N",
                NoticeSID: dataList2[tmpIndex].NoticeSID
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    /* setDataList2(data.result);*/
                    setNoticeID(data.result[0].NoticeID);
                    setNoticeSID(data.result[0].NoticeSID);
                    setNoticeName(data.result[0].NoticeName);
                    setNoticeEmail(data.result[0].NoticeEmail);
                    setNoticeSMS(data.result[0].NoticeSMS);
                    setSMSLen(data.result[0].NoticeSMS.length);

                    setCouPName(data.result[0].CouPName);

                    setDropDownCouPID(data.result[0].CouPID);
                    selCouPID(data.result[0].CouPID);

                    setDropDownCouNo(data.result[0].CouNO);
                    selCouNo(data.result[0].CouPID,data.result[0].CouNO);

                    setDropDownRegDP(data.result[0].DPCode);
                    selRegDP(data.result[0].DPCode);

                    setCouName(data.result[0].CouName);
                    setNoticeSType(data.result[0].NoticeSType);
                    setNoticeSendDate(data.result[0].NoticeSendDate);
                    
                    setNoticeNType(data.result[0].NoticeNType);
                    setCnt(data.result[0].cnt);
                })
                .catch(err => {
                    console.log(err);

                })


        } else {
            selNotice("");
            setNoticeID(0);
            setNoticeName("");
            setNoticeEmail("");
            setNoticeSMS("");
            setSMSLen(0);

            setDropDownCouPID("");
            setDropDownCouNo("");
            setDropDownRegDP("");

            setCouPName("");
            setCouName("");
            setNoticeSType("");
            setNoticeSendDate("");
            setNoticeNType("00");
            setCnt(0);
        }
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    //計算字數
    function SMSLength(e) {
        //console.log(e.target.value);
        setNoticeSMS(e.target.value);
        setSMSLen(e.target.value.length);
    }
    //下拉選單 期別
    function selNotice(type) {
        API_Get_NoticeItem()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
     //下拉選單 選定期別 連動班別序號
    function selCouPID(PID) {
        setDropDownCouPID(PID);
        console.log(PID);
        API_Put_CouNoget({
            CouPID: PID
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setCouNoArrayList(data.result);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //下拉選單 選定期別+班別序號 連動 通知對象與人數
    function selCouNo(PID, No) {
        setDropDownCouNo(No);
        
        API_Post_GetSendNumber({
            CouPID: PID,
            CouNo: No
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setregDPArrayList(data.result);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //下拉選單 選定期別+班別序號+通知對象 連動人數
    function selRegDP(RegDP) {
        setDropDownRegDP(RegDP);

        regDPArrayList.forEach((element) => {
            //console.log(element.DPCode);
            if (element.DPCode == RegDP) {
                setCnt(element.PeopleNumber);
            }
        });
    }
    //取消按鈕
    function Cancel() {
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });

        selNotice("");
        setNoticeID(0);
        setNoticeName("");
        setNoticeEmail("");
        setNoticeSMS("");
        setSMSLen(0);

        setDropDownCouPID("");
        setDropDownCouNo("");
        setDropDownRegDP("");

        setCouPName("");
        setCouName("");
        setNoticeSType("");
        setNoticeSendDate("");
        setNoticeNType("00");
        setCnt(0);

        setNoticeSTypeError(false);
        setNoticeSendDateError(false);
        setNoticeNTypeError(false);

        getData();
    }
    //單選接鈕 選立即發送 無需選定發送日期
    function handleshowB(e) {
       
        setNoticeSType(e);

        if (e == "A") {
            setNoticeSendDate(); //預計發送時間
        }
    }
    //確認按鈕
    function UpData() {
        setIsLoading(true);
        //console.log(NoticeNType);
        //if (NoticeNType == "B") {
        //    console.log(NoticeSendDate);
        //}
       
        //驗証必填欄位
        var result = formRequired("S");
        if (result) {
            API_Post_NoticeSendpost({
                NoticeName: noticeName,
                NoticeSMS: noticeSMS,
                NoticeEmail: noticeEmail,
                CouPID: DropDownCouPID,
                CouNo: DropDownCouNo,
                DPCode: DropDownRegDP,
                NoticeSType: NoticeSType,
                NoticeSendDate: NoticeSendDate,
                NoticeNType: NoticeNType
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        //window.location.reload();
                        setNoticeSID(data.result);
                        openModalSuccess("更新", data.message);
                    } else {
                        openModalError("更新", data.message);
                    }
                    setIsLoading(false)

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                    setIsLoading(false)
                })

        }
    }
    //測試按鈕
    function TestNotice() {
        //驗証必填欄位
        var result = formRequired("T");
        console.log(noticeID);
        console.log(noticeName);
        setIsLoading(true);
        if (result) {
            API_Post_DeptNoticeTest({
                CouPID: DropDownCouPID,
                CouNO: DropDownCouNo,
                NoticeID: noticeID,
                NoticeName: noticeName,
                NoticeSMS: noticeSMS,
                NoticeEmail: noticeEmail
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        openModalSuccess("發送", data.message);
                    } else {
                        openModalError("發送", data.message);
                    }
                    setIsLoading(false);

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                    setIsLoading(false);
                })
        }
    }
    function close() {
        getData();
    }
    return (
        <>
            <Loading isLoading={ isLoading }></Loading>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB">
                                <h3 className="fz-A mr-1">排程即時通知</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="info-holder">
                               <div className="left">
                                    <h5 className="mr-2 half">一般通知模板</h5>
                                </div>
                                <div className="right fc-1">
                                    <div
                                        className="btn btn-icon"
                                        onClick={openModalEdit} >
                                        <i className="icon icon-14 mr-1"></i>                                  
                                    </div>
                                    <span className="fw-b">新增通知</span>
                                </div>
                            </div>
                           
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-2">通知標題</div>
                                        <div className="flex-6">通知內容</div>
                                        <div className="flex-0">&ensp; 編 輯&nbsp;</div>
                                        <div className="flex-0">&ensp; 刪 除&nbsp;</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-2">
                                                {x.noticeName }
                                            </div>
                                            <div className="flex-6">
                                                {x.noticeEmail }
                                            </div>
                                            <div className="flex-0 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalEdit(i, "G")} 
                                                    >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-0">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i, "G")}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="info-holder">
                                <div className="left">
                                    <h5 className="mt-1 fw-b">通知排程</h5>
                                </div>                              
                            </div>

                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-2">通知標題</div>
                                        <div className="flex-5">通知內容</div>
                                        <div className="flex-2">預定發送時間</div>
                                        <div className="flex-0">&ensp; 編 輯&nbsp;</div>
                                        <div className="flex-0">&ensp; 刪 除&nbsp;</div>

                                    </li>
                                    {dataList2.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-2">
                                                {x.NoticeName }
                                            </div>
                                            <div className="flex-5">
                                                {x.NoticeEmail}
                                            </div>

                                            <div className="flex-2">
                                                {x.NoticeSendDate}
                                            </div>
                                            <div className="flex-0 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalEdit(i, "S")}
                                                    >
                                                        <i className="icon icon-24"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-0">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i, "S")}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-edit" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">新增/編輯/發送一般通知</h3>
                        </div>

                        <div className="modal-body">
                            <div className="form-container">

                                <div className="form-item half">
                                    <div className="field fw-b">通知標題</div>
                                    <div className="value">
                                        <input className="Gtype" type="text" value={noticeName} onChange={(e) => setNoticeName(e.target.value)} data-required />
                                    </div>
                                </div>
                                

                                <div className="form-item col my-1">
                                    <div className="field fw-b">簡訊內容    <span className="text-red ml-1">注意：請注意總字數不得超過70個字（包括標點符號）。</span></div>
                                    <div className="top-value my-1">
                                        <textarea className="Gtype" name="" id="" cols="50" rows="5" value={noticeSMS} onChange={(e) => SMSLength(e)} data-required></textarea>
                                        <span style={{ float: 'right' }}>字數統計 {SMSLen}/70</span>
                                    </div>

                                </div>
                                <div className="form-item col ">
                                    <div className="field fw-b">郵件內容</div>
                                    <div className="top-value my-1">
                                        <textarea className="Gtype" name="" id="" cols="50" rows="10" value={noticeEmail} onChange={(e) => setNoticeEmail(e.target.value)} data-required></textarea>
                                    </div>

                                </div>

                                <div className="form-item my-1 half">
                                    <span>下列代碼將會從資料庫讀取對應字串並取代：<br />
                                        {"{/名字/}"}&emsp; &emsp; &ensp; 學員姓名<br />
                                        {"{/課程名稱/}"}&emsp; 課程名稱<br />
                                        {"{/繳費期限/}"}&emsp; 繳費期限<br />
                                        {"{/退費日期/}"}&emsp; 核准退費日期<br />
                                        {"{/繳費日期/}"}&emsp; 繳費日期</span>
                                </div>
                                <div className="form-item half">
                                    <div className="field">
                                        <a className="btn" onClick={ Edit }>
                                        <span>存儲模板</span>
                                    </a>
                                    </div>
                                </div>
                                <div className="form-container mt-1">

                                    <div className="form-item field fw-b ml-2" style={{ alignItems: "left" }}> 選擇通知對象 </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">期別</div>
                                        <div className="top-value">
                                            <select className="test" value={DropDownCouPID} onChange={(e) => { selCouPID(e.target.value) }} data-required>
                                                <option value="">請選擇</option>
                                                {coursePeriodArrayList.map((y) => (
                                                    <option value={y.id}>{y.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">班別序號</div>
                                        <div className="top-value">
                                            <select className="test" value={DropDownCouNo} onChange={(e) => { selCouNo(DropDownCouPID, e.target.value) }} data-required >
                                                <option value="">請選擇</option>
                                                <option value="00">全選</option>
                                                {CouNoArrayList.map((y) => (
                                                    <option value={y.CouNo}>{y.CouName} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">通知對象</div>
                                        <div className="top-value">
                                            <select value={DropDownRegDP} onChange={(e) => { selRegDP(e.target.value) }} data-required>
                                                <option value="">請選擇</option>
                                                {regDPArrayList.map((y) => (
                                                    <option value={y.DPCode}>{y.CodeText} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-item twenty  multi-inline">
                                        <div className="top-table">共計：</div>
                                        <div className="top-value fw-b">
                                            <div>
                                                <input type="text" style={{ width: '80px' }} value={cnt} disabled="true" />人
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-item half">
                                       
                                        <div className="value multi-inline fw-b">
                                            <input type="radio" id="f221-1" name="f221" value="A" checked={NoticeSType === 'A'} onChange={(e) => handleshowB("A")} data-required />
                                            <label for="f221-1">立即發送</label>
                                            <input type="radio" id="f221-2" name="f221" value="B" checked={NoticeSType === 'B'} onChange={(e) => handleshowB("B")} data-required />
                                            <label for="f221-2" className="ml-2">排程發送</label>
                                                
                                            <div className="field ml-2 my-1">預計發送日期</div>
                                            <div className="value my-1">
                                                <input type="datetime-local" style={{ width: '235px' }} disabled={NoticeSType == "A" ? true : false} value={NoticeSendDate} onChange={(e) => { setNoticeSendDate(e.target.value) }} />
                                            </div>
                                            
                                            <div className="value my-1">
                                                <span className="text-red">注意：預計發送日期需從「隔日」開始設定。</span> 
                                            </div>
                                        </div>
                                        <span className={`form-item must ${NoticeSTypeError ? "show-help" : ""}`} style={{ width: '250px' }}>
                                            <span className="help-word" style={{ paddingLeft: '10px' }}> 請選擇發送時間(立即或排程)</span>
                                        </span>
                                        <span className={`form-item must twenty ${NoticeSendDateError ? "show-help" : ""}`} style={{ width: '250px' }}>
                                            <span className="help-word" style={{ paddingLeft: '10px' }}> 預計發送日期需從「隔日」開始設定</span>
                                        </span>
                                    </div>
                                    <div className="form-item ">
                                        <div className="top-table">發送方式 </div>
                                        <div className="value multi-inline">

                                            {NoticeNType == '10' || NoticeNType == '11'
                                                ? <input type="checkbox" id="f1021-1" name="f1021" onChange={(e) => {
                                                    // add to list
                                                    if (e.target.checked) {
                                                        if (NoticeNType == '01') {
                                                            setNoticeNType('11');
                                                        } else {
                                                            setNoticeNType('10');
                                                        }

                                                    } else {
                                                        if (NoticeNType == '11') {
                                                            setNoticeNType('01');
                                                        } else {
                                                            setNoticeNType('00');
                                                        }
                                                    }
                                                }} checked={NoticeNType[0] == "1" ? true : false} />
                                                : <input type="checkbox" id="f1021-1" name="f1021" onChange={(e) => {
                                                    // add to list
                                                    if (e.target.checked) {
                                                        if (NoticeNType == '01') {
                                                            setNoticeNType('11');
                                                        } else {
                                                            setNoticeNType('10');
                                                        }

                                                    } else {
                                                        if (NoticeNType == '11') {
                                                            setNoticeNType('01');
                                                        } else {
                                                            setNoticeNType('00');
                                                        }
                                                    }
                                                }} checked={NoticeNType[0] == "1" ? true : false} />}
                                            <label for="f1021-1" className="mr-1">簡訊通知</label>

                                            {NoticeNType == '01' || NoticeNType == '11'
                                                ? <input type="checkbox" id="f1021-2" name="f1021" value="01" onChange={(e) => {
                                                    // add to list
                                                    if (e.target.checked) {

                                                        if (NoticeNType == '10') {
                                                            setNoticeNType('11');
                                                        } else {
                                                            setNoticeNType('01');
                                                        }

                                                    } else {
                                                        if (NoticeNType == '11') {
                                                            setNoticeNType('10');
                                                        } else {
                                                            setNoticeNType('00');
                                                        }

                                                    }
                                                }} checked={NoticeNType[1] == "1" ? true : false} />
                                                : <input type="checkbox" id="f1021-2" name="f1021" value="01" onChange={(e) => {
                                                    // add to list
                                                    if (e.target.checked) {
                                                        if (NoticeNType == '10') {
                                                            setNoticeNType('11');
                                                        } else {
                                                            setNoticeNType('01');
                                                        }

                                                    } else {
                                                        if (NoticeNType == '11') {
                                                            setNoticeNType('10');
                                                        } else {
                                                            setNoticeNType('00');
                                                        }
                                                    }
                                                }} checked={NoticeNType[1] == "1" ? true : false} />}
                                            <label for="f1021-2">郵件通知</label>

                                        </div>
                                    </div>
                                    <span className={`form-item must twenty ${NoticeNTypeError ? "show-help" : ""}`} style={{ width: '250px' }}>
                                        <span className="help-word" style={{ paddingLeft: '30px' }}> 請選擇發送方式</span>
                                    </span>
                                </div>
                                <div className="form-item btn-area sp">
                                    <div className="left">
                                        <a className="btn" onClick={UpData}>
                                            <span>確認</span>
                                        </a>
                                        <a className="btn btn-border" data-close onClick={Cancel}>
                                            <span>取消</span>
                                        </a>
                                        <a className="btn" onClick={TestNotice}>
                                            <span>測試</span>
                                        </a>
                                        {/*<a className="btn btn-border" data-close onClick={close}>*/}
                                        {/*    <span>關閉</span>*/}
                                        {/*</a>*/}
                                    </div>
                                    <div className="right">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-check-G" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delDataG}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-check-S" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delDataS}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
                <div id="modal-successs" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}成功</div>
                        </div>
                    </div>
                </div>
            </div>

           


        </>
    );
}
export default InformSchedule;
