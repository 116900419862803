import '../../css/style.css';
import React, { useState } from 'react';
//import { API_Login } from '../../global/constants';
//import { useHistory } from 'react-router-dom';
//import ClientCaptcha from 'react-client-captcha';

function Loading({ isLoading }) {
    return (
        <>
            {isLoading ? <>
                <div class="loading-background"></div>
                <div class="loading-screen">
                    <div class="spinner"></div>
                </div>
            </> : null}
           
        </>
       
    );
}
export default Loading;
