import "../css/style.css";
import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function login() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const path = queryParams.get("path");
  useEffect(() => {
    document.getElementById("root").classList.add("center");
    document.body.classList.remove("dashboard");
    document.body.classList.add("login");
    //檢查是否有登入過(完整資料) 有的話導頁回去dashboard ,有缺的話全部清除
    //檢查是否有登入過(完整資料) 有的話導頁回去dashboard ,有缺的話全部清除
    const islogin = localStorage.getItem("islogin");
    const user = localStorage.getItem("user");
      //console.log(token, user, username, userID, menulist, authctrl, notify);
      console.log(islogin);
      if (islogin == "1") {
          localStorage.removeItem("menuitem");
          localStorage.removeItem("submenuitem");
          history.push("/Main");
      } else {
          if (path != "authenticator" && path != "changepassword" && user == null) {
              localStorage.clear();
          }
      }
  },[]);
  return (
    <div className="full center">
      <div className="login-box">
        <div className="title">
          {/*<i className="icon icon-31"></i>*/}
          <h1 className="fz-hero">
            <FormattedMessage id="systemName" /> {/*系統名稱*/}
          </h1>
        </div>
        <div className="subtitle">
          <h2 className="fz-B"></h2>
          <h3 className="fz-C"></h3>
        </div>
        <div action="" className="form-container">
          {(() => {
            if (path == null) {
              return <Loginform />;
            } else {
              switch (path.toLowerCase()) {
                case "authenticator":
                  return <Authenticator />;
                case "changepassword":
                  return <ChangePasswordform />;
                default:
                  return <Loginform />;
              }
            }
          })()}
        </div>
      </div>
    </div>
  );
}
export default login;
