import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Put_CourseType, CourseTypeData, API_POST_CourseTypeData, API_Put_CourseTypeData } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
function CourseClassType() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        CourseTypeData({})
            .then((response) => response.data)
            .then((data) => {
                
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }            
                if (data.code == "0000") {
                    //console.log(data);
                    setDataList(data.result);
                }
                else if (data.result == null) {
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != 0);                       
                    });
                    alert(data.message);
                }
                else {                 
                    alert(data.message);
                }              
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [type, settype] = useState(0);
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([{}]);
    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) { //按下的是新增
            //呼叫API新增
            API_POST_CourseTypeData({
                CouTypeName: dataList[index].name,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        getData();
                        settype(0);
                    } else {
                        alert(data.message);
                    }
                    //console.log(localStorage.getItem('menulist'));
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else if (type == 1) {
             setDataList(function (prev) {
                 return prev.map((item, i) => {                 
                     if (i == index) {
                        if (item.isEdit == true) {  
                            API_Put_CourseTypeData({
                                CouTypeID: item.id,
                                CouTypeName: item.name,
                                CouTypeInvalid: false
                            }).then((response) => response.data)
                                .then((data) => {
                                    console.log(data);
                                    if (data.code == "0000") {//成功
                                        //getData();   
                                        Edit(0, i);
                                    } else {
                                        setMsgStr(data.message);
                                        getData();
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })                               
                        }
                         item.isEdit = type == 1 ? true : false;

                    }
                    return item;
                });
            });
        }
        else {
            settype(0);
             setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            item.isEdit = false;
                            if (item.id == null) {
                                getData();
                            }
                            
                        }
                    }
                    return item;
                });
            });
        }

    }
    function delData() {
        //呼叫API
       
        //成功
        setDataList(function (prev) {
            API_Put_CourseTypeData({
                CouTypeID: dataList[tmpIndex].id,
                CouTypeInvalid: true
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {//成功
                        getData();
                    } else {
                        alert(data.message);
                        getData();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function Add(e) {
        settype(2);
        setDataList(function (prev) { 
                return [...prev, { id: null, name: null, isEdit: true }];            
        });
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function nameChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.name = e.target.value;
                }
                return item;
            });
        });
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB mid">
                                <h3 className="fz-A mr-1">課程班別維護</h3>
                            </div>
                        </div>
                        <div className="container mid">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">ID</div>
                                        <div className="flex-3">班別名稱</div>
                                        <div className="flex-1">&ensp;編輯</div>
                                        <div className="flex-1">&ensp;刪除</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">{i + 1}</div>
                                            <div className="flex-3">
                                                {x.isEdit == true ? (
                                                    <input type="text" value={x.name} onChange={(e) => nameChg(e, i)} />
                                                ) : (
                                                    x.name
                                                )}
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">

                                                    {
                                                        x.id == null ?
                                                            //<div class="btn btn-small btn-border" onClick={(e) => Edit(2, i)}>
                                                            //    <span>確定</span>
                                                            //</div>
                                                            <div className="btn btn-icon" >
                                                                {/*<span>確定</span>*/}
                                                                <i className="icon icon-orange icon-16" onClick={(e) => Edit(2, i)}></i>
                                                                <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                            </div>
                                                            :
                                                            x.isEdit == true ?
                                                                <div className="btn btn-icon" >
                                                                    {/*<span>確定</span>*/}
                                                                    <i className="icon icon-orange icon-16" onClick={(e) => Edit(1, i)}></i>
                                                                    <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>                                                                 
                                                                </div>
                                                                
                                                                :
                                                                <div className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                                    <i className="icon icon-24"></i>
                                                                </div>
                                                    }


                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => openModalCheck(i)}
                                                    >
                                                        <i className="icon icon-4"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="lt-row">
                                        <div className="btn btn-icon"
                                            onClick={type == 0 ? Add : null}>

                                            <i className="icon icon-14" ></i>

                                        </div>
                                        <span>新增</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-orange icon-9"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CourseClassType;
