import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_POST_StudyInFo } from "../../global/constants";
import { API_POST_AddStudy } from "../../global/constants";
import { API_Put_EditStudy } from "../../global/constants";
import { API_POST_ViewStudy } from "../../global/constants";
import { useHistory } from "react-router-dom";
/*import TWzipcode from 'react-twzipcode';*/
import { OpenModal, CloseModal } from "../../global/ToolUtil";


function TraineeList() {
    
    //縣市
    function setArea(CityNo) {
        
        if (CityNo == "1") {
            setAreaList(
                [
                    { Value: 1, Text: "中區" },
                    { Value: 2, Text: "東區" },
                    { Value: 3, Text: "南區" },
                    { Value: 4, Text: "西區" },
                    { Value: 5, Text: "北區" },
                    { Value: 6, Text: "北屯區" },
                    { Value: 7, Text: "西屯區" },
                    { Value: 8, Text: "南屯區" },
                    { Value: 9, Text: "太平區" },
                    { Value: 10, Text: "大里區" },
                    { Value: 11, Text: "霧峰區" },
                    { Value: 12, Text: "烏日區" },
                    { Value: 13, Text: "豐原區" },
                    { Value: 14, Text: "后里區" },
                    { Value: 15, Text: "石岡區" },
                    { Value: 16, Text: "東勢區" },
                    { Value: 17, Text: "和平區" },
                    { Value: 18, Text: "新社區" },
                    { Value: 19, Text: "潭子區" },
                    { Value: 20, Text: "大雅區" },
                    { Value: 21, Text: "神岡區" },
                    { Value: 22, Text: "大肚區" },
                    { Value: 23, Text: "沙鹿區" },
                    { Value: 24, Text: "龍井區" },
                    { Value: 25, Text: "梧棲區" },
                    { Value: 26, Text: "清水區" },
                    { Value: 27, Text: "大甲區" },
                    { Value: 28, Text: "外埔區" },
                    { Value: 29, Text: "大安區" }
                ]
            );

        }
        else  {
            setAreaList([{ Value: 1, Text: "其他" }]);
        }
    }


    const [AreaList, setAreaList] = useState([{}]);
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([]);
    const [dataListView, setDataListView] = useState([{}]);
    const [InqUserName, setInqUserName] = useState();
    const [InqUserNo, setInqUserNo] = useState();
    const [UserIDNo, setUserIDNo] = useState();
    const [UserName, setUserName] = useState();
    const [UserPhone, setUserPhone] = useState();
    const [UserSex, setUserSex] = useState("");
    const [UserEmail, setUserEmail] = useState();
    const [BirDay, setBirDay] = useState('1990-01'); //生日
    const [UserCity, setUserCity] = useState(); //縣市
    const [UserArea, setUserArea] = useState(); //區
    const [UserContact, setUserContact] = useState();
    const [UserContactPhone, setUserContactPhone] = useState();
    const [UserAcntID, setUserAcntID] = useState();
    const [UserIsForeig, setUserIsForeig] = useState();
    const [ViewType, setViewType] = useState(false);
    const [ViewType2, setViewType2] = useState(false);

    const [ErrorMsgPhone, setErrorPhone] = useState("");
    const [ErrorMsgEmail, setErrorEmail] = useState("");
    const [UserSexError, setUserSexError] = useState(false); //驗証{性別}

    const [AreaNo, setAreaNo] = useState('');
    const [CityNo, setCityNo] = useState('');

    var history = useHistory();

    useEffect(() => {
        
        setArea(CityNo);

    }, [CityNo]);

    useEffect(() => {
      
      /*  getData();*/  //避免資料太大 載入畫面時 先不撈研習之友資料 查詢後 才可以做維護
        //const timeout = setTimeout(() => {
        //    document.getElementById("menuitem-14").classList.add("active");

        //    // 變更active的icon
        //    const icon12 = document.getElementById("menuitem-14").childNodes[0].childNodes[2];
        //    icon12.classList.remove("icon-12");
        //    icon12.classList.add("icon-13");

        //    document.getElementById("submenuitem-15").classList.add("active");
        //}, 100)
        //return () => clearTimeout(timeout)

    }, []);


    function getData(type) {
        var acuntNo = type;
       
        //console.log(acuntNo);
        if (acuntNo != null) {
            API_POST_ViewStudy({ //檢視
                AcntID: acuntNo,
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data.result);
                    if (data.code == "0000") {

                       
                        console.log(data.result[0].CityNo);
                        console.log(data.result[0].AreaNo);
                        console.log(data.result[0].BirDay);
                      
                        /*setAreaNo(AreaNo);*/
                        setDataListView(data.result);
                        setUserIDNo(data.result[0].UserIDNo);
                        setUserName(data.result[0].UserName);
                        setUserPhone(data.result[0].UserPhone);
                        setUserSex(data.result[0].Usersex);
                        setUserEmail(data.result[0].email);
                        setBirDay(data.result[0].BirDay); //生日

                        setCityNo(data.result[0].CityNo); //市 台中市          
                        setAreaNo(data.result[0].AreaNo); //區 北區
                    
                        setUserContact(data.result[0].Contact);
                        setUserContactPhone(data.result[0].ContactPhone);
                        setUserAcntID(data.result[0].AcntID);
                        setUserIsForeig(data.result[0].IsForeig == "0" ? false : true);
                        
                       
                    } else {
                        dataList.clear;
                        console.log(data.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            API_POST_StudyInFo({  //查詢
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        setDataList(data.result);

                    } else {
                        console.log(data.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }
  
    function inqData(e) {

        if (InqUserName != null || InqUserNo != null) {

            var dt =
            {
                AcntNo: InqUserNo,
                AcntName: InqUserName
            };
            console.log(dt);
            API_POST_StudyInFo(dt).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                  
                    if (data.code == "0000") {  //成功
                        setDataList(data.result);
                    } else {
                        alert(data.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                })


        } else {
            alert("請輸入身分/居留證號或姓名！");
            e.preventDefault();
        }
    }

    function upData() {
        var resut = formRequired();

        //驗証{性別}是否填寫
        if (UserSex == '') {
            setUserSexError(true);

        } else {
            setUserSexError(false);
        }

        if (resut) {
            if (UserAcntID == "") {
                var dt = {
                    UserIDNo: UserIDNo,
                    UserName: UserName,
                    UserPhone: UserPhone,
                    UserSex: UserSex,
                    CityNo: CityNo,
                    AreaNo: AreaNo,
                    BirDay: BirDay + "-01",
                    Email: UserEmail,
                    Contact: UserContact,
                    ContactPhone: UserContactPhone,
                    Password: "0",
                    IsForeig: UserIsForeig,
                }

                API_POST_AddStudy(dt).then((response) => response.data)
                    .then((data) => {

                        if (data.code == "0000") {//成功
                            console.log(data.message);
                            alert(data.message);
                           
                            CloseModal("modal-edit");
                        } else {
                            alert(data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    })
            } else {

                console.log(CityNo);
                console.log(AreaNo);

                var dt = {
                    AcntID: UserAcntID,
                    UserIDNo: UserIDNo,
                    UserName: UserName,
                    UserPhone: UserPhone,
                    UserSex: UserSex,
                    CityNo: CityNo,
                    AreaNo: AreaNo,
                    BirDay: BirDay + "-01",
                    Email: UserEmail,
                    Contact: UserContact,
                    ContactPhone: UserContactPhone,
                    IsForeig: UserIsForeig,
                    Password: "123",
                }
                console.log(dt);
                API_Put_EditStudy(dt).then((response) => response.data)
                    .then((data) => {

                        if (data.code == "0000") {//成功
                            console.log(data.message);
                            alert(data.message);
                          
                            CloseModal("modal-edit");
                        } else {
                            alert(data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    })
            }
            //window.location.reload();
        }
    }

    function nameChg(e, vName) {

        switch (vName) {
            case "citycode":
                setUserCity(e.county);
                setUserArea(e.district);
                break;
            case "isforeig":
                setUserIsForeig(!UserIsForeig)
                break;
        }
    }

    //判斷 手機 & 信箱
    function formRequired() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        return result;
    }



    //新增學員的時候開啟燈箱
    function openModalEdit(e,tmpIndex,type) {
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });
        setUserSexError(false);
        console.log(tmpIndex);
        if (tmpIndex == null) {
            setUserIDNo("");
            setUserName("");
            setUserPhone("");
            setUserSex("");
            setUserEmail("");
            setUserContact("");
            setUserContactPhone("");
            setUserAcntID("");
            setCityNo("");
            setArea("");
            setUserIsForeig(false);
            setViewType(false);
            setViewType2(false);
        } else {
            if (type == "1") {
                setViewType(true);
                setViewType2(true);
            } else {
                setViewType(false);
                setViewType2(true);
            }
            getData(tmpIndex);
        }

        setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">研習之友資料維護</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="fc-1 full">
                                <div className="form-container wp9">
                                    <div className="form-item quarter">
                                        <div className="field">身分/居留證號：</div>
                                        <div className="field">
                                            <input type="text" onChange={(e) => setInqUserNo(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-item twenty">
                                        <div className="top-table">姓名</div>
                                        <div className="top-value">
                                            <input type="text" onChange={(e) => setInqUserName(e.target.value)} />
                                        </div>
                                    </div>

                                    <a className="btn btn-border btn-middle" onClick={(e) => inqData(e)}>
                                        <span>查詢</span>
                                    </a>
                                </div>

                                <div className="info-holder">
                                    <div className="right fc-1 ">
                                        <div
                                            className="btn btn-icon"
                                            onClick={openModalEdit}
                                        >
                                            <i className="icon icon-14 mr-1" ></i>
                                        </div>
                                        <span className="fw-b">新增學員</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {dataList.length == 0 ? null :
                            <div className="container full mt-1">
                                <div className="modal-body">
                                    <ul className="list-table">
                                        <li className="lt-row lt-th">
                                            <div className="flex-0">序號</div>
                                            <div className="flex-3">身分/居留證號</div>
                                            <div className="flex-2">姓名</div>
                                            <div className="flex-1">性別</div>
                                            <div className="flex-2">手機號碼</div>
                                            <div className="flex-2">緊急聯絡人</div>
                                            <div className="flex-2">緊急聯絡人電話</div>
                                            <div className="flex-1">&ensp; 檢視</div>
                                            <div className="flex-1">&ensp; 編輯</div>
                                        </li>
                                        {
                                            dataList.map((x, i) => (
                                                <li className="lt-row">
                                                    <div className="flex-0">{i + 1}</div>
                                                    <div className="flex-3">{x.UserIDNo}</div>
                                                    <div className="flex-2">{x.UserName}</div>
                                                    <div className="flex-1">{x.Usersex}</div>
                                                    <div className="flex-2">{x.UserPhone}</div>
                                                    <div className="flex-2">{x.Contact}</div>
                                                    <div className="flex-2">{x.ContactPhone}</div>
                                                    <div className="flex-1">
                                                        <div className="fn-bar">
                                                            <div
                                                                className="btn btn-icon"
                                                                onClick={(e) => openModalEdit(e, x.AcntID, "1")}
                                                            >
                                                                <i className="icon icon-20"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 fn">
                                                        <div className="fn-bar">
                                                            <div className="flex-1">
                                                                <div className="fn-bar">
                                                                    <div className="btn btn-icon" onClick={(e) => openModalEdit(e,
                                                                        x.AcntID, "2")} >
                                                                        <i className="icon icon-24"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>


                <div id="modal-edit" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">

                            {ViewType == true ? (
                                <h3 className="fz-B has-border">檢視</h3>
                            ) : (
                                <h3 className="fz-B has-border">新增/編輯學員</h3>
                            )}

                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-item multi-inline" style={{ fontSize: '14px' }}>
                                    請告知學員新增學員帳號及預設密碼為<label style={{ color: 'red' }}>身分/居留證號</label>，預設密碼第一個英文字母為大寫。
                                </div>

                                <div className="form-item ">
                                    <div className="field">身分/居留證號</div>
                                    <div className="value">
                                        <input type="text" value={UserIDNo} disabled={ViewType2} onChange={(e) => setUserIDNo(e.target.value)} data-required />
                                    </div>
                                </div>
                                <div className="form-item ">
                                    <div className="field">是否具外籍身分</div>
                                    <div className="value">
                                        <input type="checkbox" name="isForeig" id="isForeig" defaultChecked={UserIsForeig} disabled={ViewType2} onClick={(e) => nameChg(e, "isforeig")} />

                                        <label htmlFor="isForeig">是</label>
                                    </div>
                                </div>
                                <div className="form-item ">
                                    <div className="field">姓名</div>
                                    <div className="form-item half">
                                        <input type="text" value={UserName} disabled={ViewType} onChange={(e) => setUserName(e.target.value)} data-required />
                                    </div>
                                </div>

                                <div className="form-item ">
                                    <div className="field">出生年月</div>
                                    <div className="form-item half">
                                        <input type="month" value={BirDay} disabled={ViewType} onChange={(e) => setBirDay(e.target.value)} data-required />                                      
                                        </div>

                                </div>

                                <div className="form-item multi-inline">
                                    
                                    <div className="field">性別</div>
                                    
                                    <div className="form-item">
                                        <span className={`form-item must ${UserSexError ? "show-help" : ""}`} style={{ width: '400px' }}>
                                            <input type="radio" id="sexTypeM" name="sexType" value="M" disabled={ViewType} checked={UserSex == "M" ? true : ""} onChange={(e) => setUserSex(e.target.value)} data-required />
                                       
                                            <label htmlFor="sexTypeM" className="mr-1">男</label>
                                            <input type="radio" id="sexTypeF" name="sexType" value="F" disabled={ViewType} checked={UserSex == "F" ? true : ""} onChange={(e) => setUserSex(e.target.value)} data-required />
                                        
                                            <label htmlFor="sexTypeF" className="mr-1">女</label>
                                            <span className="help-word" style={{ width: '150px', paddingLeft:'10px' }} > 請選擇性別</span>
                                    </span>   
                                    </div>
                                       
                                </div>
                                
                              
                                <div className="form-item ">
                                    <div className="field">居住地區</div>
                                    <div className="form-item thirty" >
                                        <select name="CityNo" id="CityNo" tilte="請選擇縣市" disabled={ViewType} value={CityNo} onChange={e => { setCityNo(e.target.value), setAreaNo("") }}>
                                            <option value="" selected disabled>請選擇</option>
                                            <option value="1">臺中市</option>
                                            <option value="2">其他</option>
                                        </select>

                                        <select name="AreaNo" id="AreaNo" tilte="請選擇地區" disabled={ViewType} value={AreaNo} onChange={e => { setAreaNo(e.target.value) }}>
                                            <option value="" selected disabled>請選擇</option>
                                            {
                                                AreaList.map((x) => (
                                                    <option value={x.Value}>{x.Text}</option>
                                                ))
                                            }
                                        </select>

                                    </div>

                                </div>
                                
                                <div className="form-item" >
                                    <div className="field">手機號</div>
                                    <div className="value">
                                        <input type="text" value={UserPhone} disabled={ViewType} onChange={(e) => setUserPhone(e.target.value)} data-required />
                                    </div>
                                    <span className="help-word"> 請填入正確、有效的「手機號碼」(09開頭共10碼阿拉伯數字)</span>   
                                </div>

                               
                                <div className="form-item ">
                                    <div className="field">電子信箱</div>
                                    <div className="form-item half">
                                        <input type="text" value={UserEmail} disabled={ViewType} onChange={(e) => setUserEmail(e.target.value)} data-required />
                                    </div>
                                    <span className="help-word">請輸入正確的電子信箱</span>

                                </div>

                                <span className={`form-item ${ErrorMsgEmail ? "show-help" : ""}`}></span>

                                <div className="form-item ">
                                    <div className="field">緊急聯絡人姓名</div>
                                    <div className="form-item half">
                                        <input type="text" value={UserContact} disabled={ViewType} onChange={(e) => setUserContact(e.target.value)} data-required />
                                    </div>
                                </div>

                                <div className="form-item ">
                                    <div className="field">緊急聯絡人手機號</div>
                                    <div className="form-item half">
                                        <input type="text" value={UserContactPhone} disabled={ViewType} onChange={(e) => setUserContactPhone(e.target.value)} data-required />
                                    </div>
                                </div>
                                <div className="form-item fw-b">
                                    <input type="checkbox" id="f221-1" name="f221" value="f221-1" />
                                    <label for="f221-1">公務人員終身學習時數</label>
                                    <input type="checkbox" id="f221-2" name="f221" value="f221-2" />
                                    <label for="f221-2" className="ml-1">教師研習時數</label>
                                </div>


                            </div>
                            <br />
                            <br />
                            <div className="btn-holder text-center">

                                {ViewType == true ? (
                                    <div>
                                        <a className="btn" data-close><span>返回</span></a>
                                    </div>
                                ) : (
                                    <div>
                                        <a className="btn" onClick={upData}>
                                            <span>確認</span>
                                        </a>
                                        <a className="btn btn-border" data-close>
                                            <span>放棄</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default TraineeList;
