import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_Get_RefoundData, API_Get_RefundDataget, API_Put_RefundData, API_Get_DropDown, API_Get_Download, API_Post_RefundRpt } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";

function RefundList() {
    var history = useHistory();
    const FileDownload = require("js-file-download");
    const [PrevImg, setPrevImg] = useState('');

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        console.log(processed);
        API_Get_RefoundData({
            CouPID: DropDownCouPID,
            SDate: SDate,
            EDate: EDate,
            processed: processed
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    /*  alert(data.message);*/
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([{}]);
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [DropDownCouPID, setDropDownCouPID] = useState("0"); //期別下拉
    const [URegIDNo, setURegIDNo] = useState("");//身分/居留證號
    const [URegID, setURegID] = useState("");//使用者報名序號
    const [CouName, setCouName] = useState("");//報名課程
    const [UserName, setUserName] = useState("");//姓名
    const [CouStartDate, setCouStartDate] = useState("");//開課日期
    const [UserPhone, setUserPhone] = useState("");//手機號
    const [URFDate, setURFDate] = useState("");//申請日期
    const [Email, setEmail] = useState("");//電子信箱
    const [Days, setDays] = useState("");//距離開課
    const [URegInMoney, setURegInMoney] = useState("");//繳款金額
    const [URFCaption, setURFCaption] = useState("");//申請退費原因
    const [URFType, setURFType] = useState("P");//金額或百分比 P:百分比，M:金額
    const [URFCMoney, setURFCMoney] = useState("");//退費金額或百分比
    const [URFMoney, setURFMoney] = useState("");//推費金額
    const [Status, setStatus] = useState("");//審核通過:Y, 審核不通過:N
    const [processed, setprocessed] = useState("");//(是否處理,Y是,N否,空值全部)
    const [SDate, setSDate] = useState("");//申請開始日
    const [EDate, setEDate] = useState("");//申請結束日
    const [ATTFPath, setATTFPath] = useState("");//佐證文件

    function SaveData() {
        let tmpURFType = URFType != null ? URFType : "P";
        var dt = {
            URegID: URegID,
            Status: Status,
            URFType: tmpURFType,
            URFCMoney: URFCMoney,
            FMoney: tmpURFType == "P" ? parseInt(URegInMoney * URFCMoney / 100) : parseInt(URFCMoney)
        }
        console.log(dt);
        API_Put_RefundData(dt).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {//成功
                    alert(data.message);
                    getData();
                }
                else {
                    alert(data.message);
                    return;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    function Edit(type, index) {
        console.log(index);
        if (type == 2) {
            //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            })
                .then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                    } else if (data.code == "2013") {
                        //特定的失敗原因
                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }
    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }
    function MLink() {

        location.href = "Main?path=QuestionMaintain";

    }
    function RLink() {
        location.href = "Main?path=QuestionResult";

    }
    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {
        setURegID("");
        setURegIDNo("");
        setCouName("");
        setUserName("");
        setCouStartDate("");
        setUserPhone("");
        setURFDate("");
        setEmail("");
        setDays("");
        setURegInMoney("");
        setURFCaption("");
        setURFCMoney("");
        setStatus("");
        setURFType("");
        setATTFPath("");

        API_Get_RefundDataget({
            "URegID": dataList[tmpIndex].URegID,
            "URegIDNo": dataList[tmpIndex].URegIDNo,
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);


                setURegID(data.result[0].URegID)//使用者報名序號
                setURegIDNo(data.result[0].URegIDNo)//身分/居留證號
                setCouName(data.result[0].CouName)//報名課程
                setUserName(data.result[0].UserName)//姓名
                setCouStartDate(data.result[0].CouStartDate) //開課日期
                setUserPhone(data.result[0].UserPhone) //手機號
                setURFDate(data.result[0].URFDate) //申請日期
                setEmail(data.result[0].Email)//電子信箱
                setDays(data.result[0].Days)//距離開課
                setURegInMoney(data.result[0].URegInMoney)//繳款金額
                setURFCaption(data.result[0].URFCaption)//申請退費原因
                //setStatus(data.result[0].URFStatus);  //1:通過  4:不通過
                if (data.result[0].URFStatus == "1") {
                    setStatus("Y");
                }
                else if (data.result[0].URFStatus == "4") {
                    setStatus("N");
                }
                setURFType(data.result[0].URFType); //  P:百分比 M:固定金額
                setURFCMoney(data.result[0].URFCMoney) //退費金額或百分比
                setATTFPath(data.result[0].ATTFPath)//佐證文件


            })
            .catch(err => {
                console.log(err);
            })




        //setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function DownLoad(Path) {
        API_Get_Download(Path).then((response) => {
            FileDownload(response.data, "退費文件.png");
        });
    }

    function DownLoad_RefundRpt(rptCode, CouPID) {


        API_Post_RefundRpt({
            rptCode: rptCode,
            CouPID: CouPID
        }).then((response) => {
            FileDownload(response.data, "申請退費資料.xlsx");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            });
    }

    function preview(File) {
        setPrevImg(File);
        var id = "modal-Image";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">退費申請管理</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="form-container" style={{ flexWrap: "nowrap" }} >
                                <div className="form-item twenty">
                                    <div className="top-value multi-inline">
                                        <input type="radio" id="f2-1" name="f2" checked={processed == "" ? true : false} onClick={(e) => (setprocessed(""))} />
                                        <label htmlFor="f2-1" className="mr-1">全部</label>

                                        <input type="radio" id="f2-2" name="f2" checked={processed == "N" ? true : false} onClick={(e) => (setprocessed("N"))} />
                                        <label htmlFor="f2-2" className="mr-1">未處理</label>

                                        <input type="radio" id="f2-3" name="f2" checked={processed == "Y" ? true : false} onClick={(e) => (setprocessed("Y"))} />
                                        <label htmlFor="f2-3" className="mr-1">已處理</label>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">期別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => { setDropDownCouPID(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item half">
                                    <div className="top-table">申請日期區間</div>
                                    <div className="top-value multi-inline" style={{ width: "70%" }}>
                                        <input type="date" value={SDate} onChange={(e) => { setSDate(e.target.value) }} />&ensp;至&ensp;
                                        <input type="date" value={EDate} onChange={(e) => { setEDate(e.target.value) }} />
                                    </div>
                                </div>
                                <a className="btn btn-border btn-middle" onClick={getData}>
                                    <span>查詢</span>
                                </a>
                                <a className="btn btn-border btn-middle" onClick={(e) => DownLoad_RefundRpt('RefundRpt', DropDownCouPID)}>
                                    <span>下載報表</span>
                                </a>
                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">身分/居留證號</div>
                                        <div className="flex-2">課程名稱</div>
                                        <div className="flex-1">課程期別</div>
                                        <div className="flex-3">申請退費理由</div>
                                        <div className="flex-1">申請日期</div>
                                        <div className="flex-1">&ensp;審核</div>
                                        <div className="flex-1">審核結果</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">
                                                {x.URegIDNo}
                                            </div>
                                            <div className="flex-2">
                                                {x.CouName}
                                            </div>
                                            <div className="flex-1">
                                                {x.CouPName}
                                            </div>
                                            <div className="flex-3">
                                                {x.URFCaption}
                                            </div>
                                            <div className="flex-1">
                                                {x.URFDate}
                                            </div>
                                            <div className="flex-1">
                                                <div
                                                    className="btn btn-icon"
                                                    onClick={(e) => openModalEdit(i)}
                                                >
                                                    <i className="icon icon-28"></i>
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                {x.Remark}
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div id="modal-edit" className="modal" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">退費審核</h3>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>身分/居留證號</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={URegIDNo} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>報名課程</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={CouName} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>姓名</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={UserName} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>開課日期</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="date" value={CouStartDate} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>手機號</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={UserPhone} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>申請日期</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="date" value={URFDate} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>電子信箱</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={Email} />
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>距離開課</div>
                                    <div className="value multi-inline" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={Days} />&ensp;天
                                    </div>
                                </div>

                                <div className="form-item half">
                                    <div className="field" style={{ width: '110px' }}>繳款金額</div>
                                    <div className="value multi-inline" style={{ width: 'calc(100% - 110px)' }}>
                                        <input type="text" value={URegInMoney} />&ensp;元
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field" style={{ width: '110px' }}>申請退費原因</div>
                                    <div className="value" style={{ width: 'calc(100% - 110px)' }}>
                                        <textarea name="" cols="30" rows="5" value={URFCaption}></textarea>
                                    </div>
                                </div>

                                <div className="form-item">
                                    <div className="field" style={{ width: '110px' }}>佐證文件</div>
                                    <div className="value multi-inline" style={{ width: 'calc(100% - 110px)' }}>
                                        {
                                            (ATTFPath == null || ATTFPath == "") ?
                                                <a href="javascript:void(0);"></a>
                                                :
                                                <a href="javascript:void(0);" onClick={() => preview(ATTFPath)}>佐證文件</a>
                                        }
                                    </div>
                                </div>

                                <div className="form-item multi-inline">
                                    <input type="radio" id="f3-1" name="f3" value="N" checked={Status == "N" ? true : false} onChange={(e) => { setStatus(e.target.value) }} />
                                    <label htmlFor="f3-1" className="mr-1">不通過</label>

                                    <input type="radio" id="f3-2" name="f3" value="Y" checked={Status == "Y" ? true : false} onChange={(e) => { setStatus(e.target.value) }} />
                                    <label htmlFor="f3-2" className="mr-1">通過</label>

                                    <span className="ml-1 fw-b">退款金額</span>

                                    <input type="text" value={URFCMoney} onChange={(e) => { setURFCMoney(e.target.value) }} style={{ width: "50%" }} />
                                    <select name="" id="" style={{ width: "38%" }} value={URFType} onChange={(e) => { setURFType(e.target.value) }} >
                                        <option value="P" selected>%</option>
                                        <option value="M"  >元</option>
                                    </select>
                                    <span>計</span>
                                    {URFType != "M" ?
                                        <span className="ml-1 mr-1">{URegInMoney * URFCMoney / 100}</span>
                                        :
                                        <span className="ml-1 mr-1">{URFCMoney}</span>
                                    }
                                    <span>元</span>
                                </div>

                            </div>

                            <div className="btn-holder text-center mt-1">
                                <a className="btn" onClick={SaveData} data-close>
                                    <span>確認</span>
                                </a>
                                <a className="btn btn-border" data-close>
                                    <span>放棄</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-Image" className="modal large" data-modal>
                    <div className="modal-container preview-modal">
                        <div className="modal-body">
                            <div className="preview-closebtn">
                                <div className="btn btn-middle btn-border" data-close >
                                    <span>X</span>
                                </div>
                            </div>
                            <div className="container full mt-1 center">
                                <div className="form-item preview-form">
                                    <div className="value">
                                        <div className="btn-holder mt-1">

                                        </div>
                                    </div>
                                    <div className="fields preview-fields">

                                        <div className="form-border ">
                                            <img className="preview-img" src={PrevImg} img />
                                        </div>
                                        <br />
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default RefundList;
