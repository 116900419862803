import "../../css/style.css";
import React, { useState, useEffect } from "react";
import {
    API_Get_DropDown,
    API_Get_Lotterypost,
    API_Get_LotteryName,
    API_POST_LotteryInt,
    API_Get_LotteryList,
    API_Put_LotterySave,
    API_Get_Lottery,
    API_Get_LotteryBatch
} from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import domtoimage from 'dom-to-image';


function Lottery() {
    var history = useHistory();

    useEffect(() => {
        getData();

    }, []);

    function getData() {
        API_Get_Lotterypost({
            CouPID: DropDownCouPID,
            CouTypeCID1: DropDownCouTypeCID1,
            CouTypeID: DropDownCouTypeID,
            CouName: DropDownCouName
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    alert(data.message);
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                //if (!checkAuthToken(data)) {
                //    window.location = "/";
                //}
                if (data.code == "0000") {
                    //console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);

                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


    }
    const [htmlLists, sethtmlLists] = useState([]);
    const [modalTit, setModalTit] = useState("刪除");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState(); 
    const UserName = localStorage.getItem("userName");//使用者名稱
    const [times, setTimes] = useState(0); //抽選次數
    const [CouID, setCouID] = useState("");//班別ID
   
    const [lotteryAllType, setlotteryAllType] = useState("");//抽選全部按鈕禁用 
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [courseTypeCArrayList, setcourseTypeCArrayList] = useState([{}]);//類別清單
    const [courseTypeArrayList, setcourseTypeArrayList] = useState([{}]);//班別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [DropDownCouTypeID, setDropDownCouTypeID] = useState("");//類別下拉
    const [DropDownCouTypeCID1, setDropDownCouTypeCID1] = useState("");//班別下拉
    const [DropDownCouName, setDropDownCouName] = useState("");//課程名稱

    const [CouPName, setCouPName] = useState("");//期別
    const [CouName, setCouName] = useState(""); //課程名稱
    const [CouNo, setCouNo] = useState("");//課程代號
    const [CouAmount, setCouAmount] = useState("");//課程名額
    const [Cnt, setCnt] = useState("");//課程報名人數

    const [couDrawID, setcouDrawID] = useState("");//抽選截圖ID
    const [dataList, setDataList] = useState([{}]);

    const [lotteryList, setLotteryList] = useState([{}]); //抽選名單
    const [lotteryWinList, setLotteryWinList] = useState([{}]); //抽中名單
    const [lotteryLoseList, setLotteryLoseList] = useState([{}]); //落選名單
    const [winCode, setwinCode] = useState(""); //抽中加密名單
    const [loseCode, setloseCode] = useState(""); //落選加密名單
    const [lotteryAllList, setLotteryAllList] = useState([]);
    const lotteryAll = [];//批次抽選(conID) 
    //驗證
    const [saveLotteryError, setsaveLotteryError] = useState(false);//存儲
    const [viewList, setViewList] = useState([]);
    const [detial, setdetial] = useState([]);
    const todaysDate = new Date();  

    function openModalSuccess(title) {
        setModalTit(title);

        var id = "modal-successs";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError(title, msg) {
        setModalTit(title);
        setModalStr(msg);

        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    //批次電選抽選彈窗
    function openModalAll() {
        
        console.log(lotteryAllList);

        lotteryAll.map((x, i) => {

            API_Get_LotteryBatch({
                CouID: x
            }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setLotteryAllList(function (prev) {
                        return prev.map((item, i) => {
                            if (x == item.id) {
                                item.lotteryType = "F";
                            }
                            
                            return item;
                        });
                    });
                    onsole.log(lotteryAllList);
                } else {
                    openModalError("抽選", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                    }
                })
                    
        });
      
                var id = "modal-lotteryAll";
                var target = document.querySelector("#" + id);
                var layerClass = "layer-1";
                target.classList.add("active");
                target.classList.add(layerClass);
            
    }
    //電選抽選彈窗
    function openModalLottery(tmpIndex, times) {
        if (times === null) {
            setTimes(0);
        } else {
            setTimes(times);
        }

        setCouID(dataList[tmpIndex].CouID);

        setCouPName(dataList[tmpIndex].CouPName);
        setCouName(dataList[tmpIndex].CouName);
        setCouNo(dataList[tmpIndex].CouNo);
        setCouAmount(dataList[tmpIndex].CouAmount);
        setCnt(dataList[tmpIndex].cnt);

        API_Get_LotteryName({
            CouID:dataList[tmpIndex].CouID
        }).then((response) => response.data)
            .then((data) => {

                if (data.code == "0000") {
                    console.log(data);
                    setLotteryList(data.result);

                    var htmlItem = [];
                    data.result.map((x, i) => {
                        
                        htmlItem.push(<div><input type="checkbox" id={x.UserID} name={x.UserID} value={x.UregID} disabled="true" />
                            <label htmlFor={x.UregID}>{x.UserIDNo}&ensp;{x.UserName}</label></div>);
                    });
                    //console.log(htmlItem);
                    sethtmlLists(htmlItem);
                } else {
                    //openModalError("報名", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

        var id = "modal-lottery";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    
    //查看記錄彈窗
    function openModalDetial(tmpIndex) {

        setdetial(viewList[tmpIndex].ATTFPath);

        //setCouPName(dataList[tmpIndex].CouPName);
        //setCouName(dataList[tmpIndex].CouName);
        //setCouNo(dataList[tmpIndex].CouNo);
        //setCouAmount(dataList[tmpIndex].CouAmount);
        //setCnt(dataList[tmpIndex].cnt);

      
        var id = "modal-detial";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    //查看記錄彈窗
    function openModalView(tmpIndex) {       

        setCouID(dataList[tmpIndex].CouID);

        setCouPName(dataList[tmpIndex].CouPName);
        setCouName(dataList[tmpIndex].CouName);
        setCouNo(dataList[tmpIndex].CouNo);
        setCouAmount(dataList[tmpIndex].CouAmount);
        setCnt(dataList[tmpIndex].cnt);

      //取得記錄
        API_Get_LotteryList({
            CouID: dataList[tmpIndex].CouID
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    setViewList(data.result);

                    //window.location.reload();
                } else {
                    //openModalError("報名", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

        var id = "modal-view";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    //抽選全部按鈕-檢查是否有勾選課程
    function lotteryAllFun() {
        setLotteryAllList();

        let lottery = "";
        let chkData = {};
        let Data = [];

        const lotterylist = document.querySelectorAll("input[type=checkbox]");

        dataList.map((x, i) => {
            for (let e in lotterylist) {
                if (lotterylist[e].checked == true && lotterylist[e].value == x.CouID) {
                    chkData = {
                        id: x.CouID,
                        CouNo: x.CouNo,
                        CouName: x.CouName,
                        CouPName: x.CouPName,
                        lotteryType: "N"//抽選狀態(F->完成；I->抽選中；N->未抽選)
                    };
                    lotteryAll.push(x.CouID);
                    Data.push(chkData);
                    break;
                }

            }

        });

        setLotteryAllList(Data);

        lotterylist.forEach((element) => {

            if (element.checked == true) {
                lottery = lottery + element.value;
                lottery = lottery + ",";
                //console.log(element);
            }
        });
        lottery = lottery.substring(0, lottery.length - 1);
        //console.log(lottery);
        //setlotteryAll(lottery);

        if (lottery == "") {
            openModalError("操作", "請先勾選課程進行電腦抽選。");
        } else {
            openModalAll();
        }
        

    }
    //單次抽選
    function lottery() {
        //抽選API
        API_Get_Lottery({
            CouID: CouID
        }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    //更新 名單內容
                    setLotteryWinList(data.result.lotteryList);
                    setwinCode(data.result.aesString);
                    setLotteryLoseList(data.result.loseList);
                    setloseCode(data.result.loseaesString);
                    var htmlItem = [];
                    var type = false;
                    sethtmlLists("");
                    //更新 抽中名單畫面
                    //upLotteryView()
                    lotteryList.map((x, i) => {
                        for (let y in data.result.lotteryList) {
                         
                            if (data.result.lotteryList[y] == x.UregID) {
                                type = true;
                                htmlItem.push(<div><input type="checkbox" id={x.UserID} name={x.UserID} value={x.UregID} disabled="true" checked="true" />
                                    <label htmlFor={x.UregID}>{x.UserIDNo}&ensp;{x.UserName}</label></div>);
                                break;
                            }
                           
                        }
                        if (type == false) {
                            htmlItem.push(<div><input type="checkbox" id={x.UserID} name={x.UserID} value={x.UregID} disabled="true" />
                                <label htmlFor={x.UregID}>{x.UserIDNo}&ensp;{x.UserName}</label></div>);
                        } else {
                            type = false;
                        }
                        if ((i + 1) == data.result.lotteryList.length) {
                            sethtmlLists(htmlItem);

                            //抽選截圖及更新次數
                            lotteryTime();
                        }
                    });

                    
                    //window.location.reload();
                } else {
                    openModalError("抽選", data.message);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function upLotteryView() { }
    //抽選截圖及更新次數
    function lotteryTime() {

        //更新抽選次數
        setTimes(parseInt(times) + 1);

         //取得截圖
        var node = document.querySelector("#modal-lottery");

        console.log(node);

        domtoimage.toPng(node)
            .then(function (dataUrl) {
                console.log(dataUrl);
                //上傳截圖

                API_POST_LotteryInt({
                    CouID: CouID,
                    CouDrawItem: parseInt(times) + 1,
                    Base64String: dataUrl
                }).then((response) => response.data)
                    .then((data) => {
                        if (data.code == "0000") {
                            //console.log(data);
                            setcouDrawID(data.result.couDrawID);
                        } else {
                            openModalError("上傳截圖", data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })

              

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });

    }
    //存儲名單
    function saveLottery() {
        if (winCode == "") {
            setsaveLotteryError(true);
        } else {
            setsaveLotteryError(false);
            API_Put_LotterySave({
                CouDrawID: couDrawID,
                aesString: winCode,
                loseaesString: loseCode
            }).then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        //console.log(data);
                        setTimes(0);
                        window.location.reload();
                        openModalSuccess("存儲名單");
                    } else {
                        openModalError("存儲名單", data.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        }
       
    }
    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">電腦名單抽選</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item twenty">
                                    <div className="top-table">期別：</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => { setDropDownCouPID(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">類別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeCID1} onChange={(e) => { setDropDownCouTypeCID1(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {courseTypeCArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">班別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeID} onChange={(e) => { setDropDownCouTypeID(e.target.value) }}>
                                            <option value="">請選擇</option>
                                            {courseTypeArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">課程名稱</div>
                                    <div className="top-value">
                                        <input type="text" value={DropDownCouName} onChange={(e) => { setDropDownCouName(e.target.value) }} />
                                    </div>
                                </div>
                                <a className="btn btn-border btn-middle" onClick={getData}>
                                    <span >查詢</span>
                                </a>
                            </div>
                        </div>

                        <div className="container full mt-1">

                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">
                                            <div className="flex-1">                                              
                                                <input type="checkbox" id="selectAll"  onChange={
                                                    (e) => {
                                                        const lotterylist = document.querySelectorAll("input[type=checkbox]");
                                                        const checkAll = document.querySelector("#selectAll");
                                                        lotterylist.forEach(item => item.checked = checkAll.checked);
                                                    }}
                                                />
                                                <label htmlFor="selectAll" ><span>全選</span></label>  
                                            </div>
                                        </div>
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">班別序號</div>
                                        <div className="flex-1">課程名稱</div>
                                        <div className="flex-1">課程期別</div>
                                        <div className="flex-1">類別</div>
                                        <div className="flex-1">班別</div>
                                        <div className="flex-1">教師</div>
                                        <div className="flex-1">開課日期</div>
                                        <div className="flex-0">名額</div>
                                        <div className="flex-0">報名</div>
                                        <div className="flex-1">&ensp;電腦抽選</div>
                                        <div className="flex-1">&ensp;查看記錄</div>
                                    </li>
                                    {dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">
                                                {x.IsCouDrawFinal == "1" ? (
                                                    <div className="flex-0"> </div>
                                                ) : (
                                                        <div className="flex-0">
                                                            <input type="checkbox" id={`${x.CouID}`} name="r1" value={`${x.CouID}`} onChange={(e) => setlotteryAllType("Y")} />
                                                            <label htmlFor={`${x.CouID}`}></label>
                                                        </div>
                                                )}
                                            </div>
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-1">{x.CouNo}</div>
                                            <div className="flex-1">{x.CouName}</div>
                                            <div className="flex-1">{x.CouPName}</div>
                                            <div className="flex-1">{x.Kind}</div>
                                            <div className="flex-1">{x.ClassType}</div>
                                            <div className="flex-1">{x.CouTeacherName}</div>
                                            <div className="flex-1">{x.CouStartDate}</div>
                                            <div className="flex-0">{x.CouAmount}</div>
                                            <div className="flex-0">
                                                {x.cnt == null ? (
                                                    0
                                                ) : (
                                                        x.cnt
                                                )}
                                            </div>
                                            <div className="flex-1 fn">
                                                {new Date(x.CouSTDrawDate) < todaysDate ?(
                                                    x.IsCouDrawFinal == "1" ? (
                                                        <span>已完成</span>
                                                    ) : (
                                                            <div className="btn btn-icon" onClick={(e) => openModalLottery(i, x.Image)} >
                                                                <i className="icon icon-35"></i>
                                                            </div>
                                                    )
                                                ) : (
                                                    <span>尚未開始抽選</span>
                                                )}
                                            </div>
                                            <div className="flex-1 fn">
                                                {x.Image != null ? (
                                                    < div className="btn btn-icon" onClick={(e) => openModalView(i)} >
                                                        <i className="icon icon-30"></i>
                                                    </div>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="container full mt-1" >
                            <div className="btn-holder">
                                <div>
                                    <a className="btn btn-middle btn-border" onClick={lotteryAllFun }>
                                        <span>抽選全部</span>
                                    </a>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-successs" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}成功</div>
                        </div>
                    </div>
                </div>
                
                <div id="modal-lotteryAll" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">抽選全部</h3>
                        </div>

                        <div className="modal-body">
                            <div className="container full">
                                <ul className="list-table">
                                    {lotteryAllList.map((x, i) => (
                                        <li className="lt-row">
                                            
                                            <div className="flex-5 text-center">
                                                {x.CouNo}&ensp; -&ensp; {x.CouName}
                                            </div>
                                            
                                            <div className="flex-3 ">
                                                {x.lotteryType == "F" ? (
                                                    <div className="flex-3 ">
                                                        <span>．．．</span>
                                                        <span style={{ color: 'green' }}>完成抽選</span>
                                                    </div>
                                            
                                                ) : (
                                                        
                                                        x.lotteryType == "N" ? (
                                                            <div className="flex-3">
                                                                <span>．．．</span>
                                                                <span >未抽選</span>
                                                            </div>
                                                                ) : (
                                                                <div className="flex-3 ">
                                                                    <span>．．．</span>
                                                                    <span style={{ color: 'red' }}>抽選中</span>
                                                                </div>
                                                            )
                                                        
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <br />
                                <div className="btn-holder text-center" >
                                    <a className="btn" data-close onClick={getData}>
                                        <span>確定</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="modal-lottery" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">電腦抽選</h3>
                        </div>

                        <div className="modal-body">
                            <div className="container full">
                                <div className="info-holder">
                                    <div className="left"></div>
                                    <div className="right">
                                        <span className="fw-b">抽選帳號：</span><span>{ UserName }</span>
                                    </div>
                                </div>
                                <div className="info-holder">
                                    <div className="left">
                                        <span className="fw-b">期別：</span><span>{ CouPName }</span>
                                        <span className="fw-b ml-1">班別序號：</span><span>{CouNo}</span>
                                        <span className="fw-b ml-1">課程名稱：</span><span>{CouName}</span>
                                    </div>
                                    <div className="right">
                                        <span className="fw-b ml-1">名額 / 報名：</span><span>{CouAmount} / {Cnt}</span>&ensp;&ensp;
                                        <button className="btn btn-middle btn-border" onClick={(e) => lottery()}>
                                            <span>抽選</span>
                                        </button>
                                    </div>
                                </div>
                                
                                <div className="boxes">
                                    {htmlLists}
                                </div>
                                {
                                    times > 0 && (
                                        <div className="times">本次為第 {times} 次抽選</div>
                                    )
                                }
                                <br />
                                <br />
                                <span className={`form-item text-center ${saveLotteryError ? "show-help" : ""}`} style={{ width: '850px'  }}>
                                    <span className="help-word" style={{ fontSize: '16px' }}> 尚未抽選名單</span>
                                </span>
                                <div className="btn-holder text-center">
                                    <a className="btn"onClick={ saveLottery }>
                                        <span></span>存儲名單
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="modal-view" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">查看記錄</h3>
                        </div>

                        <div className="modal-body">
                            <div className="container full">
                                <div className="info-holder">
                                    <div className="left"></div>
                                    <div className="right">
                                        <span className="fw-b">抽選帳號：</span><span>{UserName}</span>
                                    </div>
                                </div>
                                <div className="info-holder">
                                    <div className="left">
                                        <span className="fw-b">期別：</span><span>{CouPName}</span>
                                        <span className="fw-b ml-1">班別序號：</span><span>{CouNo}</span>
                                        <span className="fw-b ml-1">課程名稱：</span><span>{CouName}</span>
                                    </div>
                                    <div className="right">
                                        <span className="fw-b ml-1">名額 / 報名：</span><span>{CouAmount} / {Cnt}</span>&ensp;&ensp;
                                        <span className="fw-b ml-1">抽選次數：</span><span>{viewList.length}</span>
                                    </div>
                                </div>
                                <ul className="list-table">

                                  
                                    {viewList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-1">
                                               
                                                <p>第 {x.CouDrawItem} 次抽選</p>
                                            </div>
                                            <div className="flex-8">
                                                <img src={x.ATTFPath} onClick={(e) => openModalDetial(i)} />
                                            </div>
                                            <div className="flex-1">
                                                {x.IsCouDrawFinal == '1' ? (
                                                    "最終抽選結果"
                                                ) : (
                                                   ""
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                    

                                </ul>
                                <br />
                                <br />
                                <div className="btn-holder text-center" >
                                    <a className="btn" data-close>
                                        <span>確定</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">{modalTit}失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
                <div id="modal-detial" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="fz-B has-border">查看記錄</h3>
                        </div>

                        <div className="modal-body">
                            <img src={detial}  />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Lottery;
